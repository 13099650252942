<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
    <div class="container-fluid">
        <div class="navbar-minimize">
            <button id="minimizeSidebar" class="btn btn-round btn-white btn-fill btn-just-icon navbar-toggle"
                (click)="sidebarToggle()">
                <i *ngIf="!sidebarVisible" class="material-icons" style="color: #074a73">more_vert</i>
                <i *ngIf="sidebarVisible" class="material-icons" style="color: #074a73">view_list</i>
            </button>
        </div>
        <div class="navbar-wrapper">
            <a class="navbar-brand colorWhite" href="javascript:void(0)" style="color: #074a73">{{getTitle()}}</a>
        </div>
        <button mat-raised-button class="navbar-toggler" type="button" (click)="onShowHideSidebar()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                    <a class="nav-link marginRigh" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        id="navbarDropdownMenuLink">
                        <i class="material-icons iconUser" style="font-size: 30px;color: #074a73">notifications</i>
                        <span class="notification">{{countNotSee}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-noti"
                        aria-labelledby="navbarDropdownMenuLink">
                        <div class="row noti-intro">
                            <div class="col-6 noti-intro-left">
                                <p class="noti-intro-title">THÔNG BÁO</p>
                            </div>
                            <div class="col-6 noti-intro-actions">
                                <button (click)="openSettingNoti($event)" class="noti-more-actions" mat-icon-button
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <div [ngClass]="isOpenSubMenu ? 'menuSub' : 'menuSub-hidden'">
                                    <button mat-button class="noti-more-actions-item" tabindex="2"
                                        (click)="readAll($event)">
                                        <mat-icon>checklist_rtl</mat-icon>
                                        <span> Đánh dấu tất cả là đã đọc</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="noti-body" infiniteScroll [infiniteScrollDistance]="2"
                                [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()" [scrollWindow]="false">
                                <div *ngFor="let item of messagesClient; let i =index" class="noti"
                                    (click)="updateNoti($event, item)">
                                    <div class="noti-content">
                                        <div class="row">
                                            <div class="col-7">
                                                <p class="noti-title">{{item.message.params.data.notification.title}}
                                                </p>
                                            </div>
                                            <div class="col-5 noti-date-div">
                                                <p class="noti-date">{{item.message.time | dateFormat}}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <p [innerHTML]="item.message.params.data.notification.message | sanitizeHtml"
                                                class="noti-desc" tabindex="1">
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="item.message.params.data.data.custom.status ? 'badge-noti' : 'badge-noti-hidden'">
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="navbarDropdownLanguage">
                        <img *ngIf="selectedLanguage=='vi'" src="../../../assets/img/vietnam-flag-square-icon-256.png"
                            height="20px" class="rounded-circle iconLanguage" alt="Tiếng Việt">
                        <img *ngIf="selectedLanguage=='en'"
                            src="../../../assets/img/united-kingdom-flag-square-icon-256.png" height="20px"
                            class="rounded-circle iconLanguage" alt="English">
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownLanguage">
                        <a class="dropdown-item" (click)="ChangLanguage('vi')"><i class="fa fa-info-circle fa-lg"
                                style="margin-right: 4px"></i>Tiếng Việt</a>
                        <a class="dropdown-item" (click)="ChangLanguage('en')"><i class="fa fa-info-circle fa-lg"
                                style="margin-right: 4px"></i>English</a>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link" id="navbarDropdownUser" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="material-icons iconUser" style="font-size: 30px;color: #074a73">person</i>
                        <span class="colorText" [translate]="'NAV.HELLO'" style="color: #074a73"></span>
                        <span class="colorText" style="color: #074a73">{{fullName}}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownUser">
                        <a class="dropdown-item" (click)="showUserProfile('Thay đổi thông tin tài khoản người dùng')"><i
                                class="fa fa-info-circle fa-lg" style="margin-right: 4px"
                                [translate]=" 'NAV.CHANGEINFORACCOUNT'"></i></a>
                        <a class="dropdown-item" *ngIf="showRoleEdit===true"
                            (click)="onEditRole('Quyền menu system admin')"><i class="fa fa-bar-chart fa-lg"
                                style="margin-right: 4px" [translate]="'NAV.ACCESS'"></i></a>
                        <a class="dropdown-item" *ngIf="showRoleEdit===false" (click)="showMyDrive()"><i
                                class="fa fa-dropbox fa-lg" style="margin-right: 4px"
                                [translate]=" 'NAV.drive'"></i></a>
                        <a class="dropdown-item" (click)="logout()"><i class="fa fa-sign-out fa-lg"
                                style="margin-right: 4px" [translate]="'NAV.SIGOUT'"></i></a>
                        <a class="dropdown-item" *ngIf="showRoleEdit===true"><i class="fa fa-sign-out fa-lg"
                                style="margin-right: 4px"> UI New : 13-01-2023</i></a>
                        <a class="dropdown-item"><i class="fa fa-sign-out fa-lg" style="margin-right: 4px"> Version:
                                2.0.1</i></a>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</nav>

<div class="main-dialog">
    <p-dialog [(header)]="headerTitle" [(visible)]="display" (onHide)="changePassword=false" [modal]="true"
        [responsive]="true" [style]="{width: '85%', minWidth: '80%','height':'80%','overflow-y': 'auto'}" [minY]="70"
        [maximizable]="true" [baseZIndex]="10000">

        <mat-tab-group>
            <mat-tab label="{{'organmanage.thongtintaikhoan'|translate}}">
                <form [formGroup]="addInforForm">
                    <div class="ui-grid ui-grid-responsive ui-grid-pad ui-fluid" style="margin: 10px 0px">
                        <div class="ui-grid-row">
                            <div class="ui-grid-col-12">
                                <label [translate]=" 'NAV.tennguoidung'"></label>
                                <input maxlength="100" type="text" formControlName="fullname" class="form-control"
                                    [ngClass]="{ 'is-invalid': submittedForm && f.fullname.errors }" />
                                <div *ngIf="submittedForm && f.fullname.errors" class="invalid-feedback">
                                    <div *ngIf="f.fullname.errors.required" [translate]=" 'NAV.nhapten'"></div>
                                    <div *ngIf="f.fullname.errors.pattern"> {{'organmanage.kytudautien'|translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span formGroupName="personalinformation">
                            <div class="ui-grid-row" style="padding: .25em .5em;">
                                <div class="ui-grid-col-12">
                                    <label>Email: *</label>
                                    <input maxlength="100" type="email" formControlName="email" class="form-control"
                                        [ngClass]="{ 'is-invalid': submittedForm && f1.email.errors }" />
                                    <div *ngIf="submittedForm && f1.email.errors" class="invalid-feedback">
                                        <div *ngIf="f1.email.errors.required" [translate]=" 'NAV.nhapemail'"></div>
                                        <div *ngIf="f1.email.errors.pattern" [translate]="'NAV.emailsai'">
                                            {{'organmanage.emaildinhdang'|translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-12" style="padding: .25em .5em;">
                                    <label [translate]="'NAV.sdt'"></label>
                                    <input maxlength="20" type="text" formControlName="phonenumber" class="form-control"
                                        [ngClass]="{ 'is-invalid': submittedForm && f1.phonenumber.errors }" />
                                    <div *ngIf="submittedForm && f1.phonenumber.errors" class="invalid-feedback">
                                        <div *ngIf="f1.phonenumber.errors.required" [translate]="'NAV.nhapsdt'"></div>
                                        <div *ngIf="f1.phonenumber.errors.pattern">{{'organmanage.sdtlaso'|translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                        <div class="ui-grid-row">
                            <div class="ui-grid-col-12">
                                <label [translate]="'NAV.thaydoimk'"></label><br />
                                <p-inputSwitch (click)="toggleValidatorProfile()" formControlName="xacnhancapnhat">
                                </p-inputSwitch>
                            </div>
                        </div>
                        <div *ngIf="changePassword==true">
                            <!-- <div class="ui-grid-row">
                            <div class="ui-grid-col-12">
                                <label [translate]="'NAV.mkhientai'"></label>
                                <input type="password" formControlName="oldpassword" class="form-control"
                                    [ngClass]="{ 'is-invalid': submittedForm && f.oldpassword.errors }" />
                                <div *ngIf="submittedForm && f.oldpassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.oldpassword.errors.required" [translate]="'NAV.nhapmk'"></div>
                                    <div *ngIf="f.oldpassword.errors.cannotContainSpace">
                                        {{'organmanage.matkhaudaucach'|translate}}</div>
                                </div>
                            </div>
                        </div> -->
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-12">
                                    <label [translate]="'NAV.mkmoi'"> *</label>
                                    <input maxlength="50" type="password" formControlName="password"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submittedForm && f.password.errors }" />
                                    <div *ngIf="submittedForm && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required" [translate]="'NAV.nhapmk'"></div>
                                        <div *ngIf="f.password.errors.cannotContainSpace">
                                            {{'organmanage.matkhaudaucach'|translate}}
                                        </div>
                                        <div *ngIf="f.password.errors.pattern">{{'organmanage.matkhauchon'|translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui-grid-row">
                                <div class="ui-grid-col-12">
                                    <label [translate]="'NAV.nhaplaimk'"></label>
                                    <input maxlength="50" type="password" formControlName="confirmPassword"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submittedForm && f.confirmPassword.errors }" />
                                    <div *ngIf="submittedForm && f.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmPassword.errors.required" [translate]="'NAV.nhapmk'"></div>
                                        <div *ngIf="f.confirmPassword.errors.mustMatch" [translate]="'NAV.mksai'">
                                            {{'usermanage.mkkotrung'|translate}}</div>
                                        <div *ngIf="f.confirmPassword.errors.cannotContainSpace">
                                            {{'organmanage.matkhaudaucach'|translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="form-group floatRight">

                    <button mat-raised-button (click)="display=false">{{'NAV.huy'|translate}}</button> &nbsp;
                    <button mat-raised-button (click)="onSubmitDontNeedOldPassword()" [disabled]="isSaving"
                        color="save">
                        <!-- <button mat-raised-button (click)="onSubmit()" [disabled]="isSaving" color="save"> -->
                        <span *ngIf="!isSaving">{{'NAV.luu'|translate}}</span>
                        <span *ngIf="isSaving">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{'NAV.dangluu'|translate}}
                        </span>
                    </button>

                    <!-- <button (click)="display=false" class="btn btn-danger" [translate]="'NAV.huy'"></button>
                <button (click)="onSubmit()" class="ml-1 btn btn-primary" [disabled]="isSaving">
                    <span *ngIf="!isSaving" [translate]="'NAV.luu'"></span>
                    <span *ngIf="isSaving" [translate]="'NAV.dangluu'">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                    </span>
                </button> -->
                </div>
            </mat-tab>
            <mat-tab label="{{'organmanage.thongtinkyso'|translate}}">
                <br>
                <div class="row">
                    <div class="col-md-4 boderRight">

                        <mat-form-field appearance="fill">
                            <mat-label>Chọn loại tài khoản ký số</mat-label>
                            <mat-select [(ngModel)]="selectedTypeSign">
                                <mat-option *ngFor="let sign of TypeSign" [value]="sign.value">
                                    {{sign.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- Ký số VNPT Sign -->
                        <div *ngIf="selectedTypeSign == 1">
                            <form [formGroup]="signAccountForm" class="sign-form">
                                <mat-form-field class="sign-full-width">
                                    <mat-label>{{'usermanage.tendn'|translate}}</mat-label>
                                    <input matInput placeholder="Ex. peter@vnpt.vn" formControlName="username"
                                        [ngClass]="{ 'is-invalid': submittedForm2 && g.username.errors }">

                                </mat-form-field>

                                <div *ngIf="submittedForm2 && g.username.errors" class="errorNotification">
                                    <div *ngIf="g.username.errors.required">{{'organmanage.nhaptendn'|translate}}</div>
                                </div>

                                <mat-form-field class="sign-full-width">
                                    <mat-label>{{'usermanage.matkhau'|translate}}</mat-label>
                                    <input matInput placeholder="" type="password" formControlName="password"
                                        [ngClass]="{ 'is-invalid': submittedForm2 && g.password.errors }">

                                </mat-form-field>

                                <div *ngIf="submittedForm2 && g.password.errors" class="errorNotification">
                                    <div *ngIf="g.password.errors.required">{{'usermanage.nhaplaimk'|translate}}</div>
                                </div>

                                <mat-form-field class="sign-full-width">
                                    <mat-label>ClientId</mat-label>
                                    <input matInput placeholder="" formControlName="clientId"
                                        [ngClass]="{ 'is-invalid': submittedForm2 && g.clientId.errors }">

                                </mat-form-field>

                                <div *ngIf="submittedForm2 && g.clientId.errors" class="errorNotification">
                                    <div *ngIf="g.clientId.errors.required"> {{'usermanage.nhapclient'|translate}}</div>
                                </div>

                                <mat-form-field class="sign-full-width">
                                    <mat-label>ClientSecret</mat-label>
                                    <input matInput placeholder="" formControlName="clientSecret"
                                        [ngClass]="{ 'is-invalid': submittedForm2 && g.clientSecret.errors }">

                                </mat-form-field>

                                <div *ngIf="submittedForm2 && g.clientSecret.errors" class="errorNotification">
                                    <div *ngIf="g.clientSecret.errors.required"> {{'usermanage.nhapsecre'|translate}}
                                    </div>
                                </div>



                                <div *ngIf="errorMessage!==''" class="errorNotification">
                                    {{errorMessage}}
                                </div>
                            </form>
                            <div class="form-group floatRight">
                                <button mat-raised-button (click)="setUpSignAccount()" [disabled]="isSavingAccountSign"
                                    color="save">
                                    <span *ngIf="!isSavingAccountSign">{{'NAV.luuthongtin'|translate}}</span>
                                    <span *ngIf="isSavingAccountSign">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        {{'NAV.dangluu'|translate}}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <!-- Ký số Smart CA -->
                        <div *ngIf="selectedTypeSign == 2">
                            <form [formGroup]="signAccountSmartCA" class="sign-form">
                                <mat-form-field class="sign-full-width">
                                    <mat-label>{{'usermanage.tendn'|translate}}</mat-label>
                                    <input matInput placeholder="Ex. peter" formControlName="username"
                                        [ngClass]="{ 'is-invalid': submittedFormSmartCA && accountSmartCAForm.username.errors }">

                                </mat-form-field>

                                <div *ngIf="submittedFormSmartCA && accountSmartCAForm.username.errors"
                                    class="errorNotification">
                                    <div *ngIf="accountSmartCAForm.username.errors.required">
                                        {{'organmanage.nhaptendn'|translate}}</div>
                                </div>

                                <mat-form-field class="sign-full-width">
                                    <mat-label>{{'usermanage.matkhau'|translate}}</mat-label>
                                    <!-- <input matInput placeholder="" type="password" formControlName="password" [ngClass]="{ 'is-invalid': submittedFormSmartCA && accountSmartCAForm.password.errors }"> -->
                                    <input matInput placeholder="" [type]="hide ? 'password' : 'text'"
                                        formControlName="password"
                                        [ngClass]="{ 'is-invalid': submittedFormSmartCA && accountSmartCAForm.password.errors }">
                                    <mat-icon style="margin-right: 10px;" matSuffix (click)="hide = !hide">{{hide ?
                                        'visibility_off' : 'visibility'}}</mat-icon>
                                </mat-form-field>

                                <div *ngIf="submittedFormSmartCA && accountSmartCAForm.password.errors"
                                    class="errorNotification">
                                    <div *ngIf="accountSmartCAForm.password.errors.required">
                                        {{'usermanage.nhaplaimk'|translate}}</div>
                                </div>

                                <div *ngIf="errorMessage!==''" class="errorNotification">
                                    {{errorMessage}}
                                </div>
                            </form>
                            <div class="form-group floatRight">
                                <button mat-raised-button (click)="upsertAccountSmartCA()"
                                    [disabled]="isSavingAccountSignSmartCA" color="save">
                                    <span *ngIf="!isSavingAccountSignSmartCA">{{'NAV.luuthongtin'|translate}}</span>
                                    <span *ngIf="isSavingAccountSignSmartCA">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        {{'NAV.dangluu'|translate}}
                                    </span>
                                </button>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-8">

                        <div class="row boderBottom">
                            <div class="col-md-8 lableSign">
                                {{'usermanage.chuky'|translate}}
                            </div>
                            <div class="col-md-4">

                                <label class="btn btn-outline-primary waves-effect button-margin floatRight">
                                    <input #uploadfile type="file" id="file" (change)="getFileDetails($event)">
                                    <i class="fa fa-upload"></i> {{'themhs.upload'|translate}}
                                </label>
                            </div>


                        </div>
                        <br>
                        <div class="row itemSign">
                            <div *ngFor="let file of myFilesBase64; index as i" class="col-md-4">
                                <div>
                                    <img class="itemSign" [src]="file.value" height="75" width="75"
                                        (click)=showImage(i) />
                                    <br>
                                    <div>
                                        {{file.name}}
                                        <button mat-icon-button (click)="deleteFile(i)" class="clear" matTooltip="Xóa">
                                            <i class="material-icons">clear</i>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="form-group floatRight">
                            <button mat-raised-button (click)="display=false">{{'NAV.huy'|translate}}</button> &nbsp;
                            <button mat-raised-button (click)="updateSignatureImageList()"
                                [disabled]="isSavingImageSign" color="save">
                                <span *ngIf="!isSavingImageSign">{{'NAV.luuhinhanh'|translate}}</span>
                                <span *ngIf="isSavingImageSign">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    {{'NAV.dangluu'|translate}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>


            </mat-tab>

            <!-- <mat-tab label="{{'organmanage.thongtinkysosmartca'|translate}}">
                <br>
                <div class="row">
                    <div class="col-md-4 boderRight">
                        <form [formGroup]="signAccountSmartCA" class="sign-form">
                            <mat-form-field class="sign-full-width">
                                <mat-label>{{'usermanage.tendn'|translate}}</mat-label>
                                <input matInput placeholder="Ex. peter" formControlName="username" [ngClass]="{ 'is-invalid': submittedFormSmartCA && accountSmartCAForm.username.errors }">

                            </mat-form-field>

                            <div *ngIf="submittedFormSmartCA && accountSmartCAForm.username.errors" class="errorNotification">
                                <div *ngIf="accountSmartCAForm.username.errors.required">{{'organmanage.nhaptendn'|translate}}</div>
                            </div>

                            <mat-form-field class="sign-full-width">
                                <mat-label>{{'usermanage.matkhau'|translate}}</mat-label>
                                <input matInput placeholder="" type="password" formControlName="password" [ngClass]="{ 'is-invalid': submittedFormSmartCA && accountSmartCAForm.password.errors }">

                            </mat-form-field>

                            <div *ngIf="submittedFormSmartCA && accountSmartCAForm.password.errors" class="errorNotification">
                                <div *ngIf="accountSmartCAForm.password.errors.required">{{'usermanage.nhaplaimk'|translate}}</div>
                            </div>

                            <div *ngIf="errorMessage!==''" class="errorNotification">
                                {{errorMessage}}
                            </div>
                        </form>
                        <div class="form-group floatRight">

                            <button mat-raised-button (click)="upsertAccountSmartCA()" [disabled]="isSavingAccountSignSmartCA" color="save">
                                <span *ngIf="!isSavingAccountSignSmartCA">{{'NAV.luuthongtin'|translate}}</span>
                                <span *ngIf="isSavingAccountSignSmartCA">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {{'NAV.dangluu'|translate}}
                                 </span>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-8">

                        <div class="row boderBottom">
                            <div class="col-md-8 lableSign">
                                {{'usermanage.chuky'|translate}}
                            </div>
                            <div class="col-md-4">

                                <label class="btn btn-outline-primary waves-effect button-margin floatRight">
                                <input #uploadfileSmartCA type="file" id="file" (change)="getFileDetailsSmartCA($event)">
                                 <i class="fa fa-upload"></i> {{'themhs.upload'|translate}}
                                </label>
                            </div>
                        </div>
                        <br>
                        <div class="row itemSign">
                            <div *ngFor="let file of myFilesBase64SmartCA; index as i" class="col-md-4">
                                <div>
                                    <img class="itemSign" [src]="file.value" height="75" width="75" (click)=showImageSmartCA(i) />
                                    <br>
                                    <div>
                                        {{file.name}}
                                        <button mat-icon-button (click)="deleteFileSmartCA(i)" class="clear" matTooltip="Remove this element">
                                            <i class="material-icons">clear</i>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="form-group floatRight">
                            <button mat-raised-button (click)="display=false">{{'NAV.huy'|translate}}</button> &nbsp;
                            <button mat-raised-button (click)="updateSignatureImageListSmartCA()" [disabled]="isSavingImageSignSmartCA" color="save">
                                <span *ngIf="!isSavingImageSignSmartCA">{{'NAV.luuhinhanh'|translate}}</span>
                                <span *ngIf="isSavingImageSignSmartCA">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {{'NAV.dangluu'|translate}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

            </mat-tab> -->

        </mat-tab-group>



    </p-dialog>
</div>

<p-dialog [(header)]="headerTitle" [(visible)]="roleDisplay" [modal]="true" [responsive]="true"
    [style]="{width: '60%', minWidth: '60%'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000">
    <div class="table-responsive-md table-hover">
        <p-treeTable #tt [value]="roleData" [columns]="treeCols" selectionMode="checkbox" [(selection)]="selectedNodes3"
            (onNodeUnselect)="nodeUnselect($event)" (onNodeSelect)="nodeSelect($event)">
            <ng-template pTemplate="summary">
                <div style="text-align: left" [translate]="'NAV.menudachon'">
                    <span *ngIf="!selectedNodes3 || selectedNodes3.length === 0" style="font-weight: normal"
                        [translate]="'NAV.none'"></span>
                    <ul *ngIf="selectedNodes3 && selectedNodes3.length">
                        <li *ngFor="let node of selectedNodes3">
                            <span style="font-weight: normal">{{node.data.name + ' - ' + node.data.code}}</span>
                        </li>
                    </ul>
                </div>
            </ng-template>
            <ng-template pTemplate="caption">
                <div style="text-align:left">
                    <p-treeTableHeaderCheckbox></p-treeTableHeaderCheckbox>
                    <span style="margin-left: .25em; vertical-align: middle" [translate]="'NAV.chontatca'"></span>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns let-rowNode>
                <tr [ttRow]="rowNode">
                    <th *ngFor="let col of columns" [ttSortableColumn]="col.field">
                        {{col.header}}
                        <p-treeTableSortIcon [field]="col.field"></p-treeTableSortIcon>
                    </th>
                </tr>
                <tr [ttRow]="rowNode">
                    <th *ngFor="let col of columns">
                        <input pInputText type="text"
                            (input)="tt.filter($event.target.value, col.field, col.filterMatchMode)">
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                <tr>
                    <td *ngFor="let col of columns; let i = index">
                        <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                        <p-treeTableCheckbox [value]="rowNode" *ngIf="i == 0"></p-treeTableCheckbox>
                        {{rowData[col.field]}}
                    </td>
                </tr>
            </ng-template>
        </p-treeTable>
    </div>
    <div class="form-group" style="margin-top: 10px">

        <button mat-raised-button (click)="roleDisplay=false">{{'NAV.huy'|translate}}</button> &nbsp;
        <button mat-raised-button (click)="onSaveMenuList()" [disabled]="isSaving2" color="save">
            <span *ngIf="!isSaving2">{{'NAV.luu'|translate}}</span>
            <span *ngIf="isSaving2">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{'NAV.dangluu'|translate}}
            </span>
        </button>

        <!-- <button (click)="display=false" class="btn btn-danger" [translate]="'NAV.huy'"></button>
        <button (click)="onSaveMenuList()" class="ml-1 btn btn-primary" [disabled]="isSaving2">
            <span *ngIf="!isSaving2"> <i class="fa fa-id-card-o" [translate]="'NAV.luu'"></i> </span>
            <span *ngIf="isSaving2" [translate]="'NAV.dangluu'">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

            </span>
        </button> -->
    </div>
</p-dialog>

<p-dialog header="{{'NAV.danhsachthongbao'|translate}}" [(visible)]="notificationDisplay" [modal]="true"
    [responsive]="true" [style]="{width: '60%',height: '80%'}" [minY]="70" [maximizable]="true" [baseZIndex]="10000">
    <p-fieldset>
        <div *ngFor="let msg of messagesClient; let i = index" style='padding: 20px; border-bottom: 1px solid;'>
            <div class="row">
                <div class="col-md-1" style="color: red; font-size: larger; font-weight: bold;">
                    {{i+1}}
                </div>
                <div class="col-md-11">
                    <div>
                        {{msg.message}}
                    </div>
                    <div class="row day">
                        {{ 'NAV.ngay' | translate }} {{msg.date|date: 'dd/MM/yyyy, h:mm:ss a'}}
                    </div>
                </div>
            </div>

        </div>
    </p-fieldset>
</p-dialog>

<div class="addProfile">
    <p-dialog header="" [(visible)]="displayShowImage" [modal]="true" [responsive]="true"
        [style]="{width: '52%', height: '52%'}" [maximizable]="true" [baseZIndex]="10000">
        <div style="text-align: center">
            <p-scrollPanel>
                <img [src]="imageSrc" width="52%" height="52%">
            </p-scrollPanel>
        </div>
    </p-dialog>
</div>