
<!-- <div *ngIf="username!='admin.dv.caobang'">
    <div class="logo" *ngIf="bannerAddress == null">
        <div class="logo-normal">
            <a class="sidebar-titile" routerLink="/vnpt/dashboard" routerLinkActive="active">
                VNPT eDIG
            </a>
        </div>
    
        <div class="logo-img  ">
            <img  src="/assets/img/vnpt_edig_5.png" />        
        </div>
    </div>
    
    <div *ngIf="bannerAddress != null" class="logo-caobang">
        <div >
            <img class="logo-caobang-image" [src]="bannerAddress" />
        
        </div>
    </div>
</div> -->
<div class="logo-caobang">
    <div >
        <img class="logo-caobang-image" src="/assets/img/caobang.png"/>
    
    </div>
</div>


<div  class="sidebar-wrapper">   

   
        <!-- <ul class="nav"> -->
            <ul *ngIf="languageSidebar == 'vi'" class="nav">
            <li routerLinkActive="active" *ngFor="let menuitem of mArrMenuSort" class="nav-item">

                <a  class="nav-link" [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
                    <i [class]="menuitem.icon"></i>
                    <p>{{menuitem.title}}</p>
                </a>

                <a  class="nav-link" data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()">
                    <i [class]="menuitem.icon"></i>
                    <p>{{menuitem.title}}<b class="caret"></b></p>
                </a>

  
                <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                    <ul class="nav">
                        <li class="nav-link" routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
                            
                                    <a [routerLink]="[childitem.path]" class="nav-link">

                                        <span class="sidebar-normal">{{childitem.title}}</span>
   
                                    </a>

                        </li>
                    </ul>
                </div>
            </li>
            
        </ul>
        <ul *ngIf="languageSidebar == 'en'" class="nav">
            <li routerLinkActive="active" *ngFor="let menuitem of mArrMenuSort2" class="nav-item">

                <a  class="nav-link" [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
                    <i [class]="menuitem.icon"></i>
                    <p>{{menuitem.title}}</p>
                </a>

                <a  class="nav-link" data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()">
                    <i [class]="menuitem.icon"></i>
                    <p>{{menuitem.title}}<b class="caret"></b></p>
                </a>

  
                <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                    <ul class="nav">
                        <li class="nav-link" routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
                            
                                    <a [routerLink]="[childitem.path]" class="nav-link">

                                        <span class="sidebar-normal">{{childitem.title}}</span>
   
                                    </a>

                        </li>
                    </ul>
                </div>
            </li>
            
        </ul>
    
</div>


