import { Injectable } from '@angular/core';
// import { Cookie } from 'ng2-cookies';
import { oauth2 } from '../../environments/environment';
import { MessagesService } from './messages.service';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient, private messageService: MessagesService, private _router: Router, private noti: NotificationService) {
    // this.checkLogin();
  }

  obtainAccessToken(body, headers) {
    //return this.httpClient.post<any>(oauth2.getToken, body, { headers });
   //console.log("aaaaaa",this.httpClient.get<any>(`${environment.apiUrlVnptResource}/menu/alllist`))
    return this.httpClient.post<any>(`${environment.apiUrlVnptResource}/account/login`, body);
  }

  checkLogin() {
    const isLoggedIn = this.checkCredentials();
    this.changeLoginMessage(isLoggedIn);
  }

  changeLoginMessage(message: boolean) {
    this.messageService.changeLoginMessage(message);
  }

  saveToken(response) {
    localStorage.setItem('access_token', response.token);
    localStorage.setItem('user_name', response.userName);
    localStorage.setItem('full_name', response.fullName);
    localStorage.setItem('usernameSign', response.userNameSign);
    localStorage.setItem('passwordSign', response.passwordSign);
    localStorage.setItem('certIDSign', response.certIDSign);
    localStorage.setItem('organization', JSON.stringify(response.organization));
    localStorage.setItem('accountId', JSON.stringify(response.accountId));
    localStorage.setItem('workspace', JSON.stringify(response.workspace));
    localStorage.setItem('ftpAddress', JSON.stringify(response.ftpAddress));
    localStorage.setItem('ftpAddressViewPdf', JSON.stringify(response.ftpAddressViewPdf));
    localStorage.setItem('menu', JSON.stringify(response.menu));
    localStorage.setItem('notification', JSON.stringify(response.notification));
    localStorage.setItem('approvedAccount', JSON.stringify(response.approvedAccount));
    localStorage.setItem('isAccountOrganization', JSON.stringify(response.isAccountOrganization));
    localStorage.setItem('cart_item', JSON.stringify([]));
    localStorage.setItem('usernameSign', response.usernameSign);
    localStorage.setItem('passwordSign', response.passwordSign);
    localStorage.setItem('certIDSign', response.certIDSign);
    localStorage.setItem('permissionGroupId', JSON.stringify(response.permissionGroupId));

    // localStorage.setItem('clientIdSign', response.clientId);
    // localStorage.setItem('clientSecretSign', response.clientSecret);
    // localStorage.setItem('adminGroupEmailSign', response.adminGroupEmail);
    // localStorage.setItem('groupIdSign', response.groupIdSign);
    this.messageService.changeCartItem([]);
    //console.log('Obtained Access token');
    //window.location.href = oauth2.redirectUri;
    this._router.navigate(['/vnpt/dashboard']);
    //this._router.navigate(['/login']);
    this.checkLogin();
  }

  licenseNotification(response) {
     localStorage.setItem('error', response.token);
     localStorage.setItem('user_name', response.userName);
     localStorage.setItem('accountId', response.accountId);
     if(response.userName == "SystemAdmin"){
      localStorage.setItem('statusLicense', "SystemAdmin");
     }else{
      localStorage.setItem('statusLicense', "NormalAccount");
     }
     this._router.navigate(['/licenserequired']);
     //this.checkLogin();
   }

  expiryPasswordNotification(response) {
    localStorage.setItem('lastToken', response.lastToken);
    localStorage.setItem('accountId', response.accountId);
    this._router.navigate(['/changepasswordrequired']);
    //this.checkLogin();
  }




  checkCredentials() {

    // return false;
    return localStorage.getItem('access_token') ? true : false;
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  login() {
    this._router.navigate(['/login']);
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['/login']);
    //window.location.href = oauth2.redirectUri;
  }

}
