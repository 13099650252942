import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
  transform(value: string): string {
    return moment(value).format("DD/MM/YYYY HH:mm");
  }
}

@Pipe({ name: 'datetimeFormat' })
export class DateTimeFormatPipe implements PipeTransform {
  transform(value: string, type = "dd/MM/YYYY HH:mm"): string {
    return moment(value).format(type);
  }
}
