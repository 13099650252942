import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { GuardLoginService } from './services/guard-login.service';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { constantsValue } from '../app/const';
import { AuthGuard } from './services/guard.service';
import {LicenseRequiredComponent} from './pages/license-required/license-required.component';
import {ChangePasswordRequiredComponent} from './pages/change-password-required/change-password-required.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes =[
  // {
  //   path: '',
  //   redirectTo: 'vnpt/dashboard',
  //   pathMatch: 'full',
  // }, 
  { path: '', redirectTo: 'vnpt/dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [GuardLoginService] },
  { path: 'licenserequired', component: LicenseRequiredComponent},
  { path: 'changepasswordrequired', component: ChangePasswordRequiredComponent},
  
  {
    path: constantsValue.adminRootPath,
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      { path: 'dashboard',      component: DashboardComponent },
      { path: '', loadChildren: () => import('../app/layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)},   
      { path: 'Systems',  loadChildren: () => import('../app/layouts/systems-layout/systems-layout.module').then(m => m.SystemsLayoutModule) },
      { path: 'DanhMuc',  loadChildren: () => import('../app/layouts/danhmuc-layout/danhmuc-layout.module').then(m => m.DanhMucLayoutModule) },
      { path: 'SystemsAdmin',  loadChildren: () => import('../app/layouts/systems-admin-layout/system-admin-layout.module').then(m => m.SystemsAdminLayoutModule) },
    ]
  },
  
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
