import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AdminApiService } from '../../../app/services/admin-api.service';
import { NotificationService } from '../../services/notification.service';
import { AppGlobals } from '../../pages/common/app.global';
import { AbstractControl, ValidationErrors, FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { oauth2 } from '../../../environments/environment';
import { UtilityFunction } from '../../../app/services/utility-functions';


@Component({
  selector: 'app-change-password-required',
  templateUrl: './change-password-required.component.html',
  styleUrls: ['./change-password-required.component.scss']
})
export class ChangePasswordRequiredComponent implements OnInit {
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  captcha: string = undefined;
  public captchaIsLoaded: boolean = false;
  public captchaSuccess: boolean = false;
  public captchaIsExpired: boolean = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio'

  aFormGroup: FormGroup;
  changePassword: FormGroup;

  lastToken: string = "";
  licenseKey: string = "";
  submittedKey: boolean = true;
  isUpdate: boolean = false;
  siteKey = oauth2.captchaSiteKey;


  submittedForm: boolean;


  successNotificcation: string = "Thời gian sử dụng server đã được gia hạn đến ngày ";
  errorNotification: string = "License Key không hợp lệ";
  captchaRequired: string = "Vui lòng hoàn thành captcha";
  constructor(private cdr: ChangeDetectorRef, private _formBuilder: FormBuilder, private adminApiService: AdminApiService, private _router: Router, private notiService: NotificationService
    ,private utilityFunction: UtilityFunction,) { }

  ngOnInit() {

    this.aFormGroup = this._formBuilder.group({
      recaptcha: ['', Validators.required]
    });

    this.changePassword = this._formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}'), this.cannotContainSpace]],
      confirmPassword: ['', [Validators.required]]
    });

    this.lastToken = localStorage.getItem('lastToken');

    if (this.lastToken == "undefined" || this.lastToken == undefined) {
      this._router.navigate(['/login']);
    }

  }

  cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if (/\s/.test(control.value as string)) {
      return { cannotContainSpace: true }
    }

    return null;
  }

  get f() {
    return this.changePassword.controls;
  }

  checkCaptcha(data) {
    this.captcha = data;
    this.cdr.detectChanges();
  }

  handleCaptchaReset() {
    this.captcha = undefined;
    this.cdr.detectChanges();

  }


  goBackLogin() {
    localStorage.clear();
    this._router.navigate(['/login']);
  }

  checkLicenseKey() {
    if (this.licenseKey == undefined || this.licenseKey == "") {
      this.submittedKey = false;
      return;
    }

    if (AppGlobals.language == "vi") {
      this.successNotificcation = "Thời gian sử dụng server đã được gia hạn đến ngày ";
      this.errorNotification = "License Key không hợp lệ";
      this.captchaRequired = "Vui lòng hoàn thành captcha";
    } else {
      this.successNotificcation = "Server usage time has been extended to date ";
      this.errorNotification = "Invalid License Key";
      this.captchaRequired = "Please complete captcha";
    }

    // if(this.captcha == undefined){
    //   confirm(this.captchaRequired);
    //   return;
    // }



    this.isUpdate = true;
    this.submittedKey = true;
    var body = { key: this.licenseKey, captchaResponse: this.captcha };

    this.adminApiService.updateLicenseKey(body).pipe(first()).subscribe(data => {

      this.isUpdate = false;
      localStorage.clear();
      this.notiService.showSuccess(this.successNotificcation + data.outOfDate, undefined, undefined);

    }, error => {
      this.isUpdate = false;
      this.notiService.showError(this.errorNotification, undefined, undefined);
    });

  }

  onChangePassword() {
    this.submittedForm = true;

    if (this.changePassword.invalid) {
      alert('Điền đầy đủ thông tin');
      return;
    }
    let notiMessage = "";
    let notiSuccess = "";
    let notiError = "";
    let notiError2 = "";
    let notiError3 = "";
    let notiError4 = "";
    if (AppGlobals.language == 'vi') {
      notiMessage = "Xác nhận mật khẩu không trùng mới";
      notiSuccess = "Cập nhật mật khẩu thành không";
      notiError = "Mật khẩu cũ không chính xác";
      notiError2 = "Mật khẩu truyền vào rỗng";
      notiError3 = "Phiên hoạt động đã hết hạn";
      notiError4 = "Cập nhật mật khẩu không thành công";
    } else {
      notiMessage = "Confirm new password does not match";
      notiSuccess = "Update password to zero";
      notiError = "Old password is incorrect";
      notiError2 = "Password is empty";
      notiError3 = "Activity session has expired";
      notiError4 = "Password update failed";
    }

    if (this.changePassword.value.newPassword != this.changePassword.value.confirmPassword) {
      this.notiService.showError(notiMessage, undefined, undefined);
      this.submittedForm = false;
      return;
    }

    var accountId = localStorage.getItem('accountId');

    var submitData = { oldPassword: this.utilityFunction.encryptedpassword(this.changePassword.value.oldPassword), newPassword: this.utilityFunction.encryptedpassword(this.changePassword.value.newPassword), accountId: accountId };
    this.isUpdate = true;
    this.adminApiService.UpdateAccountExpiryPassword(submitData).pipe(first()).subscribe(data => {

      this.isUpdate = false;
      localStorage.clear();
      this._router.navigate(['/login']);
      this.notiService.showSuccess(notiSuccess, undefined, undefined);


    },
      error => {
        this.isUpdate = false;
        switch (error.error.text) {
          case "Old-Password-Is-Not-Correct": {
            this.notiService.showError(notiError, undefined, undefined);
            break;
          }
          case "Password-Is-Null": {
            this.notiService.showError(notiError2, undefined, undefined);
            break;
          }
          case "Token-Invalid-Or-You-Do-Not-Have-Permission-In-This-Area": {
            this.notiService.showError(notiError3, undefined, undefined);
            break;
          }
          default: {

            this.notiService.showError(notiError4, undefined, undefined);
            break;
          }
        }


      });

  }

}
