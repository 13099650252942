<div class="wrapper">
    <div class="sidebar" id="sidebarCustom" data-color="danger" data-background-color="white">
        <app-sidebar [languageSidebar]="currentLanguage"></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel" id="main-panel">
        <app-navbar (languageNavbar)="getLanguage($event)"></app-navbar>
        <router-outlet></router-outlet>
        <div *ngIf="getShowCartItem() === true" (click)="showDetailDialog('Đăng ký mượn hồ sơ')" class="float">
            <div class="notification2"></div>
        </div>
        <!-- <div *ngIf="isMaps('maps')">
            <app-footer></app-footer>
        </div> -->
    </div>
</div>

<div class="borrowform">
    <p-dialog [(header)]="headerTitle" [(visible)]="display" [modal]="true" [responsive]="true"
        [style]="{width: '90%', minWidth: '90%', height: '87%', overflow: 'auto'}" [maximizable]="true"
        [baseZIndex]="10000">

        <!-- Chọn hình thức mượn hồ sơ -->
        <div *ngIf="isShowChooseBorrowMethod" style="height: 70vh; display: grid; align-content: center;">
            <!-- <h5 style="text-align: center;" *ngIf="cartItem.length != 0">Vui lòng hình thức mượn hồ sơ</h5> -->
            <div *ngIf="cartItem.length != 0" style="padding-top: 20px;">
                <div style="display: flex; justify-content: center;">
                    <div class="file-borrow-method">
                        <img src="assets/img/paper.png" alt="">
                        <div>
                            <h5>{{'adminlayout.paperprofile'|translate}}</h5>
                            <p>
                                {{'adminlayout.descriptionpaper'|translate}}
                            </p>
                        </div>


                        <button class="btn-next" (click)="gotoStep1(0)">
                            <span>{{'adminlayout.borrowpaper'|translate}}</span>
                        </button>
                    </div>
                    <div style="align-self: center;">
                        <span style="zoom: 2; color: #2196f3;" class="material-icons">
                            swap_horiz
                        </span>
                    </div>
                    <div class="file-borrow-method">
                        <img src="assets/img/read-online.png" alt="">
                        <div>
                            <h5>{{'adminlayout.onlinepeofile'|translate}}</h5>
                            <p>
                                {{'adminlayout.descriptiononline'|translate}}
                            </p>
                        </div>

                        <button class="btn-next" (click)="gotoStep1(1)">
                            <span>{{'adminlayout.borrowonline'|translate}}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="cartItem.length == 0" style="display: grid; justify-items: center;">
                <img src="assets/img/empty-box.png" alt="">
                <h6 style="margin: 0px; align-self: center;"><a
                        style="pointer-events: none; text-transform: initial; font-weight: 400;">{{'validateCartItem.chuacohoso'|translate}}</a>
                </h6>
            </div>
        </div>

        <div *ngIf="!isShowChooseBorrowMethod" style="height: 70vh;">
            <!-- Mượn online -->
            <mat-horizontal-stepper *ngIf="isBorrowOnline" linear="true" #stepper
                (selectionChange)="changeIndex($event)">
                <mat-step [completed]="enableNext2">
                    <ng-template matStepLabel>{{stepTitle1}}</ng-template>
                    <div *ngIf="isprofilencap == 0">
                        <p-treeTable [value]="treedata">
                            <ng-template pTemplate="header" let-columns let-rowNode>
                                <tr [ttRow]="rowNode">
                                    <th *ngFor="let col of colunmTree" class="treeTableCustom">
                                        {{col.header}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                                <tr>
                                    <td class="treeTableCustom">
                                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                        {{rowData.name}}
                                    </td>
                                    <td class="treeTableCustom">
                                        {{rowData.documentName}}
                                    </td>
                                    <td *ngIf="rowData.isBorrow == 1" class="treeTableCustom">
                                        <section>
                                            <mat-checkbox (click)="selectDocument(rowData);">{{'barcode.dk'|translate}}
                                            </mat-checkbox>
                                        </section>
                                    </td>
                                    <td *ngIf="rowData.isBorrow == 0" class="treeTableCustom">
                                        Thành phần không cho phép mượn.
                                    </td>
                                    <td *ngIf="rowData.name != undefined" class="treeTableCustom">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div [ngClass]="checkCannotBeBorrowed(rowData.id ) ? 'statusProfileFail' : 'statusProfile'"
                                                    style="margin-top: 10px;">
                                                    {{(checkCannotBeBorrowed(rowData.id) ? 'adminlayout.unavailable' :
                                                    'adminlayout.available') | translate}}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <button mat-mini-fab color="warn" (click)="deleteCartItem2(rowData)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                    </td>

                                </tr>
                            </ng-template>
                        </p-treeTable>

                        <div class="button-next">
                            <p *ngIf="!enableNext2">*{{'adminlayout.removeNote'|translate}}</p>
                            <button mat-raised-button (click)="checkEnableNext2()" matStepperNext>
                                {{'adminlayout.next'|translate}}</button>
                        </div>
                    </div>
                    <div *ngIf="isprofilencap == 1">
                        <p-treeTable [value]="treedata">
                            <ng-template pTemplate="header" let-columns let-rowNode>
                                <tr [ttRow]="rowNode">
                                    <th *ngFor="let col of colunmTree1" class="treeTableCustom">
                                        {{col.header}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                                <tr>
                                    <td class="treeTableCustom">
                                        {{rowData.name}}
                                    </td>
                                    <td *ngIf="rowData.name != undefined" class="treeTableCustom">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div [ngClass]="checkCannotBeBorrowed(rowData.id ) ? 'statusProfileFail' : 'statusProfile'"
                                                    style="margin-top: 10px;">
                                                    {{(checkCannotBeBorrowed(rowData.id) ? 'adminlayout.unavailable' :
                                                    'adminlayout.available') | translate}}
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <button mat-mini-fab color="warn" (click)="deleteCartItem2(rowData)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div> -->
                                        </div>

                                    </td>
                                    <td class="treeTableCustom">
                                        <button mat-mini-fab color="warn" (click)="deleteCartItem2(rowData)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-treeTable>

                        <div class="button-next">
                            <p *ngIf="!enableNext2">*{{'adminlayout.removeNote'|translate}}</p>
                            <button mat-raised-button (click)="checkEnableNext2()" matStepperNext>
                                {{'adminlayout.next'|translate}}</button>
                        </div>
                    </div>
                </mat-step>
                <mat-step [label]="stepTitle2">
                    <div *ngIf="listDocumentSelect.length < 1 && listProfileAndDocumentSelect.length < 1"
                        class="example-container mat-elevation-z8 step2">
                        <br><br><br><br><br>
                        <h5>{{'themhs.canitnhat'|translate}}</h5>
                    </div>
                    <div *ngIf="listDocumentSelect.length > 0 || listProfileAndDocumentSelect.length > 0">
                        <div class="row" *ngIf="cartItem.length != 0">
                            <div class="col-md-12">
                                <h6 style="margin-top: 0px; color: red;"><a style="cursor: pointer;">{{errorInfo}}</a>
                                </h6>
                                <div class="mat-elevation-z8  card-header colorPanel">
                                    <div class="row">

                                        <div class="col-md-6">

                                            <mat-form-field class="full-width">
                                                <mat-label>{{'adminhome.nguoimuon'|translate}}</mat-label>
                                                <input matInput [disabled]="true" [ngModel]="nguoimuon">
                                            </mat-form-field>

                                        </div>

                                        <div class="col-md-6">

                                            <mat-form-field class="full-width">
                                                <mat-label>{{'adminhome.donvi'|translate}}</mat-label>
                                                <input matInput [disabled]="true" [ngModel]="tenDonVi">
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="row " *ngIf="isExistanceOfApprovalAccount">

                                        <div class="col-md-6">

                                            <mat-form-field>
                                                <mat-label>{{'adminhome.nguoipheduyet'|translate}}</mat-label>
                                                <mat-select [(ngModel)]="registrationForm.approvedAccount">
                                                    <mat-option *ngFor="let item of nguoiPheDuyet" [value]="item.value">
                                                        {{item.label}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>


                                        </div>
                                        <div class="col-md-6 ">
                                            <button class="toolTipInfo" mat-icon-button #tooltip="matTooltip"
                                                matTooltip="{{'adminhome.xemnguoiduyet'|translate}}"
                                                (click)="ShowApprovedAccount()">
                                                <mat-icon>info</mat-icon>
                                            </button>
                                        </div>

                                    </div>
                                    <div class="row ">

                                        <div class="col-md-12">

                                            <mat-form-field class="full-width">
                                                <mat-label>{{'adminhome.mucdich'|translate}}</mat-label>
                                                <textarea maxlength="255" matInput
                                                    [(ngModel)]="registrationForm.purposeExploitation"></textarea>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="row ">

                                        <div class="col-md-12">

                                            <mat-form-field class="full-width">
                                                <mat-label>{{'adminhome.noidung'|translate}}</mat-label>
                                                <textarea maxlength="255" matInput
                                                    [(ngModel)]="registrationForm.recommendedContent"></textarea>
                                            </mat-form-field>

                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-md-6">

                                            <mat-form-field>
                                                <mat-label>{{'adminhome.ngaymuon'|translate}}</mat-label>
                                                <input readonly disabled matInput [matDatepicker]="pickerBorrow"
                                                    [(ngModel)]="registrationForm.borrowDate">
                                                <mat-datepicker-toggle class="mat-date-picker-size" matSuffix
                                                    [for]="pickerBorrow"></mat-datepicker-toggle>
                                                <mat-datepicker #pickerBorrow></mat-datepicker>
                                                <p class="input-err" *ngIf="borrowDateIsError==1">
                                                    {{'adminhome.ngaymuonIsNull'|translate}}</p>
                                                <p class="input-err" *ngIf="borrowDateIsError==2">
                                                    {{'adminhome.ngaymuonIsNotCurr'|translate}}</p>
                                                <p class="input-err" *ngIf="borrowDateIsError==3">
                                                    {{'adminhome.ngayIsInvalid'|translate}}</p>
                                            </mat-form-field>


                                        </div>

                                        <div class="col-md-6">

                                            <mat-form-field>
                                                <mat-label>{{'adminhome.ngaytra'|translate}}</mat-label>
                                                <input readonly matInput [matDatepicker]="pickerPayDate"
                                                    [(ngModel)]="registrationForm.payDate">
                                                <mat-datepicker-toggle class="mat-date-picker-size" matSuffix
                                                    [for]="pickerPayDate"></mat-datepicker-toggle>
                                                <mat-datepicker #pickerPayDate></mat-datepicker>
                                                <p class="input-err" *ngIf="payDateIsError==1">
                                                    {{'adminhome.ngaytraIsNull'|translate}}</p>
                                                <p class="input-err" *ngIf="payDateIsError==2">
                                                    {{'adminhome.ngaytraIsNotGTCurr'|translate}}</p>
                                                <p class="input-err" *ngIf="payDateIsError==3">
                                                    {{'adminhome.ngayIsInvalid'|translate}}</p>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="button-next">
                <button mat-raised-button matStepperPrevious>{{'adminlayout.back'|translate}}</button>
                <button mat-raised-button color="success" style="margin-left: 10px" (click)="createBorrowProfile()"
                  [disabled]="isSaving2">
                  <span *ngIf="!isSaving2">{{'NAV.luu'|translate}}</span>
                  <span *ngIf="isSaving2">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{'NAV.dangluu'|translate}}
                  </span>
                </button>
              </div> -->
                            </div>


                            <div class="col-md-12 marginTopTable">
                                <h4 class="cssH4">{{'borrow.dsdkmuon'|translate}}</h4>
                                <p-treeTable [value]="listProfileAndDocumentSelect">
                                    <ng-template pTemplate="header" let-columns let-rowNode>
                                        <tr [ttRow]="rowNode">
                                            <th class="treeTableCustom">
                                                {{'approval.tenhs'|translate}}
                                            </th>
                                            <th class="treeTableCustom">
                                                {{'approval.tentl'|translate}}
                                            </th>
                                            <th class="treeTableCustom">
                                                {{'adminlayout.status'|translate}}
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                                        <tr>
                                            <td class="treeTableCustom">
                                                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                                {{rowData.name}}
                                            </td>
                                            <td class="treeTableCustom">
                                                {{rowData.documentName}}
                                            </td>
                                            <td *ngIf="rowData.name != null" class="treeTableCustom">
                                                <mat-checkbox [(ngModel)]="rowData.isView" binary="true"
                                                    [disabled]=true>
                                                    {{'approval.xem'|translate}}
                                                </mat-checkbox>
                                                <mat-checkbox [(ngModel)]="rowData.isDownload" style="margin-left:30px">
                                                    {{'adminhome.tai'|translate}}
                                                </mat-checkbox>
                                            </td>
                                            <td *ngIf="rowData.name == null" class="treeTableCustom">
                                                {{'adminlayout.dadkmuon'|translate}}
                                            </td>

                                        </tr>
                                    </ng-template>
                                </p-treeTable>


                            </div>
                            <div class="col-md-12">
                                <div class="button-next">
                                    <button mat-raised-button
                                        matStepperPrevious>{{'adminlayout.back'|translate}}</button>
                                    <button mat-raised-button color="success" style="margin-left: 10px"
                                        (click)="createBorrowProfile()" [disabled]="isSaving2">
                                        <span *ngIf="!isSaving2">{{'NAV.luu'|translate}}</span>
                                        <span *ngIf="isSaving2">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            {{'NAV.dangluu'|translate}}
                                        </span>
                                    </button>
                                </div>
                            </div>


                        </div>

                        <div class="row" *ngIf="cartItem.length == 0">
                            <div class="col-md-8">
                                <h6 style="margin-top: 0px; color: red;">
                                    <a>{{'validateCartItem.chuacohoso'|translate}}</a></h6>
                            </div>
                        </div>
                    </div>


                </mat-step>
            </mat-horizontal-stepper>

            <!-- Mượn giấy -->
            <mat-horizontal-stepper *ngIf="!isBorrowOnline" [linear]="true" #stepper>
                <mat-step [completed]="enableNext">
                    <ng-template style="padding-bottom: 25px;" matStepLabel>
                        {{'adminlayout.listofborrowprofiles'|translate}}
                    </ng-template>

                    <div>
                        <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">

                            <ng-container matColumnDef="imageFolder">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element"> <img class="imageFolder"
                                        src="assets/img/1-0.png" /> </td>
                            </ng-container>

                            <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef> {{'adminlayout.code'|translate}} </th>
                                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> {{'adminlayout.title'|translate}} </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="attachments">
                                <th mat-header-cell *matHeaderCellDef> {{'adminlayout.dateoffiling'|translate}} </th>
                                <td mat-cell *matCellDef="let element"> {{element.createdOn | date: 'dd/MM/yyyy'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="documents">
                                <th mat-header-cell *matHeaderCellDef> {{'adminlayout.type'|translate}} </th>
                                <td mat-cell *matCellDef="let element"> {{element.category.name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="partition">
                                <th mat-header-cell *matHeaderCellDef> {{'adminlayout.archives'|translate}} </th>
                                <td mat-cell *matCellDef="let element"> {{element.wareName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef> {{'adminlayout.status'|translate}} </th>
                                <td mat-cell *matCellDef="let element">
                                    <div
                                        [ngClass]="checkCannotBeBorrowedPaper(element.id) ? 'statusProfileFail' : 'statusProfile'">
                                        <span style="zoom: .3;" class="material-icons">
                                            circle
                                        </span> {{(checkCannotBeBorrowedPaper(element.id) ? 'adminlayout.unavailable' :
                                        'adminlayout.available') | translate}}
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="feature">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">
                                    {{'adminlayout.actions'|translate}}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="button-container">
                                        <button mat-icon-button color="warn"
                                            matTooltip="{{'adminlayout.removefromcart'|translate}}"
                                            (click)="deleteCartItem(element)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns3;"></tr>
                        </table>
                    </div>

                    <div class="button-next">
                        <p *ngIf="!enableNext">*{{'adminlayout.removeNote'|translate}}</p>
                        <button mat-raised-button (click)="checkEnableNext()" matStepperNext>
                            {{'adminlayout.next'|translate}}
                        </button>
                    </div>
                </mat-step>
                <mat-step label="{{'adminlayout.confirminformation'|translate}}">

                    <div class="row" *ngIf="cartItem.length != 0">
                        <div class="col-md-8">
                            <h6 style="margin-top: 0px; color: red;"><a style="cursor: pointer;">{{errorInfo}}</a></h6>
                            <div class="mat-elevation-z8  card-header colorPanel">
                                <div class="row">

                                    <div class="col-md-6">

                                        <mat-form-field class="full-width">
                                            <mat-label>{{'adminhome.nguoimuon'|translate}}</mat-label>
                                            <input matInput [readonly]="true" [ngModel]="nguoimuon">
                                        </mat-form-field>



                                    </div>

                                    <div class="col-md-6">

                                        <mat-form-field class="full-width">
                                            <mat-label>{{'adminhome.donvi'|translate}}</mat-label>
                                            <input matInput [readonly]="true" [ngModel]="tenDonVi">
                                        </mat-form-field>

                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-md-6">
                                        <form class="example-form">
                                            <mat-form-field class="full-width">
                                                <mat-label>{{'adminlayout.phone'|translate}}</mat-label>
                                                <input matInput [formControl]="phone" maxlength="20"
                                                    (change)="checkValidPhone()" required>
                                                <p class="input-err" *ngIf="phoneIsError">{{getErrorPhoneMessage()}}</p>
                                            </mat-form-field>
                                        </form>
                                    </div>

                                    <div class="col-md-6">
                                        <form class="example-form">
                                            <mat-form-field class="full-width">
                                                <mat-label>{{'adminlayout.citizenidentification'|translate}}</mat-label>
                                                <input matInput [formControl]="IDNumber" maxlength="20"
                                                    (change)="checkValidID()" required>
                                                <p class="input-err" *ngIf="IDIsError">{{getErrorIDMessage()}}</p>
                                            </mat-form-field>
                                        </form>
                                    </div>
                                </div>

                                <div class="row ">

                                    <div class="col-md-6">

                                        <mat-form-field>
                                            <mat-label>{{'adminhome.nguoipheduyet'|translate}}</mat-label>
                                            <mat-select [(ngModel)]="registrationForm.approvedAccount">
                                                <mat-option *ngFor="let item of nguoiPheDuyet" [value]="item.value">
                                                    {{item.label}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>


                                    </div>
                                    <div class="col-md-6 ">
                                        <button class="toolTipInfo" mat-icon-button #tooltip="matTooltip"
                                            matTooltip="'Thông tin người phê duyệt'" (click)="ShowApprovedAccount()">
                                            <mat-icon>info</mat-icon>
                                        </button>
                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-md-6">

                                        <mat-form-field>
                                            <mat-label>{{'adminhome.ngaymuon'|translate}}</mat-label>
                                            <input readonly disabled matInput [matDatepicker]="pickerBorrow"
                                                [(ngModel)]="registrationForm.borrowDate"
                                                (change)="checkValidBorrowDate()" (dateChange)="checkValidBorrowDate()"
                                                required>
                                            <p class="input-err" *ngIf="borrowDateIsError==1">
                                                {{'adminhome.ngaymuonIsNull'|translate}}</p>
                                            <p class="input-err" *ngIf="borrowDateIsError==2">
                                                {{'adminhome.ngaymuonIsNotCurr'|translate}}</p>
                                            <p class="input-err" *ngIf="borrowDateIsError==3">
                                                {{'adminhome.ngayIsInvalid'|translate}}</p>
                                            <mat-datepicker-toggle class="mat-date-picker-size" matSuffix
                                                [for]="pickerBorrow"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerBorrow></mat-datepicker>
                                        </mat-form-field>


                                    </div>

                                    <div class="col-md-6">

                                        <mat-form-field>
                                            <mat-label>{{'adminhome.ngaytra'|translate}}</mat-label>
                                            <input readonly matInput [matDatepicker]="pickerPayDate"
                                                [(ngModel)]="registrationForm.payDate"
                                                (change)="checkValidPaymentDate()"
                                                (dateChange)="checkValidPaymentDate()" required>
                                            <p class="input-err" *ngIf="payDateIsError==1">
                                                {{'adminhome.ngaytraIsNull'|translate}}</p>
                                            <p class="input-err" *ngIf="payDateIsError==2">
                                                {{'adminhome.ngaytraIsNotGTCurr'|translate}}</p>
                                            <p class="input-err" *ngIf="payDateIsError==3">
                                                {{'adminhome.ngayIsInvalid'|translate}}</p>
                                            <mat-datepicker-toggle class="mat-date-picker-size" matSuffix
                                                [for]="pickerPayDate"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerPayDate></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row ">

                                    <div class="col-md-12">

                                        <mat-form-field class="full-width">
                                            <mat-label>{{'adminhome.mucdich'|translate}}</mat-label>
                                            <textarea maxlength="255" matInput
                                                [(ngModel)]="registrationForm.purposeExploitation"></textarea>
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="row ">

                                    <div class="col-md-12">

                                        <mat-form-field class="full-width">
                                            <mat-label>{{'adminhome.noidung'|translate}}</mat-label>
                                            <textarea maxlength="255" matInput
                                                [(ngModel)]="registrationForm.recommendedContent"></textarea>
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-4 marginTopTable">


                            <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8">

                                <ng-container matColumnDef="stt">
                                    <th mat-header-cell *matHeaderCellDef class="headerTable">
                                        {{'adminlayout.stt'|translate}} </th>
                                    <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
                                </ng-container>

                                <ng-container matColumnDef="codeProfile">
                                    <th mat-header-cell *matHeaderCellDef class="headerTable">
                                        {{'adminhome.ma'|translate}} </th>
                                    <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns4"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns4;"></tr>
                            </table>

                            <div class="button-next-2">
                                <button mat-raised-button matStepperPrevious>{{'adminlayout.back'|translate}}</button>
                                <button mat-raised-button (click)="registerBorrowPaper()"
                                    style="margin-left: 10px; background-color: #107C10; color: white;"
                                    [disabled]="isSaving2">
                                    <span *ngIf="!isSaving2">{{'NAV.luu'|translate}}</span>
                                    <span *ngIf="isSaving2">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        {{'NAV.dangluu'|translate}}
                                    </span>
                                </button>
                            </div>

                        </div>


                    </div>

                    <div class="row" *ngIf="cartItem.length == 0">
                        <div class="col-md-8">
                            <h6 style="margin-top: 0px; color: red;"><a
                                    style="pointer-events: none;">{{'validateCartItem.chuacohoso'|translate}}</a></h6>
                        </div>
                    </div>



                </mat-step>
            </mat-horizontal-stepper>
        </div>


    </p-dialog>
</div>
<div class="borrowform">
    <p-dialog [(header)]="headerTitlettnd" [(visible)]="displayApprovalAccount" [modal]="true" [responsive]="true"
        [style]="{width: '40%', minWidth: '40%'}" [maximizable]="true" [baseZIndex]="10000">
        <div class="well well-lg card card-block card-header">
            <br>
            <!-- <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="full-width">
                        <mat-label>{{'adminhome.manguoiduyet'|translate}}</mat-label>
                        <input matInput [readonly]="true" [ngModel]="ApprovalAccount.code">
                    </mat-form-field>

                </div>
            </div> -->
            <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="full-width">
                        <mat-label>{{'adminhome.tennguoiduyet'|translate}}</mat-label>
                        <input matInput [readonly]="true" [ngModel]="ApprovalAccount.name">
                    </mat-form-field>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="full-width">
                        <mat-label>{{'NAV.sdt'|translate}}</mat-label>
                        <input matInput [readonly]="true" [ngModel]="ApprovalAccount.phoneNumber">
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="full-width">
                        <mat-label>{{'adminhome.cmnd'|translate}}</mat-label>
                        <input matInput [readonly]="true" [ngModel]="ApprovalAccount.cmnd">
                    </mat-form-field>
                </div>
            </div> -->
            <!-- <div class="row form-group">
                <div class="col-sm-12">
                    <mat-form-field class="full-width">
                        <mat-label>{{'adminhome.congtac'|translate}}</mat-label>
                        <input matInput [readonly]="true" [ngModel]="ApprovalAccount.workingAt">
                    </mat-form-field>
                </div>
            </div> -->
        </div>
    </p-dialog>
</div>



<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>