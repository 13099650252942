import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function PhoneValidator(): ValidatorFn {
    let reg = /((\+84|84|0)[3|5|7|8|9])+([0-9]{8})\b/;

    return (control: AbstractControl): ValidationErrors | null => {
        if(control.value == null){
            return;
        }
        if (control.value.startsWith("+84")) {
            if (control.value.length !== 12)
                return { phonenumber: { value: control.value } }
        }

        if (control.value.startsWith("84")) {
            if (control.value.length !== 11)
                return { phonenumber: { value: control.value } }
        }

        if (control.value.startsWith("0")) {
            if (control.value.length !== 10)
                return { phonenumber: { value: control.value } }
        }

        const forbidden = reg.test(control.value);
        return !forbidden ? { phonenumber: { value: control.value } } : null;
    };
}
