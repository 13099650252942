import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AdminApiService } from '../../../app/services/admin-api.service';
import { NotificationService } from '../../services/notification.service';
import { AppGlobals } from '../../pages/common/app.global';
import { AbstractControl, FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { oauth2 } from '../../../environments/environment';

@Component({
  selector: 'app-license-required',
  templateUrl: './license-required.component.html',
  styleUrls: ['./license-required.component.scss']
})
export class LicenseRequiredComponent implements OnInit {
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  captcha: string = undefined;
  public captchaIsLoaded: boolean = false;
  public captchaSuccess: boolean = false;
  public captchaIsExpired: boolean = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio'

  aFormGroup: FormGroup;

  statusLicense: string = "";
  licenseKey: string = "";
  submittedKey: boolean = true;
  isUpdate: boolean = false;
  siteKey = oauth2.captchaSiteKey;


  successNotificcation: string = "Thời gian sử dụng server đã được gia hạn đến ngày ";
  errorNotification: string = "License Key không hợp lệ";
  captchaRequired: string = "Vui lòng hoàn thành captcha";

  constructor(private cdr: ChangeDetectorRef, private _formBuilder: FormBuilder, private adminApiService: AdminApiService, private _router: Router, private notiService: NotificationService) { }

  ngOnInit(){

    this.aFormGroup = this._formBuilder.group({
      recaptcha: ['', Validators.required]
    });

    this.statusLicense = localStorage.getItem('statusLicense');

    if(this.statusLicense == "undefined" || this.statusLicense == undefined){
      this._router.navigate(['/login']);
    }


  }

  checkCaptcha(data) {
    this.captcha = data;
    this.cdr.detectChanges();
  }

  handleCaptchaReset(){
    this.captcha = undefined;
    this.cdr.detectChanges();

  }


  goBackLogin(){
    localStorage.clear();
    this._router.navigate(['/login']);
  }

  checkLicenseKey(){
    if(this.licenseKey == undefined || this.licenseKey == ""){
      this.submittedKey = false;
      return;
    }

    if (AppGlobals.language == "vi") {
      this.successNotificcation = "Thời gian sử dụng server đã được gia hạn đến ngày ";
      this.errorNotification = "License Key không hợp lệ";
      this.captchaRequired = "Vui lòng hoàn thành captcha";
    }else{
      this.successNotificcation = "Server usage time has been extended to date ";
      this.errorNotification = "Invalid License Key";
      this.captchaRequired = "Please complete captcha";
    }

    if(this.captcha == undefined){
      confirm(this.captchaRequired);
      return;
    }



    this.isUpdate = true;
    this.submittedKey = true;
    var body = {key:this.licenseKey,captchaResponse: this.captcha };

    this.adminApiService.updateLicenseKey(body).pipe(first()).subscribe(data => {

      this.isUpdate = false;
      localStorage.clear();
      this.notiService.showSuccess(this.successNotificcation + data.outOfDate, undefined, undefined);
      this.captcha = undefined;
      this.captchaElem.reloadCaptcha();
    }, error =>{
      this.isUpdate = false;
      this.notiService.showError(this.errorNotification, undefined, undefined);
      this.captcha = undefined;
      this.captchaElem.reloadCaptcha();
    });

  }

}
