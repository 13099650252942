import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { LoginComponent } from '../app/pages/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppInterceptor } from './services/interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { OverlayModule } from "@angular/cdk/overlay";
import {ConfirmationService } from 'primeng/api';


// import { ProfileLayoutComponent } from './layouts/profile-layout/profile-layout.component';
import { SystemsLayoutComponent } from './layouts/systems-layout/systems-layout.component';
import { DanhmucLayoutComponent } from './layouts/danhmuc-layout/danhmuc-layout.component';
// import { ProfileLayoutComponent } from './layouts/profile-layout/profile-layout.component';
import { SystemsAdminLayoutComponent } from './layouts/systems-admin-layout/systems-admin-layout.component';
import { ExcelService } from '../app/services/excel.service';
import { DataService} from './services/data.service';
import { MessagesService} from './services/messages.service';


import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TreeTableModule } from 'primeng/treetable';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FileUploadModule} from 'primeng/fileupload';
import { StepsModule } from 'primeng/steps';
import { TreeModule } from 'primeng/tree';
import {DataViewModule} from 'primeng/dataview';
import {RatingModule} from 'primeng/rating';

// import { ArchwizardModule } from 'angular-archwizard';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ChatService} from './services/pipe/chat.service';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import { FileManagerModule} from './components/public_api';
import { ProgressSpinnerDialogComponent } from './pages/common/progress-spinner-dialog/progress-spinner-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxCaptchaModule } from 'ngx-captcha';
// import { MyDriveMinioComponent } from './pages/my-drive-minio/my-drive-minio.component';
import { FileService } from './services/file-service.service';
import { FileExplorerModule } from './components/file-explorer/file-explorer.module';
import { MatCardModule } from '@angular/material/card';



import { MatTableModule } from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';

import {MatCheckboxModule} from '@angular/material/checkbox';

import {LicenseRequiredComponent} from './pages/license-required/license-required.component';
import {ChangePasswordRequiredComponent} from './pages/change-password-required/change-password-required.component';
import { NgApexchartsModule} from "ng-apexcharts";

@NgModule({
  imports: [
    NgApexchartsModule,
    MatCardModule,
    FileExplorerModule,
    MatTableModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatCheckboxModule,
    NgxCaptchaModule,
    MatProgressSpinnerModule,
    FileManagerModule,
    MatButtonModule,
    MatStepperModule,
    // ArchwizardModule,
    TableModule,
    PaginatorModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    TabViewModule,
    DialogModule,
    SplitButtonModule,
    ScrollPanelModule,
    PanelMenuModule,
    TreeTableModule,
    InputSwitchModule,
    ConfirmDialogModule,
    CheckboxModule,
    FieldsetModule,
    PanelModule,
    TooltipModule,
    RadioButtonModule ,
    FileUploadModule,
    StepsModule,
    TreeModule,
    DataViewModule,
    RatingModule,
    OverlayModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    // ProfileLayoutComponent,
    DanhmucLayoutComponent,
    SystemsLayoutComponent,
    // ProfileLayoutComponent,
    SystemsAdminLayoutComponent,
    // QuanLyDoanhNghiepComponent,
    // DoiSoatNoiSuDungComponent,
    ProgressSpinnerDialogComponent,
    LicenseRequiredComponent,
    ChangePasswordRequiredComponent,
    // ViewProfileNotDocumentComponent,
    // ViewProfileNormalComponent,
    // EditProfileNewComponent,
    // OcrMultipleComponent,
    // LoadFolderComponent,
    //ImportProfileStructureComponent,
    // ScannerTestComponent,
    // TopicTreeComponent,
    // DanhMucFontMenuComponent,
    // MyDriveMinioComponent
  ],
  providers: [
    ConfirmationService,
    ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    DataService,
    ChatService,
    MessagesService,
    FileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    public traslate: TranslateService, private dataService: DataService
  ){
    this.traslate.addLangs(['vi', 'en']);
    this.traslate.setDefaultLang('vi');
    // this.traslate.use('vi');
    this.dataService.currentMessage.subscribe(message => this.traslate.use(message));
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
