import { Component, OnInit, ViewChild  } from '@angular/core';

import { AdminApiService } from '../services/admin-api.service';
import { first } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { Chart } from 'chart.js';
import { DanhMucApiService } from '../services/danhmuc-api.service';
import { ChatService} from '../services/pipe/chat.service'
import { DatePipe } from '@angular/common';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexFill,
  ApexLegend,
  ApexYAxis,
  ApexGrid
} from "ng-apexcharts";
// import * as Chartist from 'chartist';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
  grid: ApexGrid;
  labels: string[]; 
  yaxis: ApexYAxis | ApexYAxis[];
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  users = [];
  username: string;
  profileNormal: string="0";
  profileBorrowed: string="0";
  profilePending: string="0";

  organizationCount: string="0";
  accountCount: string="0";
  profileNormalAll: string="0";
  profileBorrowedAll: string="0";
  profilePendingAll: string="0";
  accountNumber:any = 0;
  
  
  accountNumberOnline:any = 0;
  fileCount: string="0";
  profilePublish: string="0";

  @ViewChild("chart",{static:false}) chart: ChartComponent;
  @ViewChild("chartFont",{static:false}) chartFont: ChartComponent;
  chartOptions: Partial<ChartOptions>;
  chartOptionsAdmin: Partial<ChartOptions>;
  chartOptionsFont: Partial<ChartOptions>;



  public lineBigDashboardChartType;
  public gradientStroke;
  public chartColor;
  public canvas : any;
  public ctx;
  public gradientFill;
  public lineBigDashboardChartData:Array<any>;
  public lineBigDashboardChartOptions:any;
  public lineBigDashboardChartLabels:Array<any>;
  public lineBigDashboardChartColors:Array<any>

  public gradientChartOptionsConfiguration: any;
  public gradientChartOptionsConfigurationWithNumbersAndGrid: any;

  public lineChartType;
  public lineChartData:Array<any>;
  public lineChartOptions:any;
  public lineChartLabels:Array<any>;
  public lineChartColors:Array<any>

  public lineChartWithNumbersAndGridType;
  public lineChartWithNumbersAndGridData:Array<any>;
  public lineChartWithNumbersAndGridOptions:any;
  public lineChartWithNumbersAndGridLabels:Array<any>;
  public lineChartWithNumbersAndGridColors:Array<any>

  public lineChartGradientsNumbersType;
  public lineChartGradientsNumbersData:Array<any>;
  public lineChartGradientsNumbersOptions:any;
  public lineChartGradientsNumbersLabels:Array<any>;
  public lineChartGradientsNumbersColors:Array<any>;

  isRefresh = false;
  isRefreshFont = false;

  
  chart1 = {
    data: {
      labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      datasets: [{
        label: 'Premium',
        data: [50, 80, 60, 120, 80, 100, 60],
        backgroundColor: 'transparent',
        borderColor: '#5b6582',
        borderWidth: 2
      },
      {
        label: 'Free',
        data: [100, 60, 80, 50, 140, 60, 100],
        backgroundColor: 'transparent',
        borderColor: '#36a2eb',
        borderWidth: 2
      }
      ]
    },
    options: {
      scales: {
        yAxes: [{
          ticks: {
            fontColor: 'rgba(0,0,0,.6)',
            fontStyle: 'bold',
            beginAtZero: true,
            maxTicksLimit: 8,
            padding: 10
          }
        }]
      },
      responsive: true,
      legend: {
        position: 'bottom',
        display: false
      },
    }
  };
  chart2 = {
    data: {
      labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      datasets: [{
        label: 'Premium',
        data: [50, 80, 60, 120, 80, 100, 60],
        backgroundColor: '#5b6582',
        borderColor: '#5b6582',
        borderWidth: 2
      },
      {
        label: 'Free',
        data: [100, 60, 80, 50, 140, 60, 100],
        backgroundColor: '#36a2eb',
        borderColor: '#36a2eb',
        borderWidth: 2
      }
      ]
    },
    options: {
      barValueSpacing: 1,
      scales: {
        yAxes: [{
          ticks: {
            fontColor: 'rgba(0,0,0,.6)',
            fontStyle: 'bold',
            beginAtZero: true,
            maxTicksLimit: 8,
            padding: 10
          }
        }],
        xAxes: [{
          barPercentage: 0.4
        }]
      },
      responsive: true,
      legend: {
        position: 'bottom',
        display: false
      },
    }
  };
  chartCreate = {
    data: {
      labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
      datasets: [{
        label: 'Số hồ sơ tạo',
        data: [10, 12, 8, 8, 12],
        backgroundColor: '#002340',
        borderColor: '#002340',
        // backgroundColor: '#ffffff',
        // borderColor: '#ffffff',
        // color: '#ffffff',
        borderWidth: 2
      }
      ]
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        labels: {
          render: 'value'
        }
      },
      barValueSpacing: 1,
      scales: {
        yAxes: [{
          ticks: {
            //fontColor: 'rgba(0,0,0,.6)',
            // fontColor: '#ffffff',
            fontStyle: 'bold',
            beginAtZero: true,
            maxTicksLimit: 8,
            padding: 10
          }
        }],
        xAxes: [{
          barPercentage: 0.4,
          ticks: {
            // fontColor: '#ffffff'
          }
        }]
      },
      responsive: true,
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          // fontColor: "white",
          // fontSize: 18
      }
      },
    }
  };


  chartBorrow = {
    data: {
      labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
      datasets: [{
        label: 'Số hồ sơ đã mượn',
        data: [1, 4, 8, 2, 0],
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderColor: '#e05200',
        borderWidth: 2,
        fill: false
      },
      {
        label: 'Số hồ sơ chờ duyệt',
        data: [3, 2, 7, 3, 0],
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderColor: '#00606e',
        borderWidth: 2,
        fill: false
      }
      ],
      
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        labels: {
          render: 'value'
        }
      },
      barValueSpacing: 1,
      scales: {
        yAxes: [{
          ticks: {
            fontColor: 'rgba(0,0,0,.6)',
            fontStyle: 'bold',
            beginAtZero: true,
            maxTicksLimit: 8,
            padding: 10
          }
        }],
        xAxes: [{
          barPercentage: 0.4
        }]
      },
      responsive: true,
      legend: {
        position: 'bottom',
        display: false
      },
      annotation: {
        drawTime: 'beforeDatasetsDraw',
        annotations: [{
           type: 'box',
           id: 'a-box-1',
           yScaleID: 'y-axis-0',
           yMin: 0,
           yMax: 1,
           backgroundColor: '#4cf03b'
        }, {
           type: 'box',
           id: 'a-box-2',
           yScaleID: 'y-axis-0',
           yMin: 1,
           yMax: 2.7,
           backgroundColor: '#fefe32'
        }, {
           type: 'box',
           id: 'a-box-3',
           yScaleID: 'y-axis-0',
           yMin: 2.7,
           yMax: 5,
           backgroundColor: '#fe3232'
        }]
     }
    }
  };

  chartCategory = {
    data: {
      labels: ['Hồ sơ bảo mật', 'Hồ sơ công khai', 'Hồ sơ hạ tầng', 'Hồ sơ lưu vết','Hồ sơ quên lãng'],
      datasets: [
        {
          label: 'test',
          data: [
            12, 24, 5,9,30
          ],
          backgroundColor: ['#0074D9', '#2ECC40', '#FF4136','#e3eb00','#ab028c','#1ec9fc','#000000','#632d00', '#072b00', '#4508ff', '#ff829f']
        }
      ]
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        labels: {
          render: 'percentage',
          fontSize: 0,
          fontColor: ['#0074D9', '#2ECC40', '#FF4136','#e3eb00','#ab028c','#1ec9fc','#000000','#632d00', '#072b00', '#4508ff', '#ff829f'],
          precision: 2
        }
      },
      title: {
        display: false,
        text: 'Color test'
      },
      legend: {
        position: 'left',
        display: false,
        fullWidth: false,
        labels: {
          fontSize: 11
        }
      },
      scales: {
        xAxes: [{
          display: false,
        }],
        yAxes: [{
          display: false
        }]
      }
    }
  };

  chartWarehouse = {
    data: {
      labels: ['Hồ sơ bảo mật', 'Hồ sơ công khai', 'Hồ sơ hạ tầng', 'Hồ sơ lưu vết','Hồ sơ quên lãng'],
      datasets: [
        {
          label: 'test',
          data: [
            12, 24, 5,9,30
          ],
          backgroundColor: ['#0074D9', '#2ECC40', '#FF4136','#e3eb00','#ab028c','#1ec9fc','#000000','#632d00', '#072b00', '#4508ff', '#ff829f']
        }
      ]
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        labels: {
          render: 'percentage',
          fontSize: 0,
          fontColor: ['#0074D9', '#2ECC40', '#FF4136','#e3eb00','#ab028c','#1ec9fc','#000000','#632d00', '#072b00', '#4508ff', '#ff829f'],
          precision: 2
        }
      },
      title: {
        display: false,
        text: 'Color test'
      },
      legend: {
        position: 'left',
        display: false,
        fullWidth: false,
        labels: {
          fontSize: 11
        }
      },
      scales: {
        xAxes: [{
          display: false,
        }],
        yAxes: [{
          display: false
        }]
      }
    }
  };

  chartLogin = {
    plugins: {
      labels: {
        render: 'value'
      }
    },
    data: {
      labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
      datasets: [{
        label: 'Người dùng',
        data: [50, 80, 60, 120, 80],
        backgroundColor: '#002340',
        borderColor: '#002340',
        borderWidth: 2
      }
      ]
    },
    options: {
      plugins: {
        labels: {
          render: 'value'
        }
      },
      barValueSpacing: 1,
      scales: {
        yAxes: [{
          ticks: {
            fontColor: 'rgba(0,0,0,.6)',
            fontStyle: 'bold',
            beginAtZero: true,
            maxTicksLimit: 8,
            padding: 10
          }
        }],
        xAxes: [{
          barPercentage: 0.4
        }]
      },
      responsive: true,
      legend: {
        position: 'bottom',
        display: false
      },
    }
  };
  chartAccountPerArea = {
    data: {
      labels: ['Area 1', 'Area 2', 'Area 3'],
      datasets: [
        {
          label: 'test',
          data: [
            100, 200, 300
          ],
          backgroundColor: ['#0074D9', '#2ECC40', '#FF4136','#e3eb00','#ab028c','#1ec9fc','#000000','#632d00', '#072b00', '#4508ff', '#ff829f']
        }
      ]
    },
    options: {
      // maintainAspectRatio: false,
      tooltips: {
        enabled: true
      },
      title: {
        display: false,
        text: 'Color test'
      },
      legend: {
        position: 'left',
        display: false,
        fullWidth: true,
        labels: {
          fontSize: 11
        }
      },
      scales: {
        xAxes: [{
          display: false,
        }],
        yAxes: [{
          display: false
        }]
      },
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: 'white',
          precision: 2
        },
        datalabels: {
          formatter: (value, ctx) => {
            let sum = this.chartCategory.data[0].dataset._meta[0].total;
            let percentage = (value * 100 / sum).toFixed(2) + "%";
            return percentage;
    
    
          },
          color: '#fff',
        }
      }
    }
  };

  chartError = {
    data: {
      labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5'],
      datasets: [{
        label: 'Lỗi trong ngày',
        data: [10, 15, 8, 7, 5],
        backgroundColor: '#ffd182',
        borderColor: '#e05200',
        borderWidth: 2,
        fill: true,
      }
      ],
      
    },
    options: {
      barValueSpacing: 1,
      scales: {
        yAxes: [{
          ticks: {
            fontColor: 'rgba(0,0,0,.6)',
            fontStyle: 'bold',
            beginAtZero: true,
            maxTicksLimit: 8,
            padding: 10
          }
        }],
        xAxes: [{
          barPercentage: 0.4
        }]
      },
      responsive: true,
      legend: {
        position: 'bottom',
        display: false
      },
      annotation: {
        drawTime: 'beforeDatasetsDraw',
        annotations: [{
           type: 'box',
           id: 'a-box-1',
           yScaleID: 'y-axis-0',
           yMin: 0,
           yMax: 1,
           backgroundColor: '#4cf03b'
        }, {
           type: 'box',
           id: 'a-box-2',
           yScaleID: 'y-axis-0',
           yMin: 1,
           yMax: 2.7,
           backgroundColor: '#fefe32'
        }, {
           type: 'box',
           id: 'a-box-3',
           yScaleID: 'y-axis-0',
           yMin: 2.7,
           yMax: 5,
           backgroundColor: '#fe3232'
        }]
     }
    }
  };


  chart_create : any;
  chart_borrow : any;
  chart_category : any;
  chart_warehouse : any;
  chart_login: any;
  chart_Account_Per_Area: any;
  chart_error: any;

  datawebsiteViewsChart: any;
  optionswebsiteViewsChart: any;
  responsiveOptions: any;

  date: any;
  serializedDate: any;



  constructor(private adminApiService: AdminApiService, private notiService: NotificationService,private apiService: DanhMucApiService,
    private chatService: ChatService) {
      this.chartOptionsAdmin = {
        series: [
          {
            name: "Đã sử dụng",
            color:"#0097F9",
            data: [0]
          },
          {
            name: "Còn lại",
            color:"#DEDBDB",
            data: [400]
          }
        ],
        grid: {
          row: {
            colors: ['#fff']
          },
          column: {
            colors: ['#fff']
          }
        },
        chart: {
          type: "bar",
          height: '150' ,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
          
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 0,
          colors: ["#0097F9"]
        },
        // title: {
        //   text: "Biểu đồ dung lượng file",
        //   style: {
        //     fontSize:  '19px',
        //     fontFamily:  "Roboto",
        //     color: "#002d4d"
        //   }
        // },
        xaxis: {
          categories: ["Các khu vực"]
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " GB";
            }
          }
        },
        fill: {
          colors: ['#0097F9', '#DEDBDB']
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 10,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          floating: false,
          inverseOrder: false,
          onItemClick: {
            toggleDataSeries: false
          },
        }

      };
      this.chartOptions = {
        series: [
          {
            name: "Đã sử dụng",
            color:"#0097F9",
            data: [0]
          },
          {
            name: "Còn lại",
            color:"#DEDBDB",
            data: [400]
          }
        ],
        grid: {
          row: {
            colors: ['#fff']
          },
          column: {
            colors: ['#fff']
          }
        },
        chart: {
          type: "bar",
          height: 150,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 0,
          colors: ["#0097F9"]
        },
        xaxis: {
          categories: [JSON.parse(localStorage.getItem('organization')).name]
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val.toFixed(2) + " GB";
            }
          }
        },
        fill: {
          colors: ['#0097F9', '#DEDBDB']
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 10,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          floating: false,
          inverseOrder: false,
          onItemClick: {
            toggleDataSeries: false
        },
        }
      };
      this.chartOptionsFont = {
        series: [
          {
            name: "Lượt mượn",
            type: "column",
            data: [
              // 440, 505, 414, 671, 227, 413, 201,
              //  352, 752, 
              // 320, 257, 160
            ],       
          },
          {
            name: "Lượt trả",
            type: "line",
            data: [
              // 23, 42, 35, 27, 43, 22, 17, 
              // 31, 22, 
              // 22, 12, 16
            ]
          }
        ],
        chart: {
          height: 250,
          type: "line"
        },
        stroke: {
          width: [0, 4]
        },
        // title: {
        //   text: "Traffic Sources"
        // },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: [
          // "Hành chính",
          // "Nhân sự",
          // "Quyết định",
          // "Ngân sách",
          // "Đổi mới",
          // "Phong trào",
          // "Sự kiện",
          // "Ngày lễ",
          // "Kế hoạch",
          // "Font 10",
          // "Font 11",
          // "Font 12"
        ],
        xaxis: {
          type: "category"
        },
        yaxis: [
          {
            title: {
              text: "Lượt mượn",
              style:{
                fontFamily: 'Roboto'
              }

            }
          },
          {
            opposite: true,
            title: {
              text: "Lượt trả",
              style:{
                fontFamily: 'Roboto'
              }
            }
          }
        ]
      };
  }

  ngOnInit() {  


    var date = new Date();
    this.date = new Date(date.getFullYear(), date.getMonth(), 1);


    this.serializedDate = new Date();


    // this.FromTime = this.stringstartTime;
    // this.ToTime = this.stringendTime;

    this.chart_create= new Chart('chart-create', {
      type: 'bar',
      data: this.chartCreate.data,
      options: this.chartCreate.options
    });

    
    this.chart_borrow= new Chart('chart-borrow', {
      type: 'line',
      data: this.chartBorrow.data,
      options: this.chartBorrow.options
    });
    this.chart_category= new Chart('chart-category', {
      type: 'doughnut',
      data: this.chartCategory.data,
      options: this.chartCategory.options
    });

    this.chart_warehouse= new Chart('chart-warehouse', {
      type: 'doughnut',
      data: this.chartWarehouse.data,
      options: this.chartWarehouse.options
    });

    this.chart_login= new Chart('chart-login', {
      type: 'bar',
      data: this.chartLogin.data,
      options: this.chartLogin.options
    });

    
    this.chart_Account_Per_Area= new Chart('chart-account-per-area', {
      type: 'doughnut',
      data: this.chartAccountPerArea.data,
      options: this.chartAccountPerArea.options
    });
    this.chart_error= new Chart('chart-error', {
      type: 'line',
      data: this.chartError.data,
      options: this.chartError.options
    });
    
    this.username = localStorage.getItem('user_name');
    if (this.username === 'SystemAdmin') {
      document.getElementById('chart1').style.display = "none";
      document.getElementById('chart2').style.display = "none";
      document.getElementById('chart3').style.display = "inline-block";
      document.getElementById('chart4').style.display = "-webkit-box";


      // document.getElementById('chart1').style.display = "none";
      // document.getElementById('chart2').style.display = "none";
      // document.getElementById('chart3').style.display = "flex";
      // document.getElementById('chart4').style.display = "flex";
      // document.getElementById('chart5').style.display = "flex";
      // document.getElementById('chart6').style.display = "none";
      // document.getElementById('chart7').style.display = "flex";
      // document.getElementById('chart8').style.display = "none";
      this.getCountProfileForAllStatusAdminPage();
    }else{
      document.getElementById('chart1').style.display = "-webkit-box";
      document.getElementById('chart2').style.display = "-webkit-box";
      document.getElementById('chart3').style.display = "none";
      document.getElementById('chart4').style.display = "none";
      // document.getElementById('chart3').style.display = "none";
      // document.getElementById('chart4').style.display = "none";
      // document.getElementById('chart5').style.display = "none";
      // document.getElementById('chart6').style.display = "flex";
      // document.getElementById('chart7').style.display = "none";
      // document.getElementById('chart8').style.display = "flex";
      this.getCountProfileForAllStatus();
    }
    
    //this.getCountProfileForAllStatusAdminPage();
    // this.getAccountNumber();
  }

  getAccountNumber(){
    this.chatService.accountonline.subscribe(name =>{
      this.accountNumber = name;
    })

  }
  getCountProfileForAllStatus(){
    var id = JSON.parse(localStorage.getItem('organization')).id;
    this.chartCreate.data.labels = [];
    this.chartCreate.data.datasets[0].data = [];

    this.chartBorrow.data.labels = [];
    this.chartBorrow.data.datasets[0].data = [];
    this.chartBorrow.data.datasets[1].data = [];
    this.apiService.getCountProfileForAllStatusByOrg(id).pipe(first()).subscribe(data => {
      var result=JSON.parse(data.data);
      this.accountNumberOnline = result.userOnline;
      this.profileBorrowed = result.profileBorrowed;
      this.profileNormal = result.profileNormal;
      this.profilePending = result.profilePending;

      this.accountNumber = result.accountCount;
      this.fileCount = result.fileCount;
      this.profilePublish = result.profilePublish;



      this.chartCategory.data.labels = [];
      this.chartCategory.data.datasets[0].data = [];
      var dataUsed = 0;
      if(result.fileUsed != undefined){
        dataUsed = result.fileUsed;
      }
      this.chartOptions.series[0].data[0] = dataUsed;
      this.chartOptions.series[1].data[0] = result.dataMax - dataUsed;
      
      this.chartOptions.xaxis = {
        categories: this.chartOptions.xaxis.categories
      };


      this.chartOptionsFont.series[0].data = result.statisticsFontBorrowAnddPay.borrow;
      this.chartOptionsFont.series[1].data = result.statisticsFontBorrowAnddPay.pay;
      this.chartOptionsFont.xaxis = {
        categories: result.statisticsFontBorrowAnddPay.name
      };


      for(var i=0;i<result.dateAndProfile.length;i++){
        this.chartCreate.data.labels.push(result.dateAndProfile[i].date);
        if(result.dateAndProfile[i].count!= undefined){
          this.chartCreate.data.datasets[0].data.push(result.dateAndProfile[i].count);
        }else {
          this.chartCreate.data.datasets[0].data.push(0);
        }
        
      }

      for(var i=0;i<result.dateBorrowProfile.length;i++){
        this.chartBorrow.data.labels.push(result.dateBorrowProfile[i].date);

        if(result.dateBorrowProfile[i] != undefined){
          if(result.dateBorrowProfile[i].count!= undefined){
            this.chartBorrow.data.datasets[0].data.push(result.dateBorrowProfile[i].count);
          }else {
            this.chartBorrow.data.datasets[0].data.push(0);
          }
        }   
        
        if(result.datePendingProfile[i] != undefined){
          if(result.datePendingProfile[i].count!= undefined){
            this.chartBorrow.data.datasets[1].data.push(result.datePendingProfile[i].count);
          }else {
  
            this.chartBorrow.data.datasets[1].data.push(0);
          }
        }
        
        
      }

      for(var i=0;i<result.categoryPercent.length;i++){


        if(result.categoryPercent[i].amount!= undefined){
          this.chartCategory.data.labels.push(result.categoryPercent[i].name);
          this.chartCategory.data.datasets[0].backgroundColor.push(result.categoryPercent[i].color);
        }else this.chartCategory.data.labels.push("Hồ sơ NoName");
        if(result.categoryPercent[i].amount!= undefined){
          this.chartCategory.data.datasets[0].data.push(result.categoryPercent[i].amount)
        }else this.chartCategory.data.datasets[0].data.push(0);
      }

      for(var i=0;i<result.storePercent.length;i++){


        if(result.storePercent[i].amount!= undefined){
          this.chartWarehouse.data.labels.push(result.storePercent[i].name);
          this.chartWarehouse.data.datasets[0].backgroundColor.push(result.storePercent[i].color);
        }else this.chartWarehouse.data.labels.push("Hồ sơ NoName");
        if(result.storePercent[i].amount!= undefined){
          this.chartWarehouse.data.datasets[0].data.push(result.storePercent[i].amount)
        }else this.chartWarehouse.data.datasets[0].data.push(0);
  
      }

      // this.chart_create.update();
      // this.chart_borrow.update();
      this.chart_category.update();
      this.chart_warehouse.update();

      if(this.profileBorrowed == undefined){
        this.profileBorrowed="0";
      }
      if(this.profileNormal == undefined){
        this.profileNormal="0";
      }
      if(this.profilePending == undefined){
        this.profilePending="0";
      }

    },
      error => {

      });
  }
  getCountProfileForAllStatusAdminPage(){

    this.apiService.getCountProfileForAllStatus().pipe(first()).subscribe(data => {
      var result=JSON.parse(data.data);
      this.accountNumber = result.userOnline;
      this.chartOptionsAdmin.series[0].data = [];
      this.chartOptionsAdmin.series[1].data = [];


      this.chartOptionsAdmin.xaxis.categories = [];

      for(var i=0;i<result.listDataUsed.length;i++){
        this.chartOptionsAdmin.xaxis.categories[i] = result.listDataUsed[i].organizationName;
        var dataUsed = 0;
        if(result.listDataUsed[i].dataUsed != undefined){
          this.chartOptionsAdmin.series[0].data[i] = result.listDataUsed[i].dataUsed;
          dataUsed = result.listDataUsed[i].dataUsed;
        }else{
          this.chartOptionsAdmin.series[0].data[i] = 0;
        }
        if(result.listDataUsed[i].dataMax != undefined){
          this.chartOptionsAdmin.series[1].data[i]= result.listDataUsed[i].dataMax - dataUsed;
        }else{
          this.chartOptionsAdmin.series[1].data[i] = 0;
        }
        
      }

      this.chartOptionsAdmin.chart.height = (150 + (result.listDataUsed.length-1)*30).toString();

      this.chartOptionsAdmin.xaxis = {
        categories: this.chartOptionsAdmin.xaxis.categories
      };


      this.profileNormalAll = result.profileNormal;
      this.organizationCount = result.organizationCount;
      this.accountCount = result.accountCount;

      this.chartLogin.data.labels = [];
      this.chartLogin.data.datasets[0].data = [];

      this.chartAccountPerArea.data.labels = [];
      this.chartAccountPerArea.data.datasets[0].data = [];

      this.chartError.data.labels = [];
      this.chartError.data.datasets[0].data = [];


      if(result.historyLoginProfile!=undefined){
        for(var i=0;i<result.historyLoginProfile.length;i++){
          this.chartLogin.data.labels.push(result.historyLoginProfile[i].date);
          if(result.historyLoginProfile[i].count!= undefined){
            this.chartLogin.data.datasets[0].data.push(result.historyLoginProfile[i].count);
          }else this.chartLogin.data.datasets[0].data.push(0);
        }
      }
      
      if(result.accountPerArea!=undefined){
      for(var i=0;i<result.accountPerArea.length;i++){
        this.chartAccountPerArea.data.labels.push(result.accountPerArea[i].name);
        this.chartAccountPerArea.data.datasets[0].backgroundColor.push(result.accountPerArea[i].color);
        if(result.accountPerArea[i].amount!= undefined){
          this.chartAccountPerArea.data.datasets[0].data.push(result.accountPerArea[i].amount)
        }else this.chartAccountPerArea.data.datasets[0].data.push(0);
      }
    }
      if(result.erorLog!=undefined){
        for(var i=0;i<result.erorLog.length;i++){
          this.chartError.data.labels.push(result.erorLog[i].date);
          if(result.erorLog[i].count!= undefined){
            this.chartError.data.datasets[0].data.push(result.erorLog[i].count);
          }else {
            this.chartError.data.datasets[0].data.push(0);
          }
          
        }
      }
      

      this.chart_login.update();
      this.chart_Account_Per_Area.update();
      this.chart_error.update();

      if(this.profileBorrowedAll == undefined){
        this.profileBorrowedAll="0";
      }
      if(this.profileNormalAll == undefined){
        this.profileNormal="0";
      }
      if(this.profilePendingAll == undefined){
        this.profilePending="0";
      }
      if(this.organizationCount == undefined){
        this.organizationCount="0";
      }
      if(this.accountCount == undefined){
        this.accountCount="0";
      }

    },
      error => {

      });
  }


  refreshDataUsedOfAllOrganization(){
    this.isRefresh = true;

    this.apiService.getAllSizeUsedOrganization().pipe(first()).subscribe(result => {
      this.isRefresh = false;

      // console.log("data", data);
      // var result=JSON.parse(data.data);

      this.chartOptionsAdmin.series[0].data = [];
      this.chartOptionsAdmin.series[1].data = [];


      this.chartOptionsAdmin.xaxis.categories = [];

      for(var i=0;i<result.length;i++){
        this.chartOptionsAdmin.xaxis.categories[i] = result[i].organizationName;
        var dataUsed = 0;
        if(result[i].dataUsed != undefined){
          this.chartOptionsAdmin.series[0].data[i] = result[i].dataUsed;
          dataUsed = result[i].dataUsed;
        }else{
          this.chartOptionsAdmin.series[0].data[i] = 0;
        }
        if(result[i].dataMax != undefined){
          this.chartOptionsAdmin.series[1].data[i]= result[i].dataMax - dataUsed;
        }else{
          this.chartOptionsAdmin.series[1].data[i] = 0;
        }
        
      }

      this.chartOptionsAdmin.chart.height = (150 + (result.length-1)*30).toString();

      this.chartOptionsAdmin.xaxis = {
        categories: this.chartOptionsAdmin.xaxis.categories
      };


    },
      error => {
        this.isRefresh = false;
      });
  }

  refreshDataUsedOfOrganization(){
    this.isRefresh = true;
    this.apiService.getSizeUsedOrganization().pipe(first()).subscribe(result => {
      this.isRefresh = false;

      this.chartOptions.series[0].data[0] = result.dataUsed;
      this.chartOptions.series[1].data[0] = result.dataMax - result.dataUsed;
      
      this.chartOptions.xaxis = {
        categories: this.chartOptions.xaxis.categories
      };


    },
      error => {
        this.isRefresh = false;
      });
  }

  refreshDataFontAndFontMenu(){
    this.isRefreshFont = true;
    var datePipe = new DatePipe("en-US");
    var stringstartTime = datePipe.transform(this.date, 'dd/MM/yyyy');
    var stringendTime = datePipe.transform(this.serializedDate, 'dd/MM/yyyy');
    this.apiService.getDataFontAndFontMenu(stringstartTime, stringendTime).pipe(first()).subscribe(result => {
      this.isRefreshFont = false;

      this.chartOptionsFont.series[0].data = result.borrow;
      this.chartOptionsFont.series[1].data = result.pay;
      this.chartOptionsFont.xaxis = {
        categories: result.name
      };


    },
      error => {
        this.isRefreshFont = false;
      });
  }

  


  public chartClicked(e:any):void {

  }

  public chartHovered(e:any):void {

  }
  public hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
  startAnimationForLineChart(chart){
    let seq: any, delays: any, durations: any;
    seq = 0;
    delays = 80;
    durations = 500;

    chart.on('draw', function(data) {
      if(data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            // easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if(data.type === 'point') {
            seq++;
            data.element.animate({
              opacity: {
                begin: seq * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            });
        }
    });

    seq = 0;
  };
  startAnimationForBarChart(chart){
    let seq2: any, delays2: any, durations2: any;

    seq2 = 0;
    delays2 = 80;
    durations2 = 500;
    chart.on('draw', function(data) {
      if(data.type === 'bar'){
          seq2++;
          data.element.animate({
            opacity: {
              begin: seq2 * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: 'ease'
            }
          });
      }
    });

    seq2 = 0;
  };

}
