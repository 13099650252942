export const environment = {
  production: true,
  apiUrlVnptResource: window.location.origin + '/api',
  apiUrlVnptResourceIP: window.location.origin,
  contextPath: 'vnpt-portal',
  chatUrl: window.location.origin + '/vnpt/Approval/ApprovalManagement/SendNotification',
  option: 'minio'
};

export const oauth2 = {
  redirectUri: window.location.origin + '/',
  getToken: window.location.origin + '/api/account/login',
  captchaSiteKey: "6LfoUUYaAAAAAJdB78eTm0Vlgh3P0_KhE9zhs5x1",
  urlViewDomain:"https://digitalization.vnptit.vn/"
};


export const minioStorage = {
  endPoint: "storage-edig.vnpt.vn",
  accessKey: "it2@edig0bjectstorage",
  secretKey: "it2@edig0bjectstorage@2020",
  region: "IDC-NTL"
};
export const ProductInfo = {
  sVersion:"${SCM_MODULE_VERSION}",
  sEnviroment: "${SCM_ENVIROMENT_VERSION}"
}
export const VCMNotification = {
  centrifugeJWT: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ2dWUtc2RrIn0.rqqFBcGquJJL68qrdEIFHbVv9SIjSLCfcnafNGx1T0M",
  centrifugoConnectionURL:"wss://digitalization.vnptit.vn/connection/websocket"
}