import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function IDValidator(): ValidatorFn {
    let reg = /([0-9])/

    return (control: AbstractControl): ValidationErrors | null => {
        const forbidden = reg.test(control.value);

        if (isNaN(control.value)) {
            return { IDNumber: { value: control.value } };
        }

        if (forbidden) {
            if (control.value.length == 9 || control.value.length == 12)
                return null;

            else return { IDNumber: { value: control.value } };
        }
        else {
            return { IDNumber: { value: control.value } };
        }
    };
}
