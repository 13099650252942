import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.css']
})
export class UploadFileDialogComponent implements OnInit {

  fileData: any;
  fileName = '';
  constructor() { }

  ngOnInit() {
  }

  fileProgress(fileInput: any){
    this.fileName = fileInput.target.files[0].name;
    this.fileData = <File>fileInput.target.files[0];
  }

}
