import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RequestOptions } from '@angular/http';

@Injectable({ providedIn: 'root' })
export class DanhMucApiService {
  constructor(private http: HttpClient) {

  }
  getDanhmucListAllByListStorePermissionGroup2(organizationId: any, parentId: number, permissionGroupId:any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/getalllevelbypermission?token=${token}&organization=${organizationId}&parentId=${parentId}&permissionId=${permissionGroupId}`);
    // return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/getalllevelbydepartment2?token=${token}&organization=${organizationId}&parentId=${parentId}&permissionId=${permissionGroupId}`);
  }
  getDanhmucListAllByListStorePermissionGroup2AnGiang(organizationId: any, parentId: number, permissionGroupId:any) {
    const token = localStorage.getItem('access_token');
    // return this.http.get<any>(`${environment.apiUrlVnptResource}/store/getalllevelbypermission?token=${token}&organization=${organizationId}&parentId=${parentId}&permissionId=${permissionGroupId}`);
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/getalllevelbydepartment2?token=${token}&organization=${organizationId}&parentId=${parentId}&permissionId=${permissionGroupId}`);
  }
  getListStoreEnableByOrg(level: number, parentId: number) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/getStoreEnableByOrgWithDepartmentId?level=${level}&parentid=${parentId}`);
  }
  getStatisticsDataEntryPerson(fromTime: string, toTime: string) {
    const token = localStorage.getItem('access_token');
    const organizationId = JSON.parse(localStorage.getItem('organization')).id;
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/statisticsDataEntryPerson?token=${token}&organizationId=${organizationId}&fromTime=${fromTime}&toTime=${toTime}`);
  }
  ImportDocumentZip(formData:FormData){
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/importDocument`, formData)
  }
  copyCategory(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/category/copy?token=${token}&categoryId=${body}`, {});
  }
  ocrMultiplePage(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/autodetectmultiple?token=${token}`, body);
  }
  getDictItemsCsdlCongThuong(codeName: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionary/typeDocumentCongThuong/${codeName}?token=${token}`);
  }
  createFontStorage(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/fontstorage/create?token=${token}`, body);
  }
  updateFontStorage(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/fontstorage/update?token=${token}`, body);
  }
  deleteFontStorage(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/fontstorage/delete?token=${token}`, body);
  }
  getListFontStorageByOrganization(id:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/fontstorage/getbyorganizationid?token=${token}&idOrganization=${id}`);
  }
  getListFontStorageAndMenuByOrganization(id:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/fontstoragemenu/getfontandfontmenubyorg?token=${token}&idOrganization=${id}`);
  }
  getListFontStorageMenuByFont(id:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/fontstoragemenu/getbyfont?token=${token}&fontStorageId=${id}`);
  }

  createFontMenu(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/fontstoragemenu/create?token=${token}`, body);
  }

  updateFontMenu(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/fontstoragemenu/update?token=${token}`, body);
  }

  deleteFontMenu(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/fontstoragemenu/delete?token=${token}`, body);
  }


  getFontStorageById(id:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/fontstorage/getbyid?token=${token}&id=${id}`);  
  }
  getReportProfileLiquidation(organizationId: any, dateStart:any, dateEnd:any, option: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/report/profileliquidation?token=${token}&organizationId=${organizationId}&startDate=${dateStart}&endDate=${dateEnd}&option=${option}`);
  }
  getReportDocumentOfRegistrationForm(organizationId: any, dateStart:any, dateEnd:any, option: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/report/baocaomuontailieu?token=${token}&organizationId=${organizationId}&startDate=${dateStart}&endDate=${dateEnd}&option=${option}`);
  }
  getReportListStore(organizationId:any, option:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/report/liststore?token=${token}&organizationId=${organizationId}&option=${option}`);
  }
  getReportProfileByStatus(organizationId:any, status:any, dateStart:any, dateEnd:any, option:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/report/listprofilebystatus?token=${token}&organizationId=${organizationId}&startDate=${dateStart}&endDate=${dateEnd}&option=${option}&status=${status}`);
  }
  getReportListProfileUnApproval(organizationId:any, dateStart:any, dateEnd:any, option:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/report/listprofileunapproval?token=${token}&organizationId=${organizationId}&startDate=${dateStart}&endDate=${dateEnd}&option=${option}`);
  }
  getReportListCategory(organizationId:any, option:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/report/listcategory?token=${token}&organizationId=${organizationId}&option=${option}`);
  }
  getReportGroupByBorrowAccount(organizationId:any, dateStart:any, dateEnd:any, option:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/report/listprofile?token=${token}&organizationId=${organizationId}&startDate=${dateStart}&endDate=${dateEnd}&option=${option}`);
  }
  getReportBaoCaoMucLuc(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/report/exportfontmenu?token=${token}`, body);
  }
  getReportBaoCaoChiTietHoSo(body:any,option:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/report/baocaochitiethoso?token=${token}&option=${option}`, body);
  }

  getListLiquidationForms(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/liquidationform/list?token=${token}&organizationId=${body}`);
  }
  createLiquidationForm(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/liquidationform/create?token=${token}`, body);
  }
  updateLiquidationForm(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/liquidationform/update?token=${token}`, body);
  }
  deleteLiquidationForm(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/liquidationform/delete?token=${token}`, body);
  }
  getListProfileLiquidationForm(organizationId:any, fromDate :any, toDate :any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getlistnearlyliquidatedprofiles?token=${token}&organizationId=${organizationId}&fromDate=${fromDate}&toDate=${toDate}`);
  }

  searchProfileLiquidation(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/getListProfileLiquidation`, body);
  }

  getLiquidationFormById(id: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/liquidationform/byid?token=${token}&liquidationFormId=${id}`);
  }
  syncLiquidationForms(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/liquidationform/synchronizeliquidationformswithoutkafka?token=${token}`, body);
  }

  checklink(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/image/checklink?token=${token}&link=${body}`);
  }
  getDocumentbylistProfile(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/getbylistprofile?token=${token}`, body);
  }

  getDanhmucList(level: number, parentId: number) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/treelistwithlevel?token=${token}&level=${level}&parentid=${parentId}`);
  }
  getDanhmucListByListStore(level: number, parentId: number, permissionGroupId:any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/treelistwithlevelbypermission?token=${token}&level=${level}&parentId=${parentId}&permisisonGroupId=${permissionGroupId}`);
  }
  getDanhmucListByListStoreAnGiang(level: number, parentId: number, permissionGroupId:any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/treelistwithlevelbydepartment?token=${token}&level=${level}&parentId=${parentId}&permisisonGroupId=${permissionGroupId}`);
  }
  getDanhmucListbyOrg(organizationId: any, level: number, parentId: number) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/getStoreByOrg?token=${token}&level=${level}&parentid=${parentId}&organizationId=${organizationId}`);
  }

  getDanhmucListEnablebyOrg(organizationId: any, level: number, parentId: number) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/getStoreEnableByOrg?token=${token}&level=${level}&parentid=${parentId}&organizationId=${organizationId}`);
  }

  getDanhmucListAll(organizationId: any, parentId: number) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/getalllevel?token=${token}&parentid=${parentId}&organizationId=${organizationId}`);
  }

  getDanhmucListAllByListStorePermissionGroup(organizationId: any, parentId: number, permissionGroupId:any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/getalllevelbypermission?token=${token}&organization=${organizationId}&parentId=${parentId}&permissionId=${permissionGroupId}`);
  }
  getDanhmucListAllByListStorePermissionGroupAnGiang(organizationId: any, parentId: number, permissionGroupId:any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/getalllevelbydepartment?token=${token}&organization=${organizationId}&parentId=${parentId}&permissionId=${permissionGroupId}`);
  }

  getListDanhMuc() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/`);
  }
  createStore(body: any) {
    //console.log(`${environment.apiUrlVnptResource}/store/create`);
    return this.http.post<any>(`${environment.apiUrlVnptResource}/store/create`, body);
  }
  createStoreAnGiang(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/store/createwithdepartment`, body);
  }

  updateStore(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/store/update`, body);
  }
  updateStoreAnGiang(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/store/updatewithdepartment`, body);
  }

  deleteStore(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/store/delete`, body);
  }
  getStoreByOrganizationAccount(parentId:any, level:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/listByOrganizationAccount?token=${token}&parentId=${parentId}&level=${level}`);
  }
  getLoaihoSoListAll() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/category/list?token=${token}`);
  }

  getLoaihoSoListPermissionGroupId(permissionGroupId:any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/category/listpermission?token=${token}&permissionGroupId=${permissionGroupId}`);
  }
  getLoaihoSoListPermissionGroupIdAnGiang(permissionGroupId:any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/category/listbydepartment?token=${token}&permissionGroupId=${permissionGroupId}`);
  }
  getLoaihoSoList() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/category/list?token=${token}`);
  }
  getListCategoryByOrganization(organizationId: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/category/listbyorganization?token=${token}&organizationId=${organizationId}`);
  }
  // getListCategory() {
  //   const token = localStorage.getItem('access_token');
  //   return this.http.get<any>(`${environment.apiUrlVnptResource}/category/list?token=${token}`);
  // }

  createCategory(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/category/create`, body);
  }
  createCategoryAnGiang(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/category/createWithDepartment`, body);
  }

  updateCategory(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/category/update`, body);
  }
  updateCategoryAnGiang(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/category/updateWithDepartment`, body);
  }

  deleteCategory(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/category/delete`, body);
  }

  getDepartmentList() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/department/listbytoken?token=${token}`);
  }
  getDepartmentListByOrganizationId(organizationId){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/department/listbyorganizationid?token=${token}&organizationId=${organizationId}`);
  }

  createDepartment(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/department/create`, body);
  }

  updateDepartment(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/department/update`, body);
  }

  deleteDepartment(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/department/delete`, body);
  }

  searchDanhMuc(level: number, name: string) {
    const token = localStorage.getItem('access_token');
    //console.log(token);
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/searchItemByName?token=${token}&level=${level}&name=${name}`);
  }
  searchDanhMucAnGiang(level: number, name: string) {
    const token = localStorage.getItem('access_token');
    //console.log(token);
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/searchItemByNameWithDepartment2?token=${token}&level=${level}&name=${name}`);
  }

  searchDanhMucLimit(pageStart:number, pageEnd:number, pageSize: number,level: number, name: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/storepagingwithlevel?token=${token}&level=${level}&pageStart=${pageStart}&pageEnd=${pageEnd}&pageSize=${pageSize}&name=${name}`);
  }

  searchDanhMucByParent(level: number, name: string, parentId: string) {
    const token = localStorage.getItem('access_token');
    //console.log(token);
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/searchItemByNameParent?token=${token}&level=${level}&name=${name}&parentid=${parentId}`);
  }
  searchDanhMucByParentAnGiang(level: number, name: string, parentId: string) {
    const token = localStorage.getItem('access_token');
    //console.log(token);
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/searchItemByNameParentWithDepartment2?token=${token}&level=${level}&name=${name}&parentid=${parentId}`);
  }
  searchDanhMucByGrandParent(level: number, name: string, grandparentId: string) {
    const token = localStorage.getItem('access_token');
    //console.log(token);
    //console.log(`${environment.apiUrlVnptResource}/store/searchfromgrandparent?token=${token}&level=${level}&name=${name}&grandparentid=${grandparentId}`);
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/searchfromgrandparent?token=${token}&level=${level}&name=${name}&grandparentid=${grandparentId}`);
  }
  searchDanhMucByGrandParentAnGiang(level: number, name: string, grandparentId: string) {
    const token = localStorage.getItem('access_token');
    //console.log(token);
    //console.log(`${environment.apiUrlVnptResource}/store/searchfromgrandparent?token=${token}&level=${level}&name=${name}&grandparentid=${grandparentId}`);
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/searchfromgrandparentwithdepartment2?token=${token}&level=${level}&name=${name}&grandparentid=${grandparentId}`);
  }
  createChild(body: any) {
    const token = localStorage.getItem('access_token');

    return this.http.post<any>(`${environment.apiUrlVnptResource}/store/createchild?token=${token}`, body);
  }
  createChildAnGiang(body: any) {
    const token = localStorage.getItem('access_token');

    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/store/createchild?token=${token}`, body);
  }
  getData(body: any, hoSoStyle, hopHoSo, orgId, workSpaceId, accountId) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/getData?token=${token}`, { model: body, hoSoStyle: hoSoStyle, hopHoSo: hopHoSo, orgId: orgId, workSpaceId: workSpaceId, accountId: accountId });
  }

  importDataToProfile(profile) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profiletemplate/createdocuments?token=${token}`, { model: profile });
  }

  getListByStatus() {
    const token = localStorage.getItem('access_token');

    const organizationId = JSON.parse(localStorage.getItem('organization')).id;
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/listfileimport?status=3&token=${token}&organizationId=${organizationId}`);
  }
  getListByStatuss(status: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/listbystatus?status=${status}&token=${token}`);
  }
  getListprofilenotshare(status: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/listprofilenotshare?status=${status}&token=${token}`);
  }
  updateFile(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/update?token=${token}`, body);
  }
  getCountProfileForAllStatus() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getcountforallstatus?token=${token}&organizationId=`);
  }
  getAllSizeUsedOrganization() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getAllSizeUsedOrganization?token=${token}`);
  }
  getSizeUsedOrganization() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getSizeUsedOrganization?token=${token}`);
  }

  getDataFontAndFontMenu(dateStart: any, dateEnd: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/statisticsFontBorrowAnddPay?token=${token}&dateStart=${dateStart}&dateEnd=${dateEnd}`);
  }

  getCountProfileForAllStatusByOrg(id: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getcountforallstatus?token=${token}&organizationId=${id}`);
  }
  changeStore(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/changestore?token=${token}`, body);
  }
  getDictionary_Definition() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionarydefinition/list?token=${token}`);
  }
  getDictionary_DefinitionByOrg(id: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionarydefinition/listbyorganization?token=${token}&organizationId=${id}`);
  }
  getDictionaryPublicAndPrivateByOrg(id: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionary/listdictionarypublicandprivate?token=${token}&organizationId=${id}`);
  }

  createDictionary_Definition(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/dictionarydefinition/create?token=${token}`, body);
  }
  updateDictionary_Definition(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/dictionarydefinition/update?token=${token}`, body);
  }
  deleteDictionary_Definition(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/dictionarydefinition/delete?token=${token}`, body);
  }
  getDictionary() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionary/list?token=${token}`);
  }
  getDictionaryByIdDenfinication(id: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionary/listbyiddefinition?token=${token}&id=${id}`);
  }
  createDictionary(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/dictionary/create?token=${token}`, body);
  }
  updateDictionary(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/dictionary/update?token=${token}`, body);
  }
  deleteDictionary(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/dictionary/delete?token=${token}`, body);
  }
  getProfileTemplateByCategoryId(categoryId: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profiletemplate/bycategoryid?token=${token}&categoryid=${categoryId}`);
  }

  createProfileTemplateAttributes(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profiletemplate/upsert?token=${token}`, body);
  }

  getDocumentTemplateByCategoryId(categoryId: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/documenttemplate/bycategoryid?token=${token}&categoryid=${categoryId}`);
  }

  createDocumentTemplate(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/documenttemplate/upsert?token=${token}`, body);
  }
  deleteDocumentTemplate(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/documenttemplate/delete?token=${token}`, body);
  }

  getListProfileLog() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profilelog/list?token=${token}`);
  }
  getListProfileLogByTime(start: string, end: string, id: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profilelog/listbytime?token=${token}&start=${start}&end=${end}&id=${id}`);
  }
  getListDocument(profileid: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/document/list?token=${token}&profileid=${profileid}`);
  }

  getListProfile() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/list?token=${token}`);
  }

  getParameterByOrganization() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/parameter/listbytoken?token=${token}`);
  }
  signDocument(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/Attachment/sign?token=${token}`, body);
  }
  convertPdfToImage(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/convertpdftoimage?token=${token}`, body);
  }
  ocrImage(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/submit?token=${token}`, body);
  }
  ocrName(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/ocrNameCard?token=${token}`, body);
  }
  ocrTable(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/submitTable?token=${token}`, body);
    // return this.http.post<any>(`http://10.70.123.3:5000/extract_table`,body);

  }
  ocrToKhaiYTe(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/ocrmedicalform?token=${token}`, body);
  }
  spellCheck(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/spellcheck?token=${token}`, body);
  }
  handWritting(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/handwritting?token=${token}`, body);
  }
  addProfile(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/create?token=${token}`, body);
  }
  importFolder(body: FormData) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/identityCard/import?token=${token}`, body).toPromise();
  }
  updateProfile(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/update?token=${token}`, body);
  }
  updateProfile468(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/update468?token=${token}`, body);
  }
  profileAssignment(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/profileAssignment?token=${token}`, body);
  }
  profileAssignmentAndSave(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/profileAssignmentAndSave?token=${token}`, body);
  }
  updateImport(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/identityCard/updateimport?token=${token}`, body);
  }
  createDanhMucTinhThanhPho(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DanhMucTinhThanhPho/create`, body);
  }
  createListDanhMucTinhThanhPho(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DanhMucTinhThanhPho/listData`, body);
  }
  getListDanhMucTinhThanhPho() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/DanhMucTinhThanhPho/list`);
  }
  getReportDefinition() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/ReportDefinition/listall?token=${token}`);
  }

  createReportDefinition(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ReportDefinition/create?token=${token}`, body);
  }
  editReportDefinition(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ReportDefinition/update?token=${token}`, body);
  }
  deleteReportDefinition(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ReportDefinition/delete?token=${token}`, body);
  }
  createLoaiVanBan(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/LoaiVanBan/create`, body);
  }
  editLoaiVanBan(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/LoaiVanBan/update`, body);
  }
  deleteLoaiVanBan(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/LoaiVanBan/delete`, body);
  }
  getListLoaiVanBan(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/LoaiVanBan/listall`);
  }
  getListLoaiVanBanByOrganization(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/LoaiVanBan/listorgani/${body}`);
  }
  getListLoaiGiayTo(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionary/listTypeDocument`);
  }
  getReportPDF(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/report/pdf?token=${token}`, body);
  }
  getListLoaiVanBanById(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/LoaiVanBan/${body}?token=${token}`);
  }

  ocrMultiple(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/autodetect?token=${token}`, body);
  }

  ocrMultiplev2(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/autodetectmultiple?token=${token}`, body);
  }

  c(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/Attachment/importfile?token=${token}`, body);
  }

  deleteDocument(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/delete?token=${token}`, body);
  }
  deleteDocumentTree(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/deletetree?token=${token}`, body);
  }
  getListDocumentAttachmentByProfileId(body: any){
    const token = localStorage.getItem('access_token');

    var organizationId =JSON.parse(localStorage.getItem('organization')).id;

    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getlistdocumentandattachment?token=${token}&idProfile=${body}&organizationId=${organizationId}`);
  }
  getListCategoryDictionary(){
    const token = localStorage.getItem('access_token');
    let idOrganization = JSON.parse(localStorage.getItem("organization")).id;
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionary/getCategoryDictionaryDictionaryDefinition?token=${token}&id=${idOrganization}`);
  }
  getListReportQuery() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/reportQuery/getList?token=${token}`);
  }

  searchRegistrationformLimit(idStatus:number,pageStart:number, pageEnd:number, pageSize: number, startDate: string, endDate: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/registrationform/pagingwithlevel?token=${token}&pageStart=${pageStart}&idStatus=${idStatus}&pageEnd=${pageEnd}&startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&name=`);
  }

  searchRegistrationPaperformLimit(idStatus:number,pageStart:number, pageEnd:number, pageSize: number, startDate: string, endDate: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/paperregistrationform/listapprovalregistrationform?token=${token}&pageStart=${pageStart}&idStatus=${idStatus}&pageEnd=${pageEnd}&startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&name=`);
  }

  ocrNameCard(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/ocrNameCard?token=${token}`, body);
  }

  ocrBHYT(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/ocrHealthInsurance?token=${token}`, body);
  }
  ocrHoaDon(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/ocrBill?token=${token}`, body);
  }
  ocrDetectLayoutImage(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/detectlayout?token=${token}`, body);
  }
  getFontstorageMenuByOrganization(organizationId:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/fontstoragemenu/getbyorganizationid?token=${token}&idOrganization=${organizationId}`);
  }

  createNewComponent(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/dictionary/createNewComponent?token=${token}`, body);
  }

  getAllData(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/collection/getMultiple?token=${token}`, body);
  }
  getListOCRFunction(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/ocrfunction/list?token=${token}`);
  }

  createOCRFunction(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocrfunction?token=${token}`,body);
  }

  updateOCRFunction(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.put<any>(`${environment.apiUrlVnptResource}/ocrfunction?token=${token}`,body);
  }

  deleteOCRFunction(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocrfunction/delete?token=${token}`,body);
  }

  getReportProfileDetail(option, body){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/report/baocaochitiethoso1?option=${option}&token=${token}`,body);
  }
}
