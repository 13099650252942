import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { LoginService } from './login.service';
import { NotificationService } from '../services/notification.service';
import { ConfirmationService } from 'primeng/api';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public loginService: LoginService, private notiService: NotificationService, 
    private confirmationService: ConfirmationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.loginService !== undefined && this.loginService.getToken()) {
      request = this.addToken(request, this.loginService.getToken());
      if (request.method === 'POST') {
        request = this.processPostRequest(request);
      }
    }

    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && error.status === 400) {
            if (error.error.errors[0] === 'Account-Invalid' || error.error.errors[0] === 'Token-Invalid') {
              this.confirmLogOut();
            }
          }
          return throwError(error);
        }
      ));
  }

  private confirmLogOut() {
    this.confirmationService.confirm({
      message: 'Phiên làm việc của bạn đã hết, bạn có muốn đăng xuất ?',
      header: 'Thông báo',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Có',
      rejectLabel: 'Không',
      rejectVisible: true,
      accept: () => {
        this.loginService.logout();
      },
      reject: () => {
      }
    });
  }

  private processPostRequest(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'Content-Type': `application/json`
      }
    });
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'token': `${token}`,
      }
    });
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);

  //     return this.authService.refreshToken().pipe(
  //       switchMap((token: any) => {
  //         this.isRefreshing = false;
  //         this.refreshTokenSubject.next(token.jwt);
  //         return next.handle(this.addToken(request, token.jwt));
  //       }));

  //   } else {
  //     return this.refreshTokenSubject.pipe(
  //       filter(token => token != null),
  //       take(1),
  //       switchMap(jwt => {
  //         return next.handle(this.addToken(request, jwt));
  //       }));
  //   }
  // }
}
