import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DataService } from '../services/data.service';
//primeng
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TreeTableModule } from 'primeng/treetable';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService } from 'primeng/api';
// import { MultiSelectModule, ConfirmationService, MenuModule } from 'primeng/api';
import { FieldsetModule } from 'primeng/fieldset';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { StepsModule } from 'primeng/steps';
import { TreeModule } from 'primeng/tree';
import { DataViewModule } from 'primeng/dataview';
import { RatingModule } from 'primeng/rating';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PipeModule } from 'app/helpers/pipe.module';
import { SanitizeHtmlPipe } from '../services/pipe/santinize-html-pipe.service';
import {MatSelectModule} from '@angular/material/select';
@NgModule({
  imports: [
    MatSelectModule,
    MatFormFieldModule,
    MatRippleModule,
    MatInputModule,
    MatTabsModule,
    MatButtonModule,
    TableModule,
    PaginatorModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    TabViewModule,
    DialogModule,
    SplitButtonModule,
    ScrollPanelModule,
    PanelMenuModule,
    TreeTableModule,
    InputSwitchModule,
    ConfirmDialogModule,
    CheckboxModule,
    FieldsetModule,
    PanelModule,
    TooltipModule,
    RadioButtonModule,
    FileUploadModule,
    StepsModule,
    TreeModule,
    DataViewModule,
    RatingModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    ScrollingModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    PipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ProgressBarComponent,
    // SanitizeHtmlPipe
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ProgressBarComponent
  ],
  providers: [
    ConfirmationService
  ]
})
export class ComponentsModule {
  constructor(
    public traslate: TranslateService, private dataService: DataService
  ) {
    this.traslate.addLangs(['vi', 'en']);
    this.traslate.setDefaultLang('vi');
    // this.traslate.use('vi');
    this.dataService.currentMessage.subscribe(message => this.traslate.use(message));
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
