import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { constantsValue } from '../const';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  
  private rowNavMessage = new BehaviorSubject(constantsValue.rowNav.homeRowNav);
  currentRowNavMessageMessage = this.rowNavMessage.asObservable();

  private footerMessage = new BehaviorSubject('footer');
  currentFooterMessage = this.footerMessage.asObservable();

  private colNavMessage = new BehaviorSubject(constantsValue.colNav.homeTermPrivateColNav);
  currentColNavMessageMessage = this.colNavMessage.asObservable();

  private loginMessage = new BehaviorSubject(false);
  currentLoginMessageMessage = this.loginMessage.asObservable();

  private cartItem = new BehaviorSubject(localStorage.getItem('cart_item') === null ? [] : JSON.parse(localStorage.getItem('cart_item')));
  currentCartItem = this.cartItem.asObservable();

  constructor() { }

  changeFooterMessage(message: string) {
    this.footerMessage.next(message);
  }

  changeRowNavMessage(message: string) {
    this.rowNavMessage.next(message);
  }

  changeColNavMessage(message: string) {
    this.colNavMessage.next(message);
  }

  changeLoginMessage(message: boolean) {
    this.loginMessage.next(message);
  }

  changeCartItem(message: any) {
    this.cartItem.next(message);
  }

}
