import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class HosoApiService {
  constructor(private http: HttpClient) {

  }
  getLinkUploadFileSignVNPTCAPlugin(body:any){
    return this.http.post<any>(`${environment.apiUrlVnptResource}/Attachment/signVNPTPlugin`, body);
  }
  genListLinkView(body:any){
    return this.http.post<any>(`${environment.apiUrlVnptResource}/image/getlisturlview`, body);
  }
  getListAttachmentByListProfileId(body:any){
    return this.http.post<any>(`${environment.apiUrlVnptResource}/Attachment/listbylistprofileid`, body);
  }
  signVNPTCAMultipleBulk(body:any){
    return this.http.post<any>(`${environment.apiUrlVnptResource}/workspace/signMultipleVNPTCAPdf`, body);
  }
  signSmartCAMultipleBulk(body:any){
    return this.http.post<any>(`${environment.apiUrlVnptResource}/workspace/signMultipleSmartCAPdfAuto`, body);
  }
  signSmartCABulk(body:any){
    return this.http.post<any>(`${environment.apiUrlVnptResource}/workspace/signSmartCAPdfAuto`, body);
  }
  searchSuggestProfile(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/suggestprofile`, body);
  }
  searchSuggestFulltext(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/suggestfulltext`, body);
  }
  GetListLiquidationTime() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionary/listexpiretimebyorg?token=${token}`);
  }
  GetAnGiangListLiquidationTime() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/dictionary/listexpiretimebyorg?token=${token}`);
  }
  GetListDensity(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/dictionary/listDensity?token=${token}`);
  }

  downloadimage(link: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/image/downImage?token=${token}&link=${link}`);
  }
  downloadImagePublic(link: any) {
    return this.http.get<any>(`${environment.apiUrlVnptResource}/image/downloadImagePageForum?link=${link}`);
  }

  ocafulltext(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/orcfulltext?token=${token}`, body);
  }

  searchProfile(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/byconditionwithanystatus`, body);
  }
  searchProfileAnGiang(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/search/byconditionwithanystatus`, body);
  }

  searchProfileToChangeStore(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/byconditionforchangestore`, body);
  }
  searchProfileToChangeStorePermissionGroupId(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/byconditionforchangestorepermission`, body);
  }

  searchPublish(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/bycondition`, body);
  }

  searchPublishPermissionGroupId(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/byconditionpermission`, body);
  }

  searchProfileMultiStatus(body: any) {

    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/byconditionmultistatus`, body);
  }
  searchProfileMultiStatusForSearch(body: any) {

    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/byconditionmultistatusForSearch`, body);
  }
  searchProfileMultiStatusAndFilter(body: any) {
    // if (body.ValueSearch == undefined)
    //   body.ValueSearch = "";

    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/byConditionMultiStatusAndFilter`, body);
  }
  searchProfileMultiStatusAndFilterAnGiang(body: any) {
    // if (body.ValueSearch == undefined)
    //   body.ValueSearch = "";

    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/search/byConditionMultiStatusAndFilter`, body);
  }
  searchProfileMultiStatusAndFilterForSearch(body: any) {
    // if (body.ValueSearch == undefined)
    //   body.ValueSearch = "";

    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/byConditionMultiStatusAndFilterForSearch`, body);
  }

  searchProfileAttachmentMultiStatus(body: any) {

    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/allprofileandattachment`, body);
  }

  searchAttachmentMultiStatus(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/listattachmentwithmultistatus`, body);
  }
  getStoreAndCategory(organization: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getliststoreandcategory?token=${token}&organizationId=${organization}`);
  }
  getStoreAndCategoryPermissionGroup(organization: string, permissionGroupId: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getliststoreandcategorypermission?token=${token}&organizationId=${organization}&permissionGroupId=${permissionGroupId}`);
  }
  searchAttachmentMultiStatusForSearch(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/listattachmentwithmultistatusForSearch`, body);
  }
  searchAttachmentMultiStatusAndFilter(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/listAttachmentWithMultiStatusAndFilter`, body);
  }

  profileDetail(profileId: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/${profileId}?token=${token}`);
  }
  profileandstore(profileId: string, status: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getProfileandStore?token=${token}&idProfile=${profileId}&status=${status}`);
  }
  profileandattachment(profileId: string, status: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/identityCard/getprofileandattachment?token=${token}&idProfile=${profileId}`);
  }
  profileStoreAndCategory(profileId: string) {
    var organization = JSON.parse(localStorage.getItem('organization')).id;
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getProfileStoreAndCategory?token=${token}&idProfile=${profileId}&organizationId=${organization}`);
  }

  categoryDetail(categoryId: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/category/${categoryId}?token=${token}`);
  }

  storeFull(storeId: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/store/full/?token=${token}&storeId=${storeId}`);
  }

  documentList(profileId: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/document/list/?token=${token}&profileId=${profileId}`);
  }
  // listDocumentAndAttachment(profileId: string){
  //   const token = localStorage.getItem('access_token');
  //   return this.http.get<any>(`${environment.apiUrlVnptResource}/document/listDocumentAndAttachment/?token=${token}&profileId=${profileId}`);
  // }

  listDocumentAndAttachment(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/getlistdocumentandattachment?token=${token}&idProfile=${body}`);
  }




  attachmentList(documentId: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/attachment/list/?token=${token}&documentid=${documentId}`);
  }

  xuatBanHoSo(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/updatestatus`, body);
  }
  xuatBanHoSoAnGiang(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/updatestatus`, body);
  }
  //Xuất bản hồ sơ có người phê duyệt
  publishProfile(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/updateStatusWithAuth`, body);
  }
  profileUpdate(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/update`, body);
  }
  profileUpdateStatus(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/updatestatus2?token=${token}`, body);
  }
  profileInvalidUpdateStatus(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/updatestatus2?token=${token}`, body);
  }

  updateProfileAndDocumentAttribute(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/updatestatus2WithAuth?token=${token}`, body);
  }
  autoCreateProfile(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/profileautocreate?token=${token}`, body);
  }
  deleteProfile(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/delete?token=${token}`, body);
  }

  documentUpdate(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/update`, body);
  }
  documentCreate(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/create`, body);
  }
  documentDelete(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/delete`, body);
  }

  updatedocumentandlistattachment(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/updateDocumentAndlistAttachment?token=${token}`, body);
  }
  updateattachmentbyidprofile(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/updateAttachmentByIdProfile?token=${token}`, body);
  }


  createdocumentandlistattachment(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/createDocumentAndListAttachment?token=${token}`, body);
  }
  attachmentCreate(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/Attachment/create?token=${token}`, body);
  }

  genUrlViewFile(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/image/geturlview?token=${token}`, body);
  }
  attachmentCreateAndOcrfulltext(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/Attachment/createandorc?token=${token}`, body);
  }
  attachmentConvertFileToPdf(body: any) {
    const token = localStorage.getItem('access_token');
    ////console.log
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/convertToPdf?token=${token}`, body);
  }
  attachmentUpdate(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/Attachment/update?token=${token}`, body);
  }
  attachmentDelete(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/Attachment/delete?token=${token}`, body);
  }

  searchByWordPharse(pageNumber: number, pageSize: number, valueSearch: any, organizationID: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/search/listwithstatusenable?token=${token}
    &pageNumber=${pageNumber}&pageSize=${pageSize}&valueSearch=${valueSearch}&organizationId=${organizationID}`);
  }


  attachmentSearch(status: any, organizationId: any, pageNumber: number, pageSize: number, valueSearch: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/search/listattachmentwithstring?token=${token}&valueSearch=${valueSearch}
    &pageNumber=${pageNumber}&pageSize=${pageSize}&organizationId=${organizationId}&status=${status}`);
  }



  createBorrowProfile(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profileBorrow/create`, body);
  }

  getAccountstatus1(status: any) {
    const token = localStorage.getItem('access_token');
    //console.log("status:", JSON.stringify(status))
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/listbypermissioncode?token=${token}&status=${status}`);
  }

  getAccountstatus1New(status: any) {
    const token = localStorage.getItem('access_token');
    const organizationId = JSON.parse(localStorage.getItem('organization')).id;
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/listbypermissioncodenew?token=${token}&status=${status}&organizationId=${organizationId}`);
  }


  getProfileList(valueSearch: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/search/listwithfullstring?token=${token}&valueSearch=${valueSearch}`);
  }

  commentCreate(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/comment/create?token=${token}`, body);
  }
  commentGetListByTime(id: string, start: string, end: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/comment/listbytime?token=${token}&id=${id}&start=${start}&end=${end}`);
  }
  // commentGetListDesc(pageSize: string, start: string,end: string ) {
  //   const token = localStorage.getItem('access_token');
  //   return this.http.get<any>(`${environment.apiUrlVnptResource}/comment/list?token=${token}&pageSize=${pageSize}&start=${start}&end=${end}`);
  // }
  getListRegistrationFormByProfileId(profileId: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/registrationform/listbyprofileid?token=${token}&profileId=${profileId}`);
  }
  commentUpdate(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/comment/updates?token=${token}`, body);
  }
  commentDeleteById(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/comment/delete?token=${token}`, body);
  }
  commentGetListByNameProfile(pageSize: string, start: string, end: string, nameProfile: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/comment/listbynameprofile?token=${token}&pageSize=${pageSize}&start=${start}&end=${end}&nameProfile=${nameProfile}`);
  }
  commentGetListAndCountProfile(start: string, end: string, idProfile: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/comment/listcommentandcountprofile?token=${token}&start=${start}&end=${end}&idProfile=${idProfile}`);
  }


  getListAccessLog(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/logbyconditiontime?token=${token}`, body);
  }

  deleteaccesslog(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/logbyconditiontime/delete?token=${token}`, body);
  }

  getListErrorLog(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/errbyconditiontime?token=${token}`, body);
  }

  deleteErrorLogById(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/errbyconditiontime/delete?token=${token}`, body);
  }
  getListLogCrud(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/CrudLogerrbyconditiontime?token=${token}`, body);
  }
  deleteLogCrudById(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/CrudLog/delete?token=${token}`, body);
  }

  createInvalidProfile(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/invalidProfile/create?token=${token}`, body);
  }
  getListAccountByOrganizationId(organizationId: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/listallbyorganization?token=${token}&organizationId=${organizationId}`);
  }
  getProfilebyId(idProfile: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/${idProfile}`);
  }
  publishMultiProfile(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/profile/multiupdatestatus?token=${token}`, body);
  }
  getListInvalidProfileByIdProfile(profileId: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/invalidProfile/getlistbyprofileid?token=${token}&profileId=${profileId}`);
  }
  downLoadHoSo(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/image/downloadzip?token=${token}`, body);
  }
  createClassDocument(body: FormData) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DataTrain/createdatatrain?token=${token}`, body)
  }
  labelClassDocument(body: FormData) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DataTrain/Label?token=${token}`, body)
  }
  deleteClassDocument(body: FormData) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DeleteDataTrain/{Id}?token=${token}`, body)
  }
  trainClassDocument(idOrganization: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/DataTrain/Train?token=${token}&idOrganization=${idOrganization}`);
  }

  changeApprovedAccount(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/registrationform/changeapprovalaccount?token=${token}`, body)
  }

  changeApprovedPaperAccount(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/paperregistrationform/changeapprovalaccount?token=${token}`, body)
  }
  SendMessageOcrFulltext(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/ocr/sendocrfulltext?token=${token}`, body)
  }

}
