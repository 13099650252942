import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(public toastr: ToastrService) { }

  showSuccess(messageParam: string, titleParam: string, positionClassParam: string) {
    const positionClass = positionClassParam == undefined ? 'toast-top-right' : positionClassParam;
    const message = messageParam == undefined ? 'Getting data successfully' : messageParam;
    const title = titleParam == undefined ? 'Success' : titleParam;
    this.toastr.success(message, title, {
      positionClass
    });
  }

  showError(messageParam: string, titleParam: string, positionClassParam: string) {
    const positionClass = positionClassParam == undefined ? 'toast-top-right' : positionClassParam;
    const message = messageParam == undefined ? 'Something went wrong' : messageParam;
    const title = titleParam == undefined ? 'Error' : titleParam;
    this.toastr.error(message, title, {
      positionClass
    });
  }

}
