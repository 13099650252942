
<section class="banner_area clsHomeMainBox" id="home" data-scroll-id="home">
    <div class="ui-grid-row row">
        <div class="col-md-8">
            <div class="banner_inner d-flex align-items-center" style="background-image: url(assets/img/banner/caobang-login-5.jpg);"></div>
        </div>
        <div class="col-md-4" style="margin: -15px;">
            <div class="wrap-login100">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login100-form">                
                    <div class="col-md-12">
                        <br>
                        <div>
                            <div style="text-align: center;">
                                <img _ngcontent-sgu-c1="" alt="" height="40" src="assets/img/caobang.png" style="zoom: 200%; margin-top: 10px;margin-bottom: 10px;">
                            </div>  
                        </div>

                        <br>
                        <div style="text-align: center;text-transform: uppercase;">
                            <div style="color: #ce1527; font-weight: 700; font-size: 21px;">Hệ thống tạo lập CSDL, số hóa</div>
                            <div style="color: #ce1527; font-weight: 700; font-size: 21px;">lưu trữ hồ sơ</div>
                        </div>
                        <!-- <br><br>
                        <br><br>
                        <div style="text-transform: uppercase; text-align: center;">
                            <div style="text-transform: uppercase; text-align: center;">
                                <div style="color: #ce1527; font-weight: 700; margin-top: -37px; font-size: 15px;">Đăng nhập vào hệ thống</div>
                            </div>
                        </div> -->
                        <div>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <!-- <mat-label>Username</mat-label> -->
                                <input matInput maxlength="50" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" type="text" placeholder="Tên đăng nhập">
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Username is required</div>
                                    <div *ngIf="f.username.errors.email">Invalid username</div>
                                </div>
                            </mat-form-field>
                            
                        </div>
                        <div  style="margin-top: -20px">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <!-- <mat-label>Password</mat-label> -->
                                <input matInput maxlength="50" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" type="password" placeholder="Mật khẩu">
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                    <div *ngIf="f.password.errors.minlength">Invalid password</div>
                                </div>
                            </mat-form-field>
                        </div>
                        <div *ngIf="submitted && ((f.username.errors && f.username.errors.required) || (f.password.errors && f.password.errors.required))"
                        style="margin-top: -10px; margin-bottom: -10px; font-weight: bold; color: #ce1527;">
                            Vui lòng nhập đủ thông tin
                        </div>
                        <div  style="margin-top: 25px">
                            <button class="btn btn-default submit_btn_caobang" [disabled]="isSinging">
                                <span *ngIf="!isSinging">Đăng nhập</span>
                                <span *ngIf="isSinging">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Đang đăng nhập...
                                </span>
                            </button>
                        </div> 
                    </div>
                </form> 
                <div style="text-align: center; margin-top: -5vw; background-color: white;">
                    © Sản phẩm của VNPT
                </div>
            </div>
        </div>
    </div>
        
</section>