import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AdminApiService {
  constructor(private http: HttpClient) {

  }
  ImportDepartment(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/department/import`,body);
  }
  UpdateListStoreIdByDepartmentId(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/store/updatelistwithdepartmentid`,body);
  }
  RemoveDepartmentInListCategory(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/category/updateRemoveDepartment`,body);
  }
  InsertDepartmentInListCategory(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/angiang/category/updateInsertDepartment`,body);
  }
  getListCategoryByDepartment(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/category/listbydepartment?token=${token}`);
  }
  getListAccountByDepartmentId(departmentId:string, organizationId:string){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/account/listbydepartmentid?token=${token}&departmentId=${departmentId}&organizationId=${organizationId}`);
  }
  getListStoreByDepartmentIdOnlyConfigAdmin(departmentId:string){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/store/getlistwithdepartment?token=${token}&departmentId=${departmentId}`);
  }
  getListCategoryByDepartmentIdFromInput(departmentId:string){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/angiang/category/listbydepartmentid?token=${token}&departmentId=${departmentId}`);
  }
  disableToken(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/disableToken?token=${token}`);
  }
  createThanhPhanTrienKhai(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/EgovApiReport/CreateThanhPhanTrienKhai?token=${token}`, body);
  }
  updateThanhPhanTrienKhai(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/EgovApiReport/UpdateThanhPhanTrienKhai?token=${token}`, body);
  }
  deleteThanhPhanTrienKhai(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/EgovApiReport/DeleteThanhPhanTrienKhai?token=${token}`, body);
  }
  getTPTKByUDTK_Code(udtk_code:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/EgovApiReport/GetList?token=${token}&udtk_code=${udtk_code}`);
  }
  countUserOnlineWhenCloseTab(id: any, flag:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/logout?token=${token}&id=${id}&flag=${flag}`);
  }
  getTrangThaiSuDung(udtk_code:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/EgovApiReport/GetDSTT?token=${token}&udtk_code=${udtk_code}`);
  }

  createQuanLyDoanhNghiep(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/qldn/create?token=${token}`, body);
  }

  updateQuanLyDoanNghiep(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/qldn/update?token=${token}`, body);
  }

  deleteQuanLyDoanhNghiepById(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/qldn/delete?token=${token}`, body);
  }

  getQuanLyDoanhNghiepById(id:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/qldn/getbyid?token=${token}&id=${id}`);
  }
  getAllQuanLyDoanhNghiep(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/qldn/getall?token=${token}`);
  }
  getLoaiDoiTuongKhachHang(udtk_code:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/EgovApiReport/GetLoaiDoiTuongKH?token=${token}&udtk_code=${udtk_code}`);
  }
  getQuanLyDoanhNghiepByOrganizationId(id:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/qldn/getbyorganizationid?token=${token}&id=${id}`);
  }

  statisticalProfileByYearAndOrganization(year:any, organizationId:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/profile/statisticalProfile?${token}&year=${year}&organizationId=${organizationId}`);
  }

  onSyncRedis(){
    return this.http.get<any>(`${environment.apiUrlVnptResource}/organization/redismenu`);
  }

  translationText(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/image/translate?token=${token}`, body);
  }

  getListMenyByToken(userName: string, organizationId: string, token: string) {
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/listmenubytoken?token=${token}&userName=${userName}&accountId=${organizationId}`);
  }

  getAlllistMenu() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/menu/alllist?token=${token}`);
  }
  getAlllistReport() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/ReportDefinition/listall?token=${token}`);
  }

  createMenu(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/menu/create`, body);
  }

  updateMenu(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/menu/update`, body);
  }

  updateMenuCommand(body: any, headers) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/menu/updatecommand`, body, { headers });
  }

  createMenuCommand(body: any, headers) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/menu/createcommand`, body, { headers });
  }

  deleteMenu(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/menu/delete`, body);
  }

  deleteMenuCommand(body: any, headers) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/menu/deletecommand`, body, { headers });
  }

  getListPolicyMenuByToken() {
    ////console.log('getlistpolicymenubytoke');
    const token = localStorage.getItem('access_token');
    ////console.log(token);
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/listpolicymenu?token=${token}`);
  }
  getListPolicyReportByToken() {
    ////console.log('getlistpolicymenubytoke');
    const token = localStorage.getItem('access_token');
    ////console.log(token);
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/listpolicyreport?token=${token}`);
  }
  getListPolicyOCR() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/listpolicyocr?token=${token}`);
  }
  upsertGroupbyRole(body: any) {
    //console.log('upsertGroupbyRole 53');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/permission/upsertgroupbyrole`, body);
  }
  upsertGroupReportRole(body: any) {
    ////console.log('upsertGroupbyRole 53');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/permission/upsertgroupReportbyrole`, body);
  }
  upsertGroupReportOCR(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/permission/upsertgroupOCRbyrole`, body);
  }
  upsertResourcebyPolicy(body: any, headers) {
    ////console.log('upsertResourcebyPolicy 62');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/permission/upsertresourcebypolicy`, body, { headers });
  }
  upsertResourcebyPolicyReport(body: any, headers) {
    ////console.log('upsertResourcebyPolicyReport 76');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/permission/upsertresourcebypolicyReport`, body, { headers });
  }
  upsertResourcebyPolicyOCR(body: any, headers) {

    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/permission/upsertresourcebypolicyOCR`, body, { headers });
  }
  getListMenubyPolicy(policyId: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/listmenubypolicy?policyid=${policyId}&token=${token}`);
  }
  getListReportbyPolicy(policyId: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/listreportbypolicy?policyid=${policyId}&token=${token}`);
  }
  getListOCRbyPolicy(policyId: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/listocrbypolicy?policyid=${policyId}&token=${token}`);
  }
  
  deletePolicy(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/permission/deletepolicy`, body);
  }

  getWorkspaceList() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/workspace/list?token=${token}`);
  }

  upsertWorkspace(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/workspace/create`, body);
  }

  updateWorkspace(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/workspace/update`, body);
  }

  deleteWorkspace(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/workspace/delete`, body);
  }

  getOrganizationList() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/organization/list?token=${token}`);
  }

  searchByNameOrganization(name: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/organization/searchbyname?token=${token}&name=${name}`);
  }

  searchByNameGroupReport(name: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/ReportDefinition/searchReportByName?token=${token}&name=${name}`);
  }

  searchByNameGroupRole(name: any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/searchByName?token=${token}&name=${name}`);
  }

  getGroupList() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/listgroup?token=${token}`);
  }

  createOrg(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/organization/create`, body);
  }

  updatePasswordAccount(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account_new/updatePassword`, body);
  }

  getAdminbyOrganization(organizationid: any) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/adminbyorganization?organizationid=${organizationid}&token=${token}`);
  }

  updateOrganization(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/organization/update`, body);
  }

  updateAccount(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account/update`, body);
  }
  updateAccountNotCheckOldPassword(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account/updatenotcheckpassword?token=${token}`, body);
  }

  resetPasswordAdmin(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account/resetpasswordadmin?token=${token}&organizationId=${body}`, {});
  }

  resetPassword(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account/resetpassword?token=${token}&accountId=${body}`, {});
  }

  updateAccountVirtualKey(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account/updatevirtual?token=${token}`, body);
  }
  // update account không can mat khau
  updateAccountDontNeedPassword(body: any) {
    const token = localStorage.getItem('access_token');
    //console.log(token);
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account/updateaccountdontneedpassword?token=${token}`, body);
  }

  UpdateAccountExpiryPassword(body: any) {
    const token = localStorage.getItem('lastToken');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account/updateExpiryPassword?token=${token}`, body);
  }

  deleteOrg(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/organization/delete`, body);
  }

  getPamameterListByToken() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/parameter/listbytoken?token=${token}`);
  }

  createParam(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/parameter/create`, body);
  }

  updateParam(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/parameter/update`, body);
  }

  deleteParam(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/parameter/delete`, body);
  }

  getAccountByToken() {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/fullobject?token=${token}`);
  }
  getAccountByTokenKeyVirtual(id) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/fullobjectbyid?token=${token}&id=${id}`);
  }

  postDataByHeaderAndUrl(url: string, body: any, headers) {
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}` + url, body, { headers });
  }

  updateMenuList(body: any) {
    return this.http.post<any>(`${environment.apiUrlVnptResource}/menu/updatelist`, body);
  }

  getDataByUrl(url: string) {
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}` + url + `?token=${token}`);
  }

  getDataByParameterAndUrl(url: string, par: string) {
    const token = localStorage.getItem('access_token');
    //console.log(`${environment.apiUrlVnptResource}` + url + `?token=${token}` + par);
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}` + url + `?token=${token}` + par);
  }

  postDataByUrl(url: string, body: any) {
    //console.log(localStorage.getItem('access_token'));
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}` + url, body);
  }
  postDataByUrl2(url: string, body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}` + url, body);
  }
  createSyncProfileByOrganiationId(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/profile/insertlist?token=${token}`, body);
  }

  createSyncAttachmentByOrganiationId(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/attachment/insertlist?token=${token}`, body);
  }

  createSyncRegistrationFormByOrganiationId(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/search/registrationform/insertlist?token=${token}`, body);
  }

  importListAccount(body: any) {
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account_new/importaccount?token=${token}`, body);
  }
  updateAccountFullnameAsync(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account/updatefullname?token=${token}`, body);
  }
  updateListResourceByPolicyAsync(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResourceIP}/permission/upsertlistresourcestorebygeneralgroup?token=${token}`, body);
  }
  listCategoryStoreStatusByGeneralGroup(policyId:any){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResourceIP}/permission/listcategorystorestatusbygeneralgroup?token=${token}&policyId=${policyId}`);
  }
  synchronizedDecentral(){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DataTrain/SynchronizedDecentralization?token=${token}`,{});
  }

  synchronizedProfileCode(){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DataTrain/SynchronizedProfileCode?token=${token}`,{});
  }

  synchronizedStoreCode(){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DataTrain/SynchronizedStoreCode?token=${token}`,{});
  }

  synchronizedCategoryCode(){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DataTrain/SynchronizedCategoryCode?token=${token}`,{});
  }

  synchronizedRegistrationFormCode(){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/DataTrain/SynchronizedRegistrationFormCode?token=${token}`,{});
  }

  synchronizedApprovalGroup34(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/organization/syncapprovalgroup?token=${token}`);
  }

  synchronizedAccountChannel(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/synchronizedChannel?token=${token}`);
  }

  signature(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/Attachment/signature?token=${token}`,body);
  }

  setUpSignAccount(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/account/setupsignaccount?token=${token}`,body);
  }
  upsertAccountSmartCA(body:any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/accountsmartca/create?token=${token}`,body);
  }
  getInfoAccountSmartCA(accountId:string){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/accountsmartca/getinfo?token=${token}&accountId=${accountId}`);
  }

  upsertListSignature(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/document/upsertListFileSignature?token=${token}`,body);
  }

  getListSignature(value:string){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/image/getListSignature?token=${token}&type=${value}`);
  }

  updateLicenseKey(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/license/update`,body);
  }

  // Các hàm gọi các api kiểm tra kết nối servers
  //
  //
  //
  checkDmsToSearch(){
    const token = localStorage.getItem('access_token');
    //console.log(token);
    return this.http.get<any>(`${environment.apiUrlVnptResource}/checkconnection/dmstosearch?token=${token}`);
  }
  checkDmsToPermission(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/checkconnection/dmstopermission?token=${token}`);
  }
  checkDmsToPython(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/checkconnection/dmstopython?token=${token}`);
  }
  synchronizeMissingData(){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/checkconnection/synchronizedatamenuitemonenglish?token=${token}`, {});
  }
  checkDmsToObjectStorage(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/checkconnection/dmstostorage?token=${token}`);
  }
  checkDmsToIC(body: any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/checkconnection/dmstoic`,body);
  }
  
  searchUser(name){
    const token = localStorage.getItem('access_token');
    const organizationId = JSON.parse(localStorage.getItem('organization')).id;
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account_new/searchlistuserbytoken?token=${token}&organizationId=${organizationId}&name=${name}`);
  }
  resetAllAccountByOrganization(organizationId){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/account/resetpasswordall?token=${token}&organizationId=${organizationId}`);
  }
}
