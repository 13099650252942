import { EventEmitter, Injectable } from '@angular/core';
import { ILogger, LogLevel, HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
// import { Message} from '../../models/message';
import { MessageNotification } from '../../models/messageNotification';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ChatService {

    messageReceived = new EventEmitter<string>();
    connectionEstablished = new EventEmitter<Boolean>();

    // messageReceivedClient = new EventEmitter<string>();
    messageReceivedKafkaRetry = new  EventEmitter<MessageNotification>();

    messageReceivedClient = new EventEmitter<MessageNotification>();
    messageReceivedClient2 = new Array<MessageNotification>();
    nameReceivedToSever = new EventEmitter<string>();
    accountonline = new EventEmitter<string>();
    private connectionIsEstablished = false;
    private _hubConnection: HubConnection;

    constructor(private http: HttpClient) {
        // this.createConnection();
        // this.registerOnServerEvents();
        // this.startConnection();
    }

    sendMessageToClient(name: string, message: MessageNotification) {
        //console.log('sendMessagetoclient chat service');
        this._hubConnection.invoke('SendNotificationToClient', name, message);
    }

    private createConnection() {
        this._hubConnection = new HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Debug)
            .withUrl(environment.chatUrl, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .build();
    }

    private startConnection(): void {
        this._hubConnection
            .start()
            .then(() => {
                this.connectionIsEstablished = true;
                //console.log('Hub connection started');
                this.connectionEstablished.emit(true);
            })
            .catch(err => {
                //console.log('Error while establishing connection, retrying...');
                setTimeout(function () { this.startConnection(); }, 5000);
            });
    }

    private registerOnServerEvents(): void {
        this._hubConnection.on('NotificationToClient', (nameReceivedToSever: string, message: MessageNotification) => {
            ////console.log('nameReceivedToServer: '+nameReceivedToSever);
            ////console.log('message: '+ JSON.stringify(message));   
            var accountId = JSON.parse(localStorage.getItem('accountId'));         
            if (nameReceivedToSever == accountId) {
                if(message.user_sendMessage != "NOTIFICATION_RETRY_SYSTEM"){
                    this.nameReceivedToSever.emit(nameReceivedToSever);
                    this.messageReceivedClient.emit(message);
                } else {
                    //Dùng cho chức năng push thông báo retry kafka.
                    this.nameReceivedToSever.emit(nameReceivedToSever);
                    this.messageReceivedKafkaRetry.emit(message);
                }
                
            }
        });
        this._hubConnection.on('updateCount', slaccount => {
            ////console.log('slaccount:'+slaccount);
            this.accountonline.emit(slaccount);
            ////console.log('adfadfadfa'+this.accountonline);
        })
    }

    createNotification(body: any) {
        const token = localStorage.getItem('access_token');
        return this.http.post<any>(`${environment.apiUrlVnptResource}/Notification/create?token=${token}`, body);
    }

    getListByNameNotification(name:string){
        const token = localStorage.getItem('access_token');
        return this.http.get<any>(`${environment.apiUrlVnptResource}/Notification/getlistname?name=${name}&token=${token}`);
    }

    updateNotification(body: any){
        const token = localStorage.getItem('access_token');
        return this.http.post<any>(`${environment.apiUrlVnptResource}/Notification/update?token=${token}`, body);
    }

    getListNotificationByChannel(page: any, limit: any ){
        const token = localStorage.getItem('access_token');
        return this.http.get<any>(`${environment.apiUrlVnptResource}/Notification/bychannel?token=${token}&page=${page}&limit=${limit}`);
    }

    updateListNotificationStatus(body: any){
        const token = localStorage.getItem('access_token');
        return this.http.post<any>(`${environment.apiUrlVnptResource}/Notification/updatestatus`, body);
    }
}  
