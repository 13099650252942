import { AppGlobals } from './../../pages/common/app.global';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ROUTESs } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './../../services/app.service';
import { NgZone, EventEmitter, Output } from '@angular/core';
import { MessagesService } from '../../../app/services/messages.service';
import { LoginService } from '../../../app/services/login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminApiService } from '../../../app/services/admin-api.service';
import { first } from 'rxjs/operators';
import { NotificationService } from '../../../app/services/notification.service';
import { CustomFormValidation } from '../../../app/services/custom-form-validator';
import { UtilityFunction } from '../../../app/services/utility-functions';
import { TreeNode } from 'primeng/api';
import { ChatService } from '../../../app/services/pipe/chat.service';
import { MessageNotification } from '../../../app/models/messageNotification';
import { DropdownModule } from 'primeng/dropdown';
// import dataLanguage from "../../../assets/i18n/vi.json";
// import { AppGlobals } from '../../../app/pages/common/app.global';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { DataService } from '../../services/data.service';
import { constantsValue } from '../../const';
import { HosoApiService } from '../../services/search-api.service';
import { environment, ProductInfo, VCMNotification } from 'environments/environment';


const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    @Output() languageNavbar = new EventEmitter();
    @Output() showSidebar = new EventEmitter();
    @ViewChild('uploadfile') uploadfile;
    @ViewChild('uploadfileSmartCA') uploadfileSmartCA;
    selectedLanguage = 'vi';

    showSidebar2 = true;
    isCollapsed = true;
    showRowNav: string;
    isLogin: boolean;
    userName: string;
    fullName: string;
    headerTitle: any;
    display: boolean;
    roleDisplay: boolean;
    addInforForm: FormGroup;
    signAccountForm: FormGroup;
    signAccountSmartCA: FormGroup;
    submittedForm: boolean;
    submittedForm2: boolean;
    changePassword = false;
    isSaving = false;
    selectedNodes3: TreeNode[];
    roleData: any[];
    treeCols: ({ field: string; subField: string; header: string; } | { field: string; header: string; subField?: undefined; })[];
    selectedNodes3Org: TreeNode[];
    showRoleEdit = false;
    //start dev_binh notification
    notificationDisplay = false;
    messagesClient: any[] = [];
    notiTotal = 0;
    countNotSee = 0;
    isSaving2 = false;
    passwordoldd = '';

    capnhatthanhcong = "Cập nhật thành công";
    capnhatkhongthanhcong = 'Cập nhật không thành công';
    xoadulieuthanhcong = 'Xóa dữ liệu thành công';
    xoadulieukhongthanhcong = 'Xóa dữ liệu không thành công';
    themmoithanhcong = 'Thêm mới thành công';
    themmoikhongthanhcong = 'Thêm mới không thành công';
    bancochacxoadulieunay = 'Bạn có chắc xóa dòng dữ liệu này!';
    co = 'Có';
    khong = 'Không';
    thongbao = "Thông báo";
    matkhaucukhongchinhxac = "Mật khẩu cũ không chính xác";
    matkhaurong = "Mật khẩu bị rỗng";
    phienhoatdonghethan = "Phiên hoạt động của bạn đã hết";
    laydulieukhongthanhcong = 'Lấy dữ liệu người dùng không thành công';
    khongtheluuqua5hinhanhkiso = 'Không thể lưu quá 5 hình ảnh kí số';


    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    // private toggleButton: any;
    sidebarVisible: boolean;
    myFiles: File[] = [];
    myFilesSmartCA: File[] = [];
    myFilesId: string[] = [];
    myFilesIdSmartCA: string[] = [];
    myFilesBefore: File[] = [];
    myFilesBeforeSmartCA: File[] = [];
    myFilesBeforeId: string[] = [];
    myFilesBeforeIdSmartCA: string[] = [];

    myFilesBase64: any[] = [];
    myFilesBase64SmartCA: any[] = [];

    signImageName: any;
    indextemp = -1;
    indextempSmartCA = -1;
    currentFile: File;
    currentFileSmartCA: File;
    type = '';
    myFilesLinkFtp: string[] = [];
    myFilesLinkFtpSmartCA: string[] = [];
    imageSrc = '';
    errorMessage = "";
    isSavingAccountSign = false;
    isSavingImageSign = false;
    isSavingImageSignSmartCA = false;
    displayShowImage = false;
    isSavingAccountSignSmartCA = false;
    submittedFormSmartCA = false;

    currentPageNoti = 1;
    limitPage = 10;

    upsertListFileSignature = constantsValue.apiUpload + '/document/upsertListFileSignature';
    version = "";
    TypeSign = [
        { value: 1, name: "VNPT Sign" },
        { value: 2, name: "Smart CA" }
    ];
    selectedTypeSign: any;

    listIdNotification: any[] = [];

    defaultChannel = JSON.parse(localStorage.getItem('accountId'));
    // defaultChannel = "a9b5dc22-ce45-4275-b1df-99713c18282d";

    // centrifugeJWT = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ2dWUtc2RrIn0.rqqFBcGquJJL68qrdEIFHbVv9SIjSLCfcnafNGx1T0M";
    centrifugeJWT = VCMNotification.centrifugeJWT;
    //centrifugoConnectionURL = "ws://10.159.137.27:8000/connection/websocket";
    centrifugoConnectionURL = VCMNotification.centrifugoConnectionURL;

    hide = true;
    isShowHide = false;

    constructor(location: Location, private element: ElementRef, private router: Router, private dataService: DataService, private customFormValidation: CustomFormValidation, private notiService: NotificationService,
        private adminApiService: AdminApiService, private appService: AppService, private formBuilder: FormBuilder, private hosoapiService: HosoApiService,
        private messageService: MessagesService, private loginService: LoginService, private utilityFunction: UtilityFunction,
        private chatService: ChatService, private _ngZone: NgZone, private translate: TranslateService, private _router: Router) {
        this.location = location;
        this.sidebarVisible = false;

    }

    ngOnInit() {
        //console.log('channel', this.defaultChannel);
        this.version = ProductInfo.sVersion;
        // this.getListSignature();
        this.selectedLanguage = AppGlobals.language;
        this.languageNavbar.emit(AppGlobals.language);
        this.showSidebar2 = true;

        this.treeCols = [
            { field: 'name', subField: 'selected', header: 'Tên menu' },
            { field: 'code', header: 'Mã hệ thống' },
        ];
        this.addInforForm = this.formBuilder.group({
            id: ['', []],
            fullname: ['', [Validators.required, Validators.pattern(/^[^\s].*/)]],
            status: ['', []],
            personalinformation: this.formBuilder.group({
                //email: ['', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
                email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
                phonenumber: ['', [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/)]],
                id: ['', []],
            }),
            //oldpassword: ['', []],
            password: ['', []],
            confirmPassword: ['', []],
            xacnhancapnhat: ['', []]
        });

        this.signAccountForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]],
            clientId: ['', [Validators.required]],
            clientSecret: ['', [Validators.required]],
        });
        this.signAccountSmartCA = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });

        this.messageService.currentRowNavMessageMessage.subscribe(message => this.showRowNav = message);
        this.messageService.currentLoginMessageMessage.subscribe(message => this.isLogin = message);
        //if (this.isLogin === true) {
        this.userName = localStorage.getItem('user_name');
        this.fullName = localStorage.getItem('full_name');
        if (this.userName === 'SystemAdmin') {
            this.showRoleEdit = true;
        }

        this.loadReceivedNotification();
        this.receivedNotification();
        if (AppGlobals.language == "en") {
            this.capnhatthanhcong = "Update successful";
            this.capnhatkhongthanhcong = 'Update Failed';
            this.xoadulieuthanhcong = 'Successfully deleted data';
            this.xoadulieukhongthanhcong = 'Data deletion failed';
            this.themmoikhongthanhcong = 'New addition failed';
            this.themmoithanhcong = 'Add successfully new';
            this.bancochacxoadulieunay = 'Are you sure to delete this data stream!';
            this.co = 'Yes';
            this.khong = 'No';
            this.thongbao = "Notification";
            this.matkhaucukhongchinhxac = "The old password is incorrect";
            this.matkhaurong = "Password is empty";
            this.phienhoatdonghethan = "Your session has expired";
            this.laydulieukhongthanhcong = 'Failed to retrieve user data';
            this.khongtheluuqua5hinhanhkiso = "Can't save more than 5 digital images";
        }
        // var mArrMenuSort = JSON.parse(localStorage.getItem('mArrMenuSort'));
        // //var mArrMenuSort2 = localStorage.getItem('mArrMenuSort2');
        // this.listTitles = mArrMenuSort.filter(listTitle => listTitle);

        // const navbar: HTMLElement = this.element.nativeElement;
        // this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        if ($('body').hasClass('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if ($('body').hasClass('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        $('#minimizeSidebar').click(function () {
            if (misc.sidebar_mini_active === true) {
                $('body').removeClass('sidebar-mini');
                misc.sidebar_mini_active = false;

            } else {
                setTimeout(function () {
                    $('body').addClass('sidebar-mini');

                    misc.sidebar_mini_active = true;
                }, 300);
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            const simulateWindowResize = setInterval(function () {
                window.dispatchEvent(new Event('resize'));
            }, 180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function () {
                clearInterval(simulateWindowResize);
            }, 1000);
        });
        $('#hideSidebar').click(function () {
            if (misc.hide_sidebar_active === true) {
                setTimeout(function () {
                    $('body').removeClass('hide-sidebar');
                    misc.hide_sidebar_active = false;
                }, 300);
                setTimeout(function () {
                    $('.sidebar').removeClass('animation');
                }, 600);
                $('.sidebar').addClass('animation');

            } else {
                setTimeout(function () {
                    $('body').addClass('hide-sidebar');
                    // $('.sidebar').addClass('animation');
                    misc.hide_sidebar_active = true;
                }, 300);
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            const simulateWindowResize = setInterval(function () {
                window.dispatchEvent(new Event('resize'));
            }, 180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function () {
                clearInterval(simulateWindowResize);
            }, 1000);
        });

    }

    getNotification() {
        this.chatService.getListNotificationByChannel(this.currentPageNoti, this.limitPage)
            .subscribe((res: any) => {
                if (res.notification_list)
                    this.messagesClient = this.messagesClient.concat(res.notification_list);
                this.notiTotal = res.total;
            })
    }
    onShowHideSidebar() {
        this.isShowHide = !this.isShowHide;
        const selectSidebar = document.getElementById('sidebarCustom');
        if (this.isShowHide == true) {
            selectSidebar.style.display = 'none';
        } else {
            selectSidebar.style.display = '';
        }
    }
    sidebarOpen() {
        const toggleButton = document.getElementsByClassName('navbar-toggle')[0];
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
        const getElement2 = document.querySelectorAll(".logo-caobang");
        if(getElement2.length >0){
            // getElement2[0].classList.remove("checkLogoMini");
            getElement2[0].classList.add("logo-mini-caobang");
        }
        

    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        const toggleButton = document.getElementsByClassName('navbar-toggle')[0];
        toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        const getElement2 = document.querySelectorAll(".logo-mini-caobang");
        if(getElement2.length >0){
            getElement2[0].classList.remove("logo-mini-caobang");
        }

    };

    sidebarToggle() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        // if (titlee.charAt(0) === '#') {
        titlee = titlee.slice(1);
        titlee = titlee.replace("vnpt/", "");
        // }
        if (titlee == "dashboard") {
            return "Dashboard";
        }
        var mArrMenuFromParent = [];
        var dataMenu = JSON.parse(localStorage.getItem('menu'));

        for (const permission of dataMenu.permissions) {
            for (const menuitem of dataMenu.menuitems) {
                if (permission.id == menuitem.id) {
                    mArrMenuFromParent.push(menuitem);
                }
            }
        }

        for (var item = 0; item < mArrMenuFromParent.length; item++) {
            if (mArrMenuFromParent[item].path == titlee) {
                if (this.selectedLanguage == 'vi') {
                    return mArrMenuFromParent[item].name;
                }
                return mArrMenuFromParent[item].nameENG;

            }
        }
        return '';
    }
    ChangLanguage(value: string) {
        this.selectedLanguage = value;
        AppGlobals.language = value;
        this.languageNavbar.emit(value);
        this.dataService.changeMessage(value);
    }

    loadReceivedNotification() {
        this.listIdNotification = [];
        this.chatService.getListNotificationByChannel(1, 10).pipe(first()).subscribe(data => {
            // console.log("data", data);
            if (data != undefined && data.notification_list != undefined) {
                data.notification_list.forEach(element => {
                    if (element.message.params.data.data.custom.status == true) {
                        this.countNotSee = this.countNotSee + 1;
                    }
                    this.listIdNotification.push(element.id)
                    this.messagesClient.push(element);
                    this.messagesClient.sort((a, b) => (a.time < b.time) ? 1 : -1);
                });

                this.notiTotal = data.total;
                localStorage.setItem('notification', data.toString());
            }
        });
    }

    loadReceivedNotificationFromStorage() {
        var notification = JSON.parse(localStorage.getItem('notification'));

        for (var i = 0; i < notification.length; i++) {
            if (notification[i].type == 'sent') {
                this.countNotSee = this.countNotSee + 1;
            }
            this.messagesClient.push(notification[i]);
            this.messagesClient.sort((a, b) => (a.date < b.date) ? 1 : -1);
        }

    }

    private receivedNotification() {
        var x = '';

        var Centrifuge = require("centrifuge");

        var centrifuge = new Centrifuge(this.centrifugoConnectionURL);
        centrifuge.setToken(this.centrifugeJWT);
        var self = this;
        centrifuge.subscribe(this.defaultChannel, function (message) {
            //console.log("adsadasdasdas",message );
            let notification = message.data.notification.message.replaceAll('<b>', '');
            notification = notification.replaceAll('</b>', '');
            let title = message.data.notification.title;
            if (notification != undefined) {
                self.notiService.showSuccess(notification, title, undefined);
            }
            var newNoti = { message: { params: message }, time: Date.now() };
            self.listIdNotification.push(message.data.id);
            self.messagesClient.unshift(newNoti);
            self.countNotSee = self.countNotSee + 1;

        });

        centrifuge.connect();
    }

    // selectionNotification() {
    //     this.notificationDisplay = true;
    //     this.countNotSee = 0;
    //     this.messagesClient.forEach(element => {
    //         if (element.type == 'sent') {
    //             element.type = 'received';
    //             this.chatService.updateNotification(element).pipe(first()).subscribe(data => {

    //             },
    //                 err => {

    //                 });
    //         }
    //     });
    // }

    // end dev_binh
    nodeSelect(event) {
        this.processCheckBoxTreeTable(event.node);
        this.removeDuplicateObjectInArray();
    }

    nodeUnselect(event) {
        for (const [i, value] of this.selectedNodes3.entries()) {
            this.processCheckBoxTreeTable(value);
        }
        this.removeDuplicateObjectInArray();
    }

    removeDuplicateObjectInArray() {
        this.selectedNodes3 = this.selectedNodes3.reduce((acc, current) => {
            const x = acc.find(item => item.data.id === current.data.id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
    }

    processCheckBoxTreeTable(event) {
        const tmp = event;
        let check;
        if (tmp.data.level1ParentId !== undefined && tmp.data.level1ParentId !== '') {
            check = this.selectedNodes3.find(item => item.data.id === tmp.parent.data.id);
            if (check === undefined) {
                tmp.parent.partialSelected = false;
                this.selectedNodes3.push(tmp.parent);
            }
            check = this.selectedNodes3.find(item => item.data.id === tmp.parent.parent.data.id);
            if (check === undefined) {
                tmp.parent.parent.partialSelected = false;
                this.selectedNodes3.push(tmp.parent.parent);
            }
        }
        if (tmp.data.level0ParentId !== undefined && tmp.data.level0ParentId !== '') {
            check = this.selectedNodes3.find(item => item.data.id === tmp.parent.data.id);
            if (check === undefined) {
                tmp.parent.partialSelected = false;
                this.selectedNodes3.push(tmp.parent);
            }
        }
    }

    public onEditRole(title) {
        this.adminApiService.getAlllistMenu().pipe(first()).subscribe(data => {
            this.transformData(data);
            this.selectedNodes3 = [];
            this.processCheckBoxOpenDialog();
            this.selectedNodes3Org = JSON.parse(JSON.stringify(this.selectedNodes3));
            this.showDetailRoleDialog(title);
        },
            error => {

            });
    }

    onSaveMenuList() {
        this.isSaving2 = true;
        const tmp = this.processListToSubmit();
        this.adminApiService.updateMenuList(tmp).pipe(first()).subscribe(data => {
            this.isSaving2 = false;
            this.notiService.showSuccess(this.capnhatthanhcong, undefined, undefined);
            this.roleDisplay = false;
        },
            error => {
                this.notiService.showError(this.capnhatkhongthanhcong, undefined, undefined);
                this.isSaving2 = false;
            });
    }

    processListToSubmit() {
        const tmp = [];
        let dataRemoveFromOrg;

        dataRemoveFromOrg = this.selectedNodes3Org.filter(obj => {
            return !this.selectedNodes3.some(obj2 => {
                return obj.data.id === obj2.data.id;
            });
        });

        let dataAddFromOrg;

        dataAddFromOrg = this.selectedNodes3.filter(obj => {
            return !this.selectedNodes3Org.some(obj2 => {
                return obj.data.id === obj2.data.id;
            });
        });

        for (const [i, value] of dataRemoveFromOrg.entries()) {
            let datachild;
            datachild = {};
            datachild.id = value.data.id;
            datachild.code = value.data.code;
            datachild.name = value.data.name;
            datachild.path = value.data.path;
            datachild.iconPath = value.data.iconPath;
            datachild.description = value.data.description;
            datachild.order = value.data.order;
            datachild.status = value.data.status;
            datachild.statusAdmin = 1;
            tmp.push(datachild);
        }
        for (const [i, value] of dataAddFromOrg.entries()) {
            let datachild;
            datachild = {};
            datachild.id = value.data.id;
            datachild.code = value.data.code;
            datachild.name = value.data.name;
            datachild.path = value.data.path;
            datachild.iconPath = value.data.iconPath;
            datachild.description = value.data.description;
            datachild.order = value.data.order;
            datachild.status = value.data.status;
            datachild.statusAdmin = 10;
            tmp.push(datachild);
        }
        return tmp;
    }

    processCheckBoxOpenDialog() {

        for (const [i, value] of this.roleData.entries()) {
            if (value.data.statusAdmin === 10) {
                this.selectedNodes3.push(value);
            }
            for (const [j, value1] of value.children.entries()) {
                if (value1.data.statusAdmin === 10) {
                    this.selectedNodes3.push(value1);

                }
                for (const [k, value2] of value1.children.entries()) {
                    if (value2.data.statusAdmin === 10) {
                        this.selectedNodes3.push(value2);

                    }
                }
            }
        }

    }

    transformData(originalData) {
        const level0 = [];
        const level1 = [];
        const level2 = [];
        for (const [i, value] of originalData.entries()) {
            if (value.parent === null && value.name == "Quản Trị Website") {
                level0.push(value);
            }
            if (value.parent === null && value.name == "Báo Cáo") {
                level0.push(value);
            }
            if (value.parent !== null) {
                value.level0ParentId = value.parent.id;
                level1.push(value);
            }
            if (value.commands != null) {
                for (const [j, value1] of value.commands.entries()) {
                    value1.flagCommand = true;
                    if (value.level0ParentId === undefined || value.level0ParentId === null) {
                        value1.level0ParentId = value.id;
                        level1.push(value1);
                    }
                    // else {
                    //   value1.level1ParentId = value.id;
                    //   level2.push(value1);
                    // }
                }
            }
        }
        this.roleData = [];
        for (const [i, value] of level0.entries()) {
            const tmplevel0 = { data: null, expanded: true, children: [] };
            tmplevel0.data = value;
            for (const [j, value1] of level1.entries()) {
                if (value1.level0ParentId === value.id) {
                    const tmplevel1 = { data: null, expanded: true, children: [] };
                    tmplevel1.data = value1;
                    for (const [k, value2] of level2.entries()) {
                        if (value2.level1ParentId === tmplevel1.data.id) {
                            const tmplevel2 = { data: null, expanded: true, children: [] };
                            tmplevel2.data = value2;
                            tmplevel1.children.push(tmplevel2);
                        }
                    }
                    tmplevel0.children.push(tmplevel1);
                }
            }
            this.roleData.push(tmplevel0);
        }


    }

    cannotContainSpace(control: AbstractControl): ValidationErrors | null {
        if (/\s/.test(control.value as string)) {
            return { cannotContainSpace: true }
        }

        return null;
    }

    toggleValidatorProfile() {
        // this.changePassword = !this.changePassword;
        // //const oldpassword = this.addInforForm.get('oldpassword');
        // const password = this.addInforForm.get('password');
        // const confirmPassword = this.addInforForm.get('confirmPassword');
        // if (this.changePassword === false) {
        //     console.log("Switch true");
        //     //oldpassword.setValidators([Validators.required, this.cannotContainSpace]);
        //     password.setValidators([Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}'), this.cannotContainSpace]);
        //     confirmPassword.setValidators([Validators.required, this.cannotContainSpace]);
        //     this.addInforForm.setValidators(this.customFormValidation.comparisonValidator('password', 'confirmPassword'));

        // } else {
        //     console.log("Switch false");
        //     //oldpassword.clearValidators();
        //     password.clearValidators();
        //     confirmPassword.clearValidators();
        //     this.addInforForm.clearValidators();
        // }
        // //oldpassword.updateValueAndValidity();
        // password.updateValueAndValidity();
        // confirmPassword.updateValueAndValidity();
        this.changePassword = !this.changePassword;
        this.removePasswordValidator();
    }

    addPasswordValidator() {
        const password = this.addInforForm.get('password');
        const confirmPassword = this.addInforForm.get('confirmPassword');
        password.setValidators([Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).{8,}'), this.cannotContainSpace]);
        confirmPassword.setValidators([Validators.required, this.cannotContainSpace]);
        this.addInforForm.setValidators(this.customFormValidation.comparisonValidator('password', 'confirmPassword'));
        password.updateValueAndValidity();
        confirmPassword.updateValueAndValidity();
    }

    removePasswordValidator() {
        const password = this.addInforForm.get('password');
        const confirmPassword = this.addInforForm.get('confirmPassword');
        password.clearValidators();
        confirmPassword.clearValidators();
        this.addInforForm.clearValidators();
        password.updateValueAndValidity();
        confirmPassword.updateValueAndValidity();
    }

    onSubmit() {
        let submitData = null;
        this.submittedForm = true;
        if (this.addInforForm.invalid) {
            return;
        }
        // Transform data
        submitData = this.addInforForm.value;
        delete submitData.confirmPassword;
        if (this.changePassword === true) {
            submitData.password = this.utilityFunction.encryptedpassword(submitData.password);
            submitData.oldpassword = this.utilityFunction.encryptedpassword(submitData.oldpassword);
        } else {
            delete submitData.password;
            // delete submitData.oldpassword;
            submitData.oldpassword = this.passwordoldd;
        }

        this.isSaving = true;
        submitData.userName = localStorage.getItem('user_name');
        // this.adminApiService.updateAccount(submitData).pipe(first()).subscribe(data => {
        this.adminApiService.updateAccountVirtualKey(submitData).pipe(first()).subscribe(data => {

            this.display = false;
            this.isSaving = false;
            this.fullName = data.value;
            localStorage.removeItem('full_name');
            localStorage.setItem('full_name', data.value);
            this.notiService.showSuccess(this.capnhatthanhcong, undefined, undefined);


        },
            error => {

                this.isSaving = false;
                switch (error.error.text) {
                    case "Old-Password-Is-Not-Correct": {
                        this.notiService.showError(this.matkhaucukhongchinhxac, undefined, undefined);
                        break;
                    }
                    case "Password-Is-Null": {
                        this.notiService.showError(this.matkhaurong, undefined, undefined);
                        break;
                    }
                    case "Token-Invalid-Or-You-Do-Not-Have-Permission-In-This-Area": {
                        this.notiService.showError(this.phienhoatdonghethan, undefined, undefined);
                        break;
                    }
                    default: {

                        this.notiService.showError(this.capnhatkhongthanhcong, undefined, undefined);
                        break;
                    }
                }


            });
    }

    // Hàm onSubmit sau khi bỏ cái mật khẩu cũ

    onSubmitDontNeedOldPassword() {
        if (this.changePassword)
            this.addPasswordValidator();
        else
            this.removePasswordValidator();

        let submitData = null;
        this.submittedForm = true;
        if (this.addInforForm.invalid) {
            this.notiService.showError("Invalid form", undefined, undefined);
            return;
        }
        // Transform data
        submitData = this.addInforForm.value;
        delete submitData.confirmPassword;
        if (this.changePassword === true) {
            submitData.password = this.utilityFunction.encryptedpassword(submitData.password);
            submitData.oldpassword = this.utilityFunction.encryptedpassword(submitData.oldpassword);
        }
        else {
            submitData.password = null;
            submitData.oldpassword = this.passwordoldd;
        }
        this.isSaving = true;
        submitData.userName = localStorage.getItem('user_name');

        // this.adminApiService.updateAccount(submitData).pipe(first()).subscribe(data => {
        this.adminApiService.updateAccountDontNeedPassword(submitData)
            .pipe(first())
            .subscribe(
                data => {
                    this.display = false;
                    this.isSaving = false;
                    this.fullName = data.value;
                    localStorage.removeItem('full_name');
                    localStorage.setItem('full_name', data.value);
                    this.notiService.showSuccess(this.capnhatthanhcong, undefined, undefined);

                },
                error => {

                    this.isSaving = false;
                    switch (error.error.text) {
                        case "Old-Password-Is-Not-Correct": {
                            this.notiService.showError(this.matkhaucukhongchinhxac, undefined, undefined);
                            break;
                        }
                        case "Password-Is-Null": {
                            this.notiService.showError(this.matkhaurong, undefined, undefined);
                            break;
                        }
                        case "Token-Invalid-Or-You-Do-Not-Have-Permission-In-This-Area": {
                            this.notiService.showError(this.phienhoatdonghethan, undefined, undefined);
                            break;
                        }
                        default: {
                            this.notiService.showError(this.capnhatkhongthanhcong, undefined, undefined);
                            break;
                        }
                    }
                }
            );
    }


    get f() {
        return this.addInforForm.controls;
    }

    get g() {
        return this.signAccountForm.controls;
    }

    get f1() {
        return this.addInforForm.get('personalinformation')['controls'];
    }
    get accountSmartCAForm() {
        return this.signAccountSmartCA.controls;
    }

    public resetForm() {
        this.addInforForm.reset();
        this.signAccountForm.reset();
        this.submittedForm = false;
        this.submittedForm2 = false;
    }

    public showUserProfile(title) {
        if (AppGlobals.language == 'en') {
            title = "Update information of account";
        }
        // this.myFiles = [];
        // this.myFilesId = [];
        // this.myFilesBefore = [];
        // this.myFilesBeforeId = [];
        // this.myFilesBase64 = [];
        var id = JSON.parse(localStorage.getItem('accountId'));
        this.adminApiService.getAccountByTokenKeyVirtual(id).pipe(first()).subscribe(data => {
            this.resetForm();
            this.passwordoldd = data.account.password;
            // console.log("data", data);
            this.addInforForm.controls['xacnhancapnhat'].setValue(false);
            this.addInforForm.controls['id'].patchValue(data.account.id);
            this.addInforForm.controls['status'].patchValue(1);
            this.addInforForm.controls['fullname'].patchValue(data.account.fullname);
            this.addInforForm.patchValue({ personalinformation: { id: data.personalInformation.id } });
            this.addInforForm.patchValue({ personalinformation: { email: data.personalInformation.email } });
            this.addInforForm.patchValue({ personalinformation: { phonenumber: data.personalInformation.phoneNumber } });


            this.signAccountForm.controls['username'].patchValue(data.account.usernameSign);
            this.signAccountForm.controls['password'].patchValue(data.account.passwordSign);
            this.signAccountForm.controls['clientId'].patchValue(data.account.clientIdSign);
            this.signAccountForm.controls['clientSecret'].patchValue(data.account.clientSecretSign);


            // for (var i = 0; i < index.node.children.length; i++) {
            //     var f = new File([index.node.children.id], index.node.children[i].data.name);
            //     var id = index.node.children[i].data.id;

            //     var addressImage = index.node.children[i].data.address;
            //     this.myFilesLinkFtp.push(addressImage);

            //     this.myFilesId.push(id);
            //     this.myFilesBeforeId.push(id);
            //     this.myFiles.push(f);
            //     this.myFilesBefore.push(f);
            // }

            this.showDetailDialog(title);

        },
            error => {
                this.notiService.showError(this.laydulieukhongthanhcong, undefined, undefined);
            });
        this.getListSignature();
        this.getInfoAccountSmartCA();
        this.getListSignatureSmartCA();
    }

    showMyDrive() {
        // this.displayMyDrive = true;
        // this._router.navigate(['vnpt/MyDrive']);
        // this._router.navigate(['vnpt/MyDriveNew']);
        // this._router.navigate(['vnpt/mydriveminio']);
        if (environment.option == 'ftp') {
            this._router.navigate(['vnpt/MyDriveNew']);
        } else {
            this._router.navigate(['vnpt/mydriveminio']);
        }

    }

    showDetailDialog(title) {
        this.headerTitle = title;
        this.display = true;
    }

    showDetailRoleDialog(title) {
        this.headerTitle = title;
        this.roleDisplay = true;
    }

    login() {
        this.loginService.login();
    }

    logout() {
        const accountId = JSON.parse(localStorage.getItem('accountId'));
        this.adminApiService.countUserOnlineWhenCloseTab(accountId, 0).pipe(first()).subscribe(res => { });
        this.loginService.logout();
    }

    toggleSidebarPin() {
        // this.appService.toggleSidebarPin();

        // AppGlobals.showSidebar = !this.showSidebar2;
        if (this.showSidebar2 == true) {
            this.showSidebar2 = false;
            // AppGlobals.showSidebar = this.showSidebar2;
            this.showSidebar.emit(this.showSidebar2);
        } else {
            this.showSidebar2 = true;
            // AppGlobals.showSidebar = this.showSidebar2;
            this.showSidebar.emit(this.showSidebar2);
        }


    }
    toggleSidebar() {
        this.appService.toggleSidebar();
    }


    getFileDetails(e) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var name = e.target.files[0].name;
        this.signImageName = name;
        var temp2 = name.split(".");
        var typeImage = temp2[temp2.length - 1];
        typeImage = typeImage.toLowerCase();


        if (constantsValue.array_type_image.lastIndexOf(typeImage) > -1) {
            this.myFiles.push(e.target.files[0]);
            this.myFilesId.push("");
            this.uploadfile.nativeElement.value = '';
        } else {
            alert('Vui lòng chọn file hình ảnh!');
            this.uploadfile.nativeElement.value = '';
        }


        var reader = new FileReader();

        reader.onload = this.handleReaderLoaded2.bind(this);
        reader.readAsDataURL(file);

    }
    getFileDetailsSmartCA(e) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var name = e.target.files[0].name;
        this.signImageName = name;
        var temp2 = name.split(".");
        var typeImage = temp2[temp2.length - 1];
        typeImage = typeImage.toLowerCase();


        if (constantsValue.array_type_image.lastIndexOf(typeImage) > -1) {
            this.myFilesSmartCA.push(e.target.files[0]);
            this.myFilesIdSmartCA.push("");
            this.uploadfileSmartCA.nativeElement.value = '';
        } else {
            alert('Vui lòng chọn file hình ảnh!');
            this.uploadfileSmartCA.nativeElement.value = '';
        }


        var reader = new FileReader();

        reader.onload = this.handleReaderLoadedSmartCA.bind(this);
        reader.readAsDataURL(file);

    }

    handleReaderLoaded2(e) {

        var reader = e.target;
        this.myFilesBase64.push({ value: reader.result, name: this.signImageName });
    }
    handleReaderLoadedSmartCA(e) {

        var reader = e.target;
        this.myFilesBase64SmartCA.push({ value: reader.result, name: this.signImageName });
    }

    deleteFile(index: any) {

        if (this.myFilesId[index] != "" && this.myFilesId[index] != null && this.myFilesId[index] != undefined) {
            if (confirm("Dữ liệu này đã có từ trước, Bạn có chắc muốn xóa dữ liệu này?")) {
                this.myFiles.splice(index, 1);
                this.myFilesId.splice(index, 1);
                this.myFilesLinkFtp.splice(index, 1);
                this.myFilesBase64.splice(index, 1);
                this.indextemp = -1;
            }
        } else {
            this.myFiles.splice(index, 1);
            this.myFilesId.splice(index, 1);
            this.myFilesBase64.splice(index, 1);
            this.myFilesLinkFtp.splice(index, 1);
            this.indextemp = -1;
        }

    }

    deleteFileSmartCA(index: any) {

        if (this.myFilesIdSmartCA[index] != "" && this.myFilesIdSmartCA[index] != null && this.myFilesIdSmartCA[index] != undefined) {
            if (confirm("Dữ liệu này đã có từ trước, Bạn có chắc muốn xóa dữ liệu này?")) {
                this.myFilesSmartCA.splice(index, 1);
                this.myFilesIdSmartCA.splice(index, 1);
                this.myFilesLinkFtpSmartCA.splice(index, 1);
                this.myFilesBase64SmartCA.splice(index, 1);
                this.indextempSmartCA = -1;
            }
        } else {
            this.myFilesSmartCA.splice(index, 1);
            this.myFilesIdSmartCA.splice(index, 1);
            this.myFilesBase64SmartCA.splice(index, 1);
            this.myFilesLinkFtpSmartCA.splice(index, 1);
            this.indextempSmartCA = -1;
        }

    }
    showImage(index: any) {
        // if (index === this.indextemp) {
        //   return;
        // }
        // this.imageSrc = '';
        // this.listArea = [];
        // this.isLoadingpdf = true;
        // this.indextemp = index;
        this.handleInputChange(index);
    }
    showImageSmartCA(index: any) {
        this.handleInputChangeSmartCA(index);
    }
    handleInputChange(e) {
        var file = this.myFiles[e];
        this.currentFile = this.myFiles[e];
        this.type = file.type.replace('image/', '');
        var pattern1 = /image-*/;

        const fileNameParts = file.name.split('.');
        const fileType = fileNameParts.pop().toLowerCase();


        if (this.type == '') {
            var body = this.myFilesLinkFtp[e];

            if (body.includes("ftp") == false) {
                var ftpAddress = localStorage.getItem('ftpAddress');
                body = ftpAddress + body;
            }
            var nametypetudo = body.split(".");
            var typeimage = nametypetudo[nametypetudo.length - 1];
            typeimage = typeimage.toLowerCase();

            if (typeimage == 'png' || typeimage == 'jpg' || typeimage == 'jpeg' || typeimage == 'gif' || typeimage == 'tif') {
                // this.showSpinner.next(true);
                this.hosoapiService.downloadimage(body).pipe().subscribe(data => {
                    //   this.showSpinner.next(false);
                    this.type = typeimage;
                    this.imageSrc = 'data:image/' + typeimage + ';base64,' + data.value.slice(1, -1);

                    //   var newBlob = this.base64toBlob(data.value.slice(1, -1), "image/" + typeimage);

                    //   var file = new File([newBlob], "aaa");
                    //   this.currentFile = file;

                    //   this.isLoadingpdf = false;
                    this.displayShowImage = true;
                }, err => {
                    //   this.isLoadingpdf = false;
                    //   this.showSpinner.next(false);
                });
            }
        } else {

            this.imageSrc = this.myFilesBase64[e].value;
            this.displayShowImage = true;
        }

    }
    handleInputChangeSmartCA(e) {
        var file = this.myFilesSmartCA[e];
        this.currentFile = this.myFilesSmartCA[e];
        this.type = file.type.replace('image/', '');
        var pattern1 = /image-*/;

        const fileNameParts = file.name.split('.');
        const fileType = fileNameParts.pop().toLowerCase();


        if (this.type == '') {
            var body = this.myFilesLinkFtpSmartCA[e];

            if (body.includes("ftp") == false) {
                var ftpAddress = localStorage.getItem('ftpAddress');
                body = ftpAddress + body;
            }
            var nametypetudo = body.split(".");
            var typeimage = nametypetudo[nametypetudo.length - 1];
            typeimage = typeimage.toLowerCase();

            if (typeimage == 'png' || typeimage == 'jpg' || typeimage == 'jpeg' || typeimage == 'gif' || typeimage == 'tif') {
                // this.showSpinner.next(true);
                this.hosoapiService.downloadimage(body).pipe().subscribe(data => {
                    //   this.showSpinner.next(false);
                    this.type = typeimage;
                    this.imageSrc = 'data:image/' + typeimage + ';base64,' + data.value.slice(1, -1);

                    //   var newBlob = this.base64toBlob(data.value.slice(1, -1), "image/" + typeimage);

                    //   var file = new File([newBlob], "aaa");
                    //   this.currentFile = file;

                    //   this.isLoadingpdf = false;
                    this.displayShowImage = true;
                }, err => {
                    //   this.isLoadingpdf = false;
                    //   this.showSpinner.next(false);
                });
            }
        } else {

            this.imageSrc = this.myFilesBase64SmartCA[e].value;
            this.displayShowImage = true;
        }

    }

    handleReaderLoaded(e) {
        var reader = e.target;
        this.imageSrc = reader.result;
    }

    public setUpSignAccount() {
        let submitData = null;
        this.submittedForm2 = true;

        this.errorMessage = "";
        if (this.signAccountForm.invalid) {
            return;
        }
        this.isSavingAccountSign = true;
        submitData = this.signAccountForm.value;
        delete submitData.confirmPassword;
        var dataBody = { username: submitData.username, password: this.utilityFunction.encryptedpassword(submitData.password), clientId: submitData.clientId, clientSecret: submitData.clientSecret };

        this.adminApiService.setUpSignAccount(dataBody).pipe(first()).subscribe(data => {
            this.notiService.showSuccess(this.capnhatthanhcong, undefined, undefined);

            this.isSavingAccountSign = false;

            localStorage.setItem('usernameSign', data.usernameSign);
            localStorage.setItem('passwordSign', data.passwordSign);
            localStorage.setItem('certIDSign', data.certIDSign);
        },
            error => {
                this.isSavingAccountSign = false;
                if (error.error.errors == undefined) {
                    this.errorMessage = "Thiết lập tài khoản lỗi, vui lòng nhập lại";
                    return;
                }
                var message = error.error.errors[0];
                switch (message) {
                    case "Account-Sign-Is-Not-Valid":
                        this.errorMessage = "Tài khoản không hợp lệ, vui lòng nhập lại";
                        break;
                    case "Error-When-Get-CertID":
                        this.errorMessage = "Quá trình xác nhận xảy ra vấn đề, vui lòng nhập lại";
                        break;
                    case "Account-Update-Failed":
                        this.errorMessage = "Thiết lập tài khoản lỗi, vui lòng nhập lại";
                        break;
                    default:
                        this.errorMessage = "Thiết lập tài khoản lỗi, vui lòng nhập lại";
                        break;
                }
            });
    }

    updateSignatureImageList() {
        if (this.myFiles.length > 5) {
            this.notiService.showError(this.khongtheluuqua5hinhanhkiso, undefined, undefined);
            return;
        }
        let createList = this.myFiles.filter(x => !this.myFilesBefore.includes(x));
        let deleteListId = this.myFilesBeforeId.filter(x => !this.myFilesId.includes(x));


        var formData = new FormData();
        for (var i = 0; i < createList.length; i++) {
            formData.append('createList', createList[i]);

        }
        this.appendArray(formData, deleteListId, 'listDeleteAttachment');
        const options = {
            method: 'POST',
            headers: {
                'token': localStorage.getItem('access_token'),
            },
            body: formData,
        };
        this.isSavingImageSign = true;
        fetch(this.upsertListFileSignature, options).then(result => {
            this.notiService.showSuccess(this.capnhatthanhcong, undefined, undefined);
            this.isSavingImageSign = false;
            // this.display = false;
            //this.getDetail2();
        }).catch(error => {
            this.isSavingImageSign = false;
            // this.display = false;
            this.notiService.showError(this.themmoikhongthanhcong, undefined, undefined);
        });


    }

    updateSignatureImageListSmartCA() {
        if (this.myFilesSmartCA.length > 5) {
            this.notiService.showError(this.khongtheluuqua5hinhanhkiso, undefined, undefined);
            return;
        }
        let createList = this.myFilesSmartCA.filter(x => !this.myFilesBeforeSmartCA.includes(x));
        let deleteListId = this.myFilesBeforeIdSmartCA.filter(x => !this.myFilesIdSmartCA.includes(x));


        var formData = new FormData();
        for (var i = 0; i < createList.length; i++) {
            formData.append('createList', createList[i]);

        }
        this.appendArray(formData, deleteListId, 'listDeleteAttachment');
        formData.append('type', "SmartCA");
        const options = {
            method: 'POST',
            headers: {
                'token': localStorage.getItem('access_token'),
            },
            body: formData,
        };
        this.isSavingImageSignSmartCA = true;
        fetch(this.upsertListFileSignature, options).then(result => {
            this.notiService.showSuccess(this.capnhatthanhcong, undefined, undefined);
            this.isSavingImageSignSmartCA = false;
            // this.display = false;
            //this.getDetail2();
        }).catch(error => {
            this.isSavingImageSignSmartCA = false;
            // this.display = false;
            this.notiService.showError(this.themmoikhongthanhcong, undefined, undefined);
        });


    }

    getListSignature() {
        this.myFiles = [];
        this.myFilesId = [];
        this.myFilesBefore = [];
        this.myFilesBeforeId = [];
        this.myFilesBase64 = [];
        this.adminApiService.getListSignature("").pipe(first()).subscribe(data => {

            let signatureInfors = data.signatureInfors;
            for (var i = 0; i < signatureInfors.length; i++) {
                var f = new File([signatureInfors[i].id], signatureInfors[i].name);
                var id = signatureInfors[i].id;

                // var addressImage = index.node.children[i].data.address;
                this.myFilesLinkFtp.push(signatureInfors[i].address);

                var nametypetudo = signatureInfors[i].name.split(".");
                var typeimage = nametypetudo[nametypetudo.length - 1];
                typeimage = typeimage.toLowerCase();

                this.myFilesBase64.push({ value: 'data:image/' + typeimage + ';base64,' + signatureInfors[i].base64, name: signatureInfors[i].name });


                this.myFilesId.push(id);
                this.myFilesBeforeId.push(id);
                this.myFiles.push(f);
                this.myFilesBefore.push(f);
            }

        },
            error => {

            });
    }

    getListSignatureSmartCA() {
        this.myFilesSmartCA = [];
        this.myFilesIdSmartCA = [];
        this.myFilesBeforeSmartCA = [];
        this.myFilesBeforeIdSmartCA = [];
        this.myFilesBase64SmartCA = [];
        this.adminApiService.getListSignature("SmartCA").pipe(first()).subscribe(data => {

            let signatureInfors = data.signatureInfors;
            for (var i = 0; i < signatureInfors.length; i++) {
                var f = new File([signatureInfors[i].id], signatureInfors[i].name);
                var id = signatureInfors[i].id;

                // var addressImage = index.node.children[i].data.address;
                this.myFilesLinkFtpSmartCA.push(signatureInfors[i].address);

                var nametypetudo = signatureInfors[i].name.split(".");
                var typeimage = nametypetudo[nametypetudo.length - 1];
                typeimage = typeimage.toLowerCase();

                this.myFilesBase64SmartCA.push({ value: 'data:image/' + typeimage + ';base64,' + signatureInfors[i].base64, name: signatureInfors[i].name });


                this.myFilesIdSmartCA.push(id);
                this.myFilesBeforeIdSmartCA.push(id);
                this.myFilesSmartCA.push(f);
                this.myFilesBeforeSmartCA.push(f);
            }

        },
            error => {

            });
    }


    appendArray(form_data, values, name) {
        if (!values && name)
            form_data.append(name, '');
        else {
            if (typeof values == 'object') {
                for (const key in values) {
                    if (typeof values[key] == 'object')
                        this.appendArray(form_data, values[key], name + '[' + key + ']');
                    else
                        form_data.append(name + '[' + key + ']', values[key]);
                }
            } else
                form_data.append(name, values);
        }

        return form_data;
    }

    isOpenSubMenu = false;
    scrollDistance = 1;
    throttle = 10;
    array = [].constructor(10);

    openSettingNoti(event) {
        event.stopPropagation();
        this.isOpenSubMenu = true;
    }

    closeSettingNoti(event) {
        this.isOpenSubMenu = false;
    }

    async updateViewNotification(listIdNotification) {
        // console.log("Vào hàm update rồi nè");
        this.countNotSee = 0;
        this.chatService
            .updateListNotificationStatus({ listIdNotification: listIdNotification })
            .pipe(first())
            .subscribe(data => {

            },
                err => {

                });
    }

    async readAll(event) {
        event.stopPropagation();

        let listIdNotification = [];

        this.messagesClient.forEach(element => {
            if (element.message.params.data.data.custom.status == true) {
                element.message.params.data.data.custom.status = false;
                listIdNotification.push(element.id);
            }
        });
        await this.updateViewNotification(listIdNotification);

        this.closeSettingNoti(event);
    }

    async updateNoti(event, noti) {
        event.stopPropagation();

        let objIndex = this.messagesClient.findIndex((item => item.id == noti.id));

        this.messagesClient[objIndex].message.params.data.data.custom.status = false;

        await this.updateViewNotification([noti.id]);
    }

    onScrollDown() {
        // console.log('scrolled!!');
        if (this.messagesClient.length < this.notiTotal) {
            this.currentPageNoti++;
            this.getNotification();
        }

    }

    upsertAccountSmartCA() {
        let submitData = null;
        this.submittedFormSmartCA = true;

        this.errorMessage = "";
        if (this.signAccountSmartCA.invalid) {
            return;
        }
        this.isSavingAccountSignSmartCA = true;
        submitData = this.signAccountSmartCA.value;
        var dataBody = { customEmail: submitData.username, customPass: this.utilityFunction.encryptedpassword(submitData.password), accountId: JSON.parse(localStorage.getItem('accountId')) };
        this.adminApiService.upsertAccountSmartCA(dataBody).pipe(first()).subscribe(data => {
            this.notiService.showSuccess("Đăng nhập tài khoản SmartCA thành công!", undefined, undefined);

            this.isSavingAccountSignSmartCA = false;

            localStorage.setItem('usernameSign', data.usernameSign);
            localStorage.setItem('passwordSign', data.passwordSign);
            localStorage.setItem('certIDSign', data.certIDSign);
        },
            error => {
                this.isSavingAccountSignSmartCA = false;
                // this.notiService.showError(this.capnhatkhongthanhcong, undefined, undefined);
                // if (error.error.errors == undefined) {
                //     this.errorMessage = "Thiết lập tài khoản lỗi, vui lòng nhập lại";
                //     return;
                // }
                var message = error.error.errors[0];
                switch (message) {
                    case "Login-SmartCA-Failed":
                        this.notiService.showError("Tài khoản hoặc mật khẩu SmartCA không chính xác", undefined, undefined);
                        break;
                }
                // this.notiService.showError(this.errorMessage, undefined, undefined);
            });
    }
    getInfoAccountSmartCA() {
        var id = JSON.parse(localStorage.getItem('accountId'));
        this.adminApiService.getInfoAccountSmartCA(id).pipe(first()).subscribe(res => {
            this.signAccountSmartCA.controls['username'].patchValue(res.customEmail);
        }, err => {

        });
    }
}
