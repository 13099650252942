import { Injectable } from '@angular/core';
import { FileElement } from '../components/file-explorer/model/element';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { v4 } from 'uuid';


export interface IFileService {
  add(fileElement: FileElement);
  delete(id: string);
  update(id: string, update: Partial<FileElement>);
  queryInFolder(folderId: string): Observable<FileElement[]>;
  get(id: string): FileElement;
  getListFile(bucket :string);
}

@Injectable({
  providedIn: 'root'
})
export class FileService implements IFileService {
  private map = new Map<string, FileElement>();

  constructor(private http: HttpClient) {}

  getListFile(){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/FileManager/listfile?token=${token}`);
  }

  addFileToMinio(data : any){
    const token = localStorage.getItem('access_token');
    return this.http.post<any>(`${environment.apiUrlVnptResource}/FileManager/addFile?token=${token}`,data);
  }
  deleteFileMinIO(path: string){
    const token = localStorage.getItem('access_token');
    return this.http.get<any>(`${environment.apiUrlVnptResource}/FileManager/deleteFile?token=${token}&path=${path}`);
  }

  clearMap(){
    this.map.clear();
  }

  add(fileElement: FileElement) {
    fileElement.id = v4();
    this.map.set(fileElement.id, this.clone(fileElement));
    return fileElement;
  }
  addNotId(fileElement: FileElement){
    this.map.set(fileElement.id, this.clone(fileElement));
    return fileElement;
  }

  delete(id: string) {
    this.map.delete(id);
  }

  update(id: string, update: Partial<FileElement>) {
    let element = this.map.get(id);
    element = Object.assign(element, update);
    this.map.set(element.id, element);
  }

  private querySubject: BehaviorSubject<FileElement[]>;
  queryInFolder(folderId: string) {
    const result: FileElement[] = [];
    this.map.forEach(element => {
      if (element.parent === folderId) {
        result.push(this.clone(element));
      }
    });
    if (!this.querySubject) {
      this.querySubject = new BehaviorSubject(result);
    } else {
      this.querySubject.next(result);
    }
    return this.querySubject.asObservable();
  }

  get(id: string) {
    return this.map.get(id);
  }

  clone(element: FileElement) {
    return JSON.parse(JSON.stringify(element));
  }
}

