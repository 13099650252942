import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { constantsValue } from '../const';
import { FileItem } from 'app/pages/Profile/add-profile/add-profile.component';
@Injectable({
  providedIn: 'root'
})
export class UtilityFunction {

  constructor() { }

  encryptedpassword(str_password) {
    const key = CryptoJS.enc.Utf8.parse(constantsValue.cryptoJSString);
    const iv = CryptoJS.enc.Utf8.parse(constantsValue.cryptoJSString);
    const encryptedpass = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(str_password), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encryptedpass.toString();
  }

  // Transform data from flatten array to tree node with unlimitted level
  transformData(data, parent) {
    const result = data.filter(d => {
      if (d.parent === null && d.parent === parent) {
        return true;
      }

      if (d.parent === null) {
        return false;
      }
      if (d.parent.id === parent) {
        return true;
      }

      return false;
    });

    if (!result && !result.length) {
      return null;
    }

    return result.map(item =>
      ({ data: item, children: this.transformData(data, item.id), expanded: true }));
  }
  transformDocument(data, parent): FileItem[]{    
    const result = data.filter( d => {
      if(d.parentId === parent){
        return true;
      }
      return false;
    });
    if(!result && !result.length){
      return [];
    }
    // return result.map(item1 => 
    //   ({ value: item1, name: item1.name, isDirectory:true, items: this.transformDocument(data, item1.id)}));
    return result.map( itemResult => {
      let listFile = [];
      if(itemResult.listAttacment.length>0){
        itemResult.listAttacment.forEach(element2 => {
          let fileTemp: FileItem = { name: element2.name, isDirectory: false, size: 1000, value: element2 };
          listFile.push(fileTemp);
        });
      }
      return {value: itemResult, name: itemResult.name, isDirectory: true, items: listFile.concat(this.transformDocument(data, itemResult.id))};
    });
  }

  transformDataFont(data, parent) {
    const result = data.filter(d => {
      if (d.fontStorageMenuParent === null && d.fontStorageMenuParent === parent) {
        return true;
      }

      if (d.fontStorageMenuParent === null) {
        return false;
      }
      if (d.fontStorageMenuParent.id === parent) {
        return true;
      }

      return false;
    });

    if (!result && !result.length) {
      return null;
    }

    return result.map(item =>
      ({ data: item, children: this.transformDataFont(data, item.id), expanded: true }));
  }

  transformData2(data, parent) {
    const result = data.filter(d => {
      if (d.parent === null && d.parent === parent) {
        return true;
      }

      if (d.parent === null) {
        return false;
      }
      if (d.parent.id === parent) {
        return true;
      }
      return false;
    });

    if (!result && !result.length) {
      return null;
    }

    return result.map(item =>
      ({ data: item, children: this.transformData(data, item.id), expanded: false }));
  }

  transformData3(data, parent) {
    const result = data.filter(d => {
      if (d.fontStorageMenuParent === null && d.fontStorageMenuParent === parent) {
        return true;
      }

      if (d.fontStorageMenuParent === null) {
        return false;
      }
      if (d.fontStorageMenuParent.id === parent) {
        return true;
      }
      return false;
    });

    if (!result && !result.length) {
      return null;
    }

    return result.map(item =>
      ({ data: item, children: this.transformDataFont(data, item.id), expanded: false }));
  }

  transformDataUseNode(data, parent) {
    const result = data.filter(d => {
      if (d.parent === null && d.parent === parent) {
        return true;
      }
      if (d.parent === null) {
        return false;
      }
      if (d.parent.id === parent) {
        return true;
      }
      return false;
    });

    if (!result && !result.length) {
      return null;
    }

    return result.map(item =>
      ({
        data: item,
        // expandedIcon: "fa fa-folder-open",
        // collapsedIcon: "fa fa-folder",
        // label: "Home",
        children: this.transformDataUseNode(data, item.id),
        expanded: false
      }));
  }

  DeQuy(data) {
    const result = data.filter(d => {
      if (d.data.partition == "Department") {
        if (d.data.Account.length == 0 && d.children.length == 0) {
          return false;
        }
        if (d.data.Account.length > 0) {
          d.data.Account.forEach(element => {
            d.children.push({ data: element });
          });
          return true;
        }
        if (d.children.length > 0) {
          return true;
        }
      }
      return false;
    });

    if (!result) {
      return null;
    }
    return result.map(item =>
      ({
        data: item.data,
        children: this.DeQuy(item.children),
        expanded: false
      }));
  }
}
