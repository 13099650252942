import { Injectable } from '@angular/core';

@Injectable()
export class AppGlobals {
    static showCartItem = false;
    static normal = {
        "menuitems": [
            {
                "code": "mnuHeThong",
                "name": "Hệ thống",
                "path": "",
                "iconPath": "menu-icon fa fa-desktop",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCHA1002",
                "name": "Danh Mục",
                "path": "",
                "iconPath": "menu-icon fa fa-pencil-square-o",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_TimKiem",
                "name": "Tìm Kiếm",
                "path": "",
                "iconPath": "menu-icon fa fa-bar-chart-o",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HoSo",
                "name": "Hồ Sơ",
                "path": "",
                "iconPath": "fa fa-archive",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                "createdOn": "2019-06-14T02:15:22.6343252Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnuMuonHoSo",
                "name": "Mượn hồ sơ",
                "path": "",
                "iconPath": "fa fa-exchange",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                "createdOn": "2019-06-14T02:47:51.9910513Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HeThongNhomQuyen",
                "name": "Hệ Thống Nhóm quyền",
                "path": "Systems/GroupRoleForUserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "3e57f918-0928-4e19-bfc5-5acd6eccc9e4",
                "createdOn": "2019-06-04T10:01:00.6083367Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "df",
                "name": "Quản lý cây đơn vị",
                "path": "Systems/OrganizationForUserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "26f5165c-14f8-452a-83e5-573cf0b4a0dd",
                "createdOn": "2019-05-17T07:18:24.2762051Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_QuanTriNguoiDung",
                "name": "Quản trị người dùng",
                "path": "Systems/UserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "aa835364-5bf9-461b-a166-fbc5378bf8d1",
                "createdOn": "2019-06-04T10:01:41.6087173Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ThamSoHeThong",
                "name": "Tham số hệ thống",
                "path": "Systems/ParameterForAdminLocalManagement",
                "iconPath": "",
                "description": "Tham số hệ thống ( Chỉ dành cho AdminLocal có Organization do SystemAdmin tạo ra)",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "fe3ce8e3-bfb9-4bc2-84cf-7e1c1158219e",
                "createdOn": "2019-06-14T08:08:04.78606Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomNguoiDung",
                "name": "Quản Trị Nhóm Người dùng",
                "path": "Systems/GroupManagement",
                "iconPath": "",
                "description": "Quản Trị Nhóm Người dùng",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "3a20e434-3944-478b-aa34-9f0bd734d48e",
                "createdOn": "2019-07-01T02:34:05.0591387Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_CauHinhDuyetHoSo",
                "name": "Cấu hình duyệt hồ sơ",
                "path": "Systems/ApprovalGroupManagement",
                "iconPath": "",
                "description": "Cấu hình duyệt hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d40079a4-9ca4-4f17-a6bc-950822b74b59",
                "createdOn": "2019-07-08T06:22:58.4784708Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomNguoiDuyet",
                "name": "Định nghĩa Danh Mục Riêng",
                "path": "Systems/DictionaryDefinition",
                "iconPath": "",
                "description": "Danh mục dùng chung theo từng Organization",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "04efb618-278e-45f0-8cf6-2260e9bd6411",
                "createdOn": "2019-07-31T01:57:08.9677459Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucKho",
                "name": "Danh Mục Kho Hồ Sơ",
                "path": "DanhMuc/DanhMucKho",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemMoiKhoHoSo",
                        "name": "Thêm mới Kho",
                        "description": "123",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "6217c01d-d981-4b30-91cf-b8fb293c8ba5",
                        "createdOn": "2019-05-24T09:53:24.4494468Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "mnXoaKhoHoSo",
                        "name": "Xóa Kho Hồ Sơ",
                        "description": "Command Xóa",
                        "order": 3,
                        "status": 1,
                        "partition": "Command",
                        "id": "be710650-beb8-4f96-8b33-a3ae3fd2fa37",
                        "createdOn": "2019-05-27T01:29:16.3705427Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "8d964f5b-cc50-48e1-a34d-029fd54f2ce0",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucKe",
                "name": "Danh Mục Kệ",
                "path": "DanhMuc/DanhMucKe",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "mnThemKeHoSo",
                        "name": "Thêm mới kệ hồ sơ",
                        "description": "Command thêm mới kệ hồ sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "9590c010-e85f-4669-ab2d-5e38b6d182ef",
                        "createdOn": "2019-05-27T10:16:44.9901875Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "mnSuaKeHoSo",
                        "name": "Sửa Kệ",
                        "description": "Sửa kệ hồ sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "6a8a49d1-3364-42ac-bb91-69303e6b1dae",
                        "createdOn": "2019-06-12T02:32:46.0552892Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "143ee3b6-7b15-4d3a-829e-f099cf1b3439",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucTang",
                "name": "Danh Mục Tầng",
                "path": "DanhMuc/DanhMucTang",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemTangHoSo",
                        "name": "Thêm mới tầng hồ sơ",
                        "description": "Thêm mới tầng hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "a343ac1c-ed44-47e9-a3e8-32934b17502a",
                        "createdOn": "2019-06-12T02:33:44.081596Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "cmdSuaTangHoSo",
                        "name": "Sửa Tầng Hồ Sơ",
                        "description": "Sửa Tầng Hồ Sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "b4dfb6fe-109c-4dbb-95b8-93d88c888226",
                        "createdOn": "2019-06-12T02:34:15.0705607Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d511beda-3d49-430f-8109-832e04c0814e",
                "createdOn": "2019-06-12T02:27:03.717283Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucLoaiHoSo",
                "name": "Loại Hồ Sơ",
                "path": "DanhMuc/DanhMucLoaiHoSo",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmd_ThemMoiLoaiHoSo",
                        "name": "Thêm Mới Loại Hồ Sơ",
                        "description": "Chức năng thêm mới loại hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "caa13252-1715-4d6a-bfc2-679c2aa531f0",
                        "createdOn": "2019-06-17T01:34:20.6643626Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "59e61e52-ff00-4db8-80dd-b4ee903e22a6",
                "createdOn": "2019-06-14T07:34:27.7776867Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucHop",
                "name": "Danh Mục Hộp",
                "path": "DanhMuc/DanhMucHop",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmd_ThemMoiHopHoSo",
                        "name": "Thêm mới hộp hồ sơ",
                        "description": "Thêm mới hộp hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "dac1c4c5-64af-4725-8fba-a7b8f36931fc",
                        "createdOn": "2019-06-14T06:26:27.8571595Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d3bce569-853c-457f-8e20-4b2304b64094",
                "createdOn": "2019-06-12T02:30:33.7741779Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucPhongBan",
                "name": "Danh Mục Phòng Ban",
                "path": "SystemsAdmin/DepartmentManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemMoiPhongBan",
                        "name": "Thêm Mới Phòng Ban",
                        "description": "Thêm mới phòng ban",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "0000b056-3f64-4c49-aad5-8a3cc656408c",
                        "createdOn": "2019-06-19T03:12:30.4622076Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "9de06b14-2fd6-4e75-be64-16b34a9ee329",
                "createdOn": "2019-06-18T02:24:16.1831017Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON26",
                "name": "Tìm kiếm từ gợi nhớ",
                "path": "SearchProfile/SearchByWordPharse",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "975526a2-a8ee-4583-8f37-b9eb1ea9cc32",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON27",
                "name": "Tìm Kiếm Theo Điều Kiện",
                "path": "SearchProfile/SearchByCondition",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "57b22950-957e-4670-9738-95d24be9514e",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_CapNhatHoSo",
                "name": "Cập nhật Hồ Sơ",
                "path": "Profile/Edit-profile",
                "iconPath": "",
                "description": "Chức năng cập nhật hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "35c3244a-790d-4ef9-830e-e6b19f3af1b5",
                "createdOn": "2019-07-16T03:29:59.3454799Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ImportHoSo",
                "name": "Import Hồ sơ ",
                "path": "Profile/ImportHoSo",
                "iconPath": "",
                "description": "Import hồ sơ từ file excel",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "03f1df10-22f4-46d3-8690-71132ded7c18",
                "createdOn": "2019-07-16T03:26:52.9618385Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhapMoiHoSo",
                "name": "Nhập mới hồ sơ",
                "path": "Profile/ThemHoSo",
                "iconPath": "",
                "description": "Nhập mới hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "40e3614d-bd8e-48cd-95c7-7ff9b2e6e95b",
                "createdOn": "2019-06-14T02:52:27.4443085Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_xemNhatKyHoSo",
                "name": "Nhật ký hồ sơ",
                "path": "Profile/ProfileHistory",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "c136f1c2-b027-4a4f-8d07-3804a013a837",
                "createdOn": "2019-06-14T08:14:20.5112467Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DuyetHoSo",
                "name": "Xác nhận hồ sơ Xuất Bản",
                "path": "Profile/Publish",
                "iconPath": "",
                "description": "Xác nhận hồ sơ publish lên hệ thống để khai thác",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "e59b78b9-74de-4681-95ae-a70a1016515b",
                "createdOn": "2019-06-14T02:13:33.3130359Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_chuyenKhohoso",
                "name": "Chuyển kho Hồ Sơ",
                "path": "Profile/ChuyenKhoHoSoComponent",
                "iconPath": "",
                "description": "chuyển hồ sơ kho, tầng, hợp này sang kho, tầng, hộp khác",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "799ecb68-7fea-43bf-ad53-79908786572d",
                "createdOn": "2019-06-14T08:23:05.9218647Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mn_HuyXuatBanHoSo",
                "name": "Hủy Xuất Bản Hồ Sơ",
                "path": "Profile/ProfileRevoke",
                "iconPath": "",
                "description": "Cho phép Hủy Xuất Bản Hồ Sơ sau khi thấy không hợp lệ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "6402552a-c8a0-4152-8002-992f1fba3a0e",
                "createdOn": "2019-06-27T01:52:33.636979Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "VIEWPROFILE",
                "name": "Xem hồ sơ",
                "path": "MiningProfile/ViewProfile",
                "iconPath": "",
                "description": "a",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "302c93a0-c354-4a35-8601-cbf3a1bb6774",
                "createdOn": "2019-08-16T01:47:04.0809548Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DangKyMuonHoSo",
                "name": "Lịch sử mượn hồ sơ",
                "path": "MiningProfile/ProfileRegistration",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "aaaaaa",
                        "name": "aaaaaaaa",
                        "description": "aaaaaa",
                        "order": 3,
                        "status": 0,
                        "partition": "Command",
                        "id": "0b4f7061-4561-44b8-929a-8d5c2a538cad",
                        "createdOn": "2019-07-16T09:29:20.6233199Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "qqqqqqq",
                        "name": "qqqqqqqqqq",
                        "description": "111",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "49b80f22-8deb-4360-9197-0c7227068e94",
                        "createdOn": "2019-07-17T04:24:59.3208166Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "1ecaeee3-5b3b-4bda-930f-ee5707507c0c",
                "createdOn": "2019-06-14T02:48:58.9366617Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "APPROVALMANA",
                "name": "Duyệt phiếu",
                "path": "Approval/ApprovalManagement",
                "iconPath": "",
                "description": "là duyệt chứ còn gì nữa",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "3f3bca4c-1051-4242-933f-b7a7046ca7aa",
                "createdOn": "2019-08-01T06:45:17.3830578Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucRieng",
                "name": "Danh Mục Chung",
                "path": "DanhMuc/DanhMucDinhNghia",
                "iconPath": "",
                "description": "Các danh mục định nghĩa",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "09ed17ad-1f9d-4719-b30a-abcb9a3cf3ca",
                "createdOn": "2019-10-24T09:11:51.8787212Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_KiemDuyet",
                "name": "Kiểm duyệt",
                "path": "",
                "iconPath": "fa fa-american-sign-language-interpreting",
                "description": "Chức năng kiểm duyệt xuất bản",
                "parent": null,
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                "createdOn": "2019-10-28T07:26:36.9155463Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ocr_recognition",
                "name": "Nhận dạng ký tự",
                "path": "SearchProfile/OcrRecognization",
                "iconPath": "",
                "description": "Chức năng nhận dạng ký tự",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "ef2b521c-dccc-42b8-85f6-99d45a280425",
                "createdOn": "2019-11-04T02:23:48.9994663Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhanXet",
                "name": "Nhận xét Hồ Sơ",
                "path": "Profile/NhanXetHoSo",
                "iconPath": "",
                "description": "Cho phép người dùng nhận xét comment về hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "a55281df-36e4-49f8-acbc-1dece0435d18",
                "createdOn": "2019-11-19T02:53:55.4519984Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_InbarcodeHoSo",
                "name": "In Barcode Hồ Sơ",
                "path": "Profile/InBarcode",
                "iconPath": "",
                "description": "Chức năng in barcode , QR code cho thành phần hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "8fe992a9-5810-448b-a636-25af223623ca",
                "createdOn": "2019-11-27T03:48:38.7640142Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HeThongBaoCao",
                "name": "Hệ Thống Báo Cáo",
                "path": "SystemsAdmin/Report",
                "iconPath": "",
                "description": "Danh sách các báo cáo đã đăng ký",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "a41f0985-a78a-40ef-9b40-21ace6e215a0",
                "createdOn": "2019-12-02T06:34:04.3780039Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ThietKeReport",
                "name": "Thiết kế Báo Cáo",
                "path": "SystemsAdmin/ReportDesign",
                "iconPath": "",
                "description": "Thiết kế Báo Cáo",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "8b27481c-17e9-4fa3-8529-95980d7e0eac",
                "createdOn": "2019-12-03T01:36:50.5827841Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_Báo Cáo",
                "name": "Báo Cáo",
                "path": "",
                "iconPath": "fa fa-cubes",
                "description": "Báo cáo thống kê",
                "parent": null,
                "commands": null,
                "order": 8,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                "createdOn": "2019-10-28T07:26:36.9303776Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucLoaiVanBan",
                "name": "Danh Mục Loại Văn Bản",
                "path": "DanhMuc/LoaiVanBan",
                "iconPath": "",
                "description": "Loại Văn Bản",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 8,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "dd584bd3-a8f4-4f08-98c1-966ca808391a",
                "createdOn": "2019-12-19T06:07:58.5595086Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            }
        ],
        "permissions": [
            {
                "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "3e57f918-0928-4e19-bfc5-5acd6eccc9e4",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "26f5165c-14f8-452a-83e5-573cf0b4a0dd",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "aa835364-5bf9-461b-a166-fbc5378bf8d1",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "fe3ce8e3-bfb9-4bc2-84cf-7e1c1158219e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "3a20e434-3944-478b-aa34-9f0bd734d48e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "d40079a4-9ca4-4f17-a6bc-950822b74b59",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "04efb618-278e-45f0-8cf6-2260e9bd6411",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "8d964f5b-cc50-48e1-a34d-029fd54f2ce0",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "6217c01d-d981-4b30-91cf-b8fb293c8ba5",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "be710650-beb8-4f96-8b33-a3ae3fd2fa37",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "143ee3b6-7b15-4d3a-829e-f099cf1b3439",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "9590c010-e85f-4669-ab2d-5e38b6d182ef",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "6a8a49d1-3364-42ac-bb91-69303e6b1dae",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "d511beda-3d49-430f-8109-832e04c0814e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "a343ac1c-ed44-47e9-a3e8-32934b17502a",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "b4dfb6fe-109c-4dbb-95b8-93d88c888226",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "59e61e52-ff00-4db8-80dd-b4ee903e22a6",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "caa13252-1715-4d6a-bfc2-679c2aa531f0",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "d3bce569-853c-457f-8e20-4b2304b64094",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "dac1c4c5-64af-4725-8fba-a7b8f36931fc",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "9de06b14-2fd6-4e75-be64-16b34a9ee329",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "0000b056-3f64-4c49-aad5-8a3cc656408c",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "975526a2-a8ee-4583-8f37-b9eb1ea9cc32",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "57b22950-957e-4670-9738-95d24be9514e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "35c3244a-790d-4ef9-830e-e6b19f3af1b5",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "03f1df10-22f4-46d3-8690-71132ded7c18",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "40e3614d-bd8e-48cd-95c7-7ff9b2e6e95b",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "c136f1c2-b027-4a4f-8d07-3804a013a837",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "e59b78b9-74de-4681-95ae-a70a1016515b",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "799ecb68-7fea-43bf-ad53-79908786572d",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "6402552a-c8a0-4152-8002-992f1fba3a0e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "302c93a0-c354-4a35-8601-cbf3a1bb6774",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "1ecaeee3-5b3b-4bda-930f-ee5707507c0c",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "0b4f7061-4561-44b8-929a-8d5c2a538cad",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "49b80f22-8deb-4360-9197-0c7227068e94",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "3f3bca4c-1051-4242-933f-b7a7046ca7aa",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "09ed17ad-1f9d-4719-b30a-abcb9a3cf3ca",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "ef2b521c-dccc-42b8-85f6-99d45a280425",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "a55281df-36e4-49f8-acbc-1dece0435d18",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "8fe992a9-5810-448b-a636-25af223623ca",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "a41f0985-a78a-40ef-9b40-21ace6e215a0",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "8b27481c-17e9-4fa3-8529-95980d7e0eac",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "dd584bd3-a8f4-4f08-98c1-966ca808391a",
                "code": null,
                "name": null,
                "category": null
            }
        ]
    }
    static normalEN = {
        "menuitems": [
            {
                "code": "mnuHeThong",
                "name": "System",
                "path": "",
                "iconPath": "menu-icon fa fa-desktop",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCHA1002",
                "name": "Category",
                "path": "",
                "iconPath": "menu-icon fa fa-pencil-square-o",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_TimKiem",
                "name": "Search",
                "path": "",
                "iconPath": "menu-icon fa fa-bar-chart-o",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HoSo",
                "name": "Profile",
                "path": "",
                "iconPath": "fa fa-archive",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                "createdOn": "2019-06-14T02:15:22.6343252Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnuMuonHoSo",
                "name": "Borrow the Profile",
                "path": "",
                "iconPath": "fa fa-exchange",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                "createdOn": "2019-06-14T02:47:51.9910513Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HeThongNhomQuyen",
                "name": "Role Group System",
                "path": "Systems/GroupRoleForUserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "3e57f918-0928-4e19-bfc5-5acd6eccc9e4",
                "createdOn": "2019-06-04T10:01:00.6083367Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HeThongNhomBaoCao",
                "commands": null,
                "createdBy": "00000000-0000-0000-0000-000000000000",
                "createdOn": "2019-12-12T02:51:34.5663888Z",
                "description": "Hệ Thống Nhóm Báo Cáo",
                "iconPath": "",
                "id": "cce65623-f761-4708-88ec-5c7f0303f6d1",
                "name": "Reporting Group System",
                "order": 8,
                "parent": {
                    "code": null,
                    "commands": null,
                    "createdBy": "00000000-0000-0000-0000-000000000000",
                    "createdOn": "0001-01-01T00:00:00",
                    "description": null,
                    "iconPath": null,
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "name": null,
                    "order": 0,
                    "parent": null,
                    "partition": "MenuItem",
                    "path": null,
                    "status": 0,
                    "statusAdmin": 0
                },
                "partition": "MenuItem",
                "path": "Systems/GroupReportForUserManagement",
                "status": 1,
                "statusAdmin": 0
            },
            {
                "code": "df",
                "name": "Tree management unit",
                "path": "Systems/OrganizationForUserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "26f5165c-14f8-452a-83e5-573cf0b4a0dd",
                "createdOn": "2019-05-17T07:18:24.2762051Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_QuanTriNguoiDung",
                "name": "Administration of users",
                "path": "Systems/UserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "aa835364-5bf9-461b-a166-fbc5378bf8d1",
                "createdOn": "2019-06-04T10:01:41.6087173Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ThamSoHeThong",
                "name": "System parameter",
                "path": "Systems/ParameterForAdminLocalManagement",
                "iconPath": "",
                "description": "Tham số hệ thống ( Chỉ dành cho AdminLocal có Organization do SystemAdmin tạo ra)",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "fe3ce8e3-bfb9-4bc2-84cf-7e1c1158219e",
                "createdOn": "2019-06-14T08:08:04.78606Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomNguoiDung",
                "name": "User Group Administration",
                "path": "Systems/GroupManagement",
                "iconPath": "",
                "description": "Quản Trị Nhóm Người dùng",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "3a20e434-3944-478b-aa34-9f0bd734d48e",
                "createdOn": "2019-07-01T02:34:05.0591387Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_CauHinhDuyetHoSo",
                "name": "Profile Approval configuration",
                "path": "Systems/ApprovalGroupManagement",
                "iconPath": "",
                "description": "Cấu hình duyệt hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d40079a4-9ca4-4f17-a6bc-950822b74b59",
                "createdOn": "2019-07-08T06:22:58.4784708Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomNguoiDuyet",
                "name": "Specific Category Definition",
                "path": "Systems/DictionaryDefinition",
                "iconPath": "",
                "description": "Danh mục dùng chung theo từng Organization",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "04efb618-278e-45f0-8cf6-2260e9bd6411",
                "createdOn": "2019-07-31T01:57:08.9677459Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucKho",
                "name": "Catalog Warehouse Profile",
                "path": "DanhMuc/DanhMucKho",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemMoiKhoHoSo",
                        "name": "Thêm mới Kho",
                        "description": "123",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "6217c01d-d981-4b30-91cf-b8fb293c8ba5",
                        "createdOn": "2019-05-24T09:53:24.4494468Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "mnXoaKhoHoSo",
                        "name": "Xóa Kho Hồ Sơ",
                        "description": "Command Xóa",
                        "order": 3,
                        "status": 1,
                        "partition": "Command",
                        "id": "be710650-beb8-4f96-8b33-a3ae3fd2fa37",
                        "createdOn": "2019-05-27T01:29:16.3705427Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "8d964f5b-cc50-48e1-a34d-029fd54f2ce0",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucKe",
                "name": "Category Shelf",
                "path": "DanhMuc/DanhMucKe",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "mnThemKeHoSo",
                        "name": "Thêm mới kệ hồ sơ",
                        "description": "Command thêm mới kệ hồ sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "9590c010-e85f-4669-ab2d-5e38b6d182ef",
                        "createdOn": "2019-05-27T10:16:44.9901875Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "mnSuaKeHoSo",
                        "name": "Sửa Kệ",
                        "description": "Sửa kệ hồ sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "6a8a49d1-3364-42ac-bb91-69303e6b1dae",
                        "createdOn": "2019-06-12T02:32:46.0552892Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "143ee3b6-7b15-4d3a-829e-f099cf1b3439",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucTang",
                "name": "Category Floor",
                "path": "DanhMuc/DanhMucTang",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemTangHoSo",
                        "name": "Thêm mới tầng hồ sơ",
                        "description": "Thêm mới tầng hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "a343ac1c-ed44-47e9-a3e8-32934b17502a",
                        "createdOn": "2019-06-12T02:33:44.081596Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "cmdSuaTangHoSo",
                        "name": "Sửa Tầng Hồ Sơ",
                        "description": "Sửa Tầng Hồ Sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "b4dfb6fe-109c-4dbb-95b8-93d88c888226",
                        "createdOn": "2019-06-12T02:34:15.0705607Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d511beda-3d49-430f-8109-832e04c0814e",
                "createdOn": "2019-06-12T02:27:03.717283Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucLoaiHoSo",
                "name": "Profile Type",
                "path": "DanhMuc/DanhMucLoaiHoSo",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmd_ThemMoiLoaiHoSo",
                        "name": "Thêm Mới Loại Hồ Sơ",
                        "description": "Chức năng thêm mới loại hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "caa13252-1715-4d6a-bfc2-679c2aa531f0",
                        "createdOn": "2019-06-17T01:34:20.6643626Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "59e61e52-ff00-4db8-80dd-b4ee903e22a6",
                "createdOn": "2019-06-14T07:34:27.7776867Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucHop",
                "name": "Category Box",
                "path": "DanhMuc/DanhMucHop",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmd_ThemMoiHopHoSo",
                        "name": "Thêm mới hộp hồ sơ",
                        "description": "Thêm mới hộp hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "dac1c4c5-64af-4725-8fba-a7b8f36931fc",
                        "createdOn": "2019-06-14T06:26:27.8571595Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d3bce569-853c-457f-8e20-4b2304b64094",
                "createdOn": "2019-06-12T02:30:33.7741779Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucPhongBan",
                "name": "Category of Departments",
                "path": "SystemsAdmin/DepartmentManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemMoiPhongBan",
                        "name": "Thêm Mới Phòng Ban",
                        "description": "Thêm mới phòng ban",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "0000b056-3f64-4c49-aad5-8a3cc656408c",
                        "createdOn": "2019-06-19T03:12:30.4622076Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "9de06b14-2fd6-4e75-be64-16b34a9ee329",
                "createdOn": "2019-06-18T02:24:16.1831017Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON26",
                "name": "Search for words to remember",
                "path": "SearchProfile/SearchByWordPharse",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "975526a2-a8ee-4583-8f37-b9eb1ea9cc32",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON27",
                "name": "Search By Condition",
                "path": "SearchProfile/SearchByCondition",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "57b22950-957e-4670-9738-95d24be9514e",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_CapNhatHoSo",
                "name": "Update profile",
                "path": "Profile/Edit-profile",
                "iconPath": "",
                "description": "Chức năng cập nhật hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "35c3244a-790d-4ef9-830e-e6b19f3af1b5",
                "createdOn": "2019-07-16T03:29:59.3454799Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ImportHoSo",
                "name": "Import Profile",
                "path": "Profile/ImportHoSo",
                "iconPath": "",
                "description": "Import hồ sơ từ file excel",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "03f1df10-22f4-46d3-8690-71132ded7c18",
                "createdOn": "2019-07-16T03:26:52.9618385Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhapMoiHoSo",
                "name": "Create a new profile",
                "path": "Profile/ThemHoSo",
                "iconPath": "",
                "description": "Nhập mới hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "40e3614d-bd8e-48cd-95c7-7ff9b2e6e95b",
                "createdOn": "2019-06-14T02:52:27.4443085Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_xemNhatKyHoSo",
                "name": "Log Profile",
                "path": "Profile/ProfileHistory",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "c136f1c2-b027-4a4f-8d07-3804a013a837",
                "createdOn": "2019-06-14T08:14:20.5112467Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DuyetHoSo",
                "name": "Confirm the publication Profile",
                "path": "Profile/Publish",
                "iconPath": "",
                "description": "Xác nhận hồ sơ publish lên hệ thống để khai thác",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "e59b78b9-74de-4681-95ae-a70a1016515b",
                "createdOn": "2019-06-14T02:13:33.3130359Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_chuyenKhohoso",
                "name": "Moving warehouse Profile",
                "path": "Profile/ChuyenKhoHoSoComponent",
                "iconPath": "",
                "description": "chuyển hồ sơ kho, tầng, hợp này sang kho, tầng, hộp khác",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "799ecb68-7fea-43bf-ad53-79908786572d",
                "createdOn": "2019-06-14T08:23:05.9218647Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mn_HuyXuatBanHoSo",
                "name": "Unpublish Profile",
                "path": "Profile/ProfileRevoke",
                "iconPath": "",
                "description": "Cho phép Hủy Xuất Bản Hồ Sơ sau khi thấy không hợp lệ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "6402552a-c8a0-4152-8002-992f1fba3a0e",
                "createdOn": "2019-06-27T01:52:33.636979Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "VIEWPROFILE",
                "name": "View profile",
                "path": "MiningProfile/ViewProfile",
                "iconPath": "",
                "description": "a",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "302c93a0-c354-4a35-8601-cbf3a1bb6774",
                "createdOn": "2019-08-16T01:47:04.0809548Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DangKyMuonHoSo",
                "name": "History borrowing Profile",
                "path": "MiningProfile/ProfileRegistration",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "aaaaaa",
                        "name": "aaaaaaaa",
                        "description": "aaaaaa",
                        "order": 3,
                        "status": 0,
                        "partition": "Command",
                        "id": "0b4f7061-4561-44b8-929a-8d5c2a538cad",
                        "createdOn": "2019-07-16T09:29:20.6233199Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "qqqqqqq",
                        "name": "qqqqqqqqqq",
                        "description": "111",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "49b80f22-8deb-4360-9197-0c7227068e94",
                        "createdOn": "2019-07-17T04:24:59.3208166Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "1ecaeee3-5b3b-4bda-930f-ee5707507c0c",
                "createdOn": "2019-06-14T02:48:58.9366617Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "APPROVALMANA",
                "name": "Approval",
                "path": "Approval/ApprovalManagement",
                "iconPath": "",
                "description": "là duyệt chứ còn gì nữa",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "3f3bca4c-1051-4242-933f-b7a7046ca7aa",
                "createdOn": "2019-08-01T06:45:17.3830578Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucRieng",
                "name": "General Category",
                "path": "DanhMuc/DanhMucDinhNghia",
                "iconPath": "",
                "description": "Các danh mục định nghĩa",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "09ed17ad-1f9d-4719-b30a-abcb9a3cf3ca",
                "createdOn": "2019-10-24T09:11:51.8787212Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucLoaiVanBan",
                "name": "Danh mục Loại văn bản",
                "path": "DanhMuc/LoaiVanBan",
                "iconPath": "",
                "description": "Các danh mục định nghĩa",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "09ed17ad-1f9d-4719-b30a-abcb9a3cf3ca",
                "createdOn": "2019-10-24T09:11:51.8787212Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_KiemDuyet",
                "name": "Censorship",
                "path": "",
                "iconPath": "fa fa-american-sign-language-interpreting",
                "description": "Chức năng kiểm duyệt xuất bản",
                "parent": null,
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                "createdOn": "2019-10-28T07:26:36.9155463Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ocr_recognition",
                "name": "Character recognition",
                "path": "SearchProfile/OcrRecognization",
                "iconPath": "",
                "description": "Chức năng nhận dạng ký tự",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "ef2b521c-dccc-42b8-85f6-99d45a280425",
                "createdOn": "2019-11-04T02:23:48.9994663Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhanXet",
                "name": "Comment Profile",
                "path": "Profile/NhanXetHoSo",
                "iconPath": "",
                "description": "Cho phép người dùng nhận xét comment về hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "a55281df-36e4-49f8-acbc-1dece0435d18",
                "createdOn": "2019-11-19T02:53:55.4519984Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_InbarcodeHoSo",
                "name": "Print Barcode Profile",
                "path": "Profile/InBarcode",
                "iconPath": "",
                "description": "Chức năng in barcode , QR code cho thành phần hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "8fe992a9-5810-448b-a636-25af223623ca",
                "createdOn": "2019-11-27T03:48:38.7640142Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucLoaiVanBan",
                "commands": null,
                "createdBy": "00000000-0000-0000-0000-000000000000",
                "createdOn": "2019-12-19T06:07:58.5595086Z",
                "description": "Loại Văn Bản",
                "iconPath": "",
                "id": "dd584bd3-a8f4-4f08-98c1-966ca808391a",
                "name": "Category Template Type Text",
                "order": 8,
                "parent": {
                    "code": null,
                    "commands": null,
                    "createdBy": "00000000-0000-0000-0000-000000000000",
                    "createdOn": "0001-01-01T00:00:00",
                    "description": null,
                    "iconPath": null,
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "name": null,
                    "order": 0,
                    "parent": null,
                    "partition": "MenuItem",
                    "path": null,
                    "status": 0,
                    "statusAdmin": 0
                },
                "partition": "MenuItem",
                "path": "DanhMuc/LoaiVanBan",
                "status": 1,
                "statusAdmin": 0
            }
        ],
        "permissions": [
            {
                "id": "dd584bd3-a8f4-4f08-98c1-966ca808391a",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "3e57f918-0928-4e19-bfc5-5acd6eccc9e4",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "cce65623-f761-4708-88ec-5c7f0303f6d1",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "26f5165c-14f8-452a-83e5-573cf0b4a0dd",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "aa835364-5bf9-461b-a166-fbc5378bf8d1",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "fe3ce8e3-bfb9-4bc2-84cf-7e1c1158219e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "3a20e434-3944-478b-aa34-9f0bd734d48e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "d40079a4-9ca4-4f17-a6bc-950822b74b59",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "04efb618-278e-45f0-8cf6-2260e9bd6411",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "8d964f5b-cc50-48e1-a34d-029fd54f2ce0",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "6217c01d-d981-4b30-91cf-b8fb293c8ba5",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "be710650-beb8-4f96-8b33-a3ae3fd2fa37",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "143ee3b6-7b15-4d3a-829e-f099cf1b3439",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "9590c010-e85f-4669-ab2d-5e38b6d182ef",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "6a8a49d1-3364-42ac-bb91-69303e6b1dae",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "d511beda-3d49-430f-8109-832e04c0814e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "a343ac1c-ed44-47e9-a3e8-32934b17502a",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "b4dfb6fe-109c-4dbb-95b8-93d88c888226",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "59e61e52-ff00-4db8-80dd-b4ee903e22a6",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "caa13252-1715-4d6a-bfc2-679c2aa531f0",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "d3bce569-853c-457f-8e20-4b2304b64094",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "dac1c4c5-64af-4725-8fba-a7b8f36931fc",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "9de06b14-2fd6-4e75-be64-16b34a9ee329",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "0000b056-3f64-4c49-aad5-8a3cc656408c",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "975526a2-a8ee-4583-8f37-b9eb1ea9cc32",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "57b22950-957e-4670-9738-95d24be9514e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "35c3244a-790d-4ef9-830e-e6b19f3af1b5",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "03f1df10-22f4-46d3-8690-71132ded7c18",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "40e3614d-bd8e-48cd-95c7-7ff9b2e6e95b",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "c136f1c2-b027-4a4f-8d07-3804a013a837",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "e59b78b9-74de-4681-95ae-a70a1016515b",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "799ecb68-7fea-43bf-ad53-79908786572d",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "6402552a-c8a0-4152-8002-992f1fba3a0e",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "302c93a0-c354-4a35-8601-cbf3a1bb6774",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "1ecaeee3-5b3b-4bda-930f-ee5707507c0c",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "0b4f7061-4561-44b8-929a-8d5c2a538cad",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "49b80f22-8deb-4360-9197-0c7227068e94",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "3f3bca4c-1051-4242-933f-b7a7046ca7aa",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "09ed17ad-1f9d-4719-b30a-abcb9a3cf3ca",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "ef2b521c-dccc-42b8-85f6-99d45a280425",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "a55281df-36e4-49f8-acbc-1dece0435d18",
                "code": null,
                "name": null,
                "category": null
            },
            {
                "id": "8fe992a9-5810-448b-a636-25af223623ca",
                "code": null,
                "name": null,
                "category": null
            }
        ]
    };
    static admin = {
        "menuitems": [
            {
                "code": "VIEWPROFILE",
                "name": "Xem hồ sơ",
                "path": "MiningProfile/ViewProfile",
                "iconPath": "",
                "description": "a",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "302c93a0-c354-4a35-8601-cbf3a1bb6774",
                "createdOn": "2019-08-16T01:47:04.0809548Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucKho",
                "name": "Danh Mục Kho Hồ Sơ",
                "path": "DanhMuc/DanhMucKho",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemMoiKhoHoSo",
                        "name": "Thêm mới Kho",
                        "description": "123",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "6217c01d-d981-4b30-91cf-b8fb293c8ba5",
                        "createdOn": "2019-05-24T09:53:24.4494468Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "mnXoaKhoHoSo",
                        "name": "Xóa Kho Hồ Sơ",
                        "description": "Command Xóa",
                        "order": 3,
                        "status": 1,
                        "partition": "Command",
                        "id": "be710650-beb8-4f96-8b33-a3ae3fd2fa37",
                        "createdOn": "2019-05-27T01:29:16.3705427Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "8d964f5b-cc50-48e1-a34d-029fd54f2ce0",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON26",
                "name": "Tìm kiếm từ gợi nhớ",
                "path": "SearchProfile/SearchByWordPharse",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "975526a2-a8ee-4583-8f37-b9eb1ea9cc32",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HeThongNhomQuyen",
                "name": "Hệ Thống Nhóm quyền",
                "path": "Systems/GroupRoleForUserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "3e57f918-0928-4e19-bfc5-5acd6eccc9e4",
                "createdOn": "2019-06-04T10:01:00.6083367Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            }
            ,
            {
                "code": "mnu_HeThongNhomBaoCao",
                "commands": null,
                "createdBy": "00000000-0000-0000-0000-000000000000",
                "createdOn": "2019-12-12T02:51:34.5663888Z",
                "description": "Hệ Thống Nhóm Báo Cáo",
                "iconPath": "",
                "id": "cce65623-f761-4708-88ec-5c7f0303f6d1",
                "name": "Hệ Thống Nhóm Báo Cáo",
                "order": 8,
                "parent": {
                    "code": null,
                    "commands": null,
                    "createdBy": "00000000-0000-0000-0000-000000000000",
                    "createdOn": "0001-01-01T00:00:00",
                    "description": null,
                    "iconPath": null,
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "name": null,
                    "order": 0,
                    "parent": null,
                    "partition": "MenuItem",
                    "path": null,
                    "status": 0,
                    "statusAdmin": 0
                },
                "partition": "MenuItem",
                "path": "Systems/GroupReportForUserManagement",
                "status": 1,
                "statusAdmin": 0
            },
            {
                "code": "mn_QuanTri",
                "name": "Quản Trị Website",
                "path": "",
                "iconPath": "fa fa-cogs",
                "description": "ahihi",
                "parent": null,
                "commands": [],
                "order": 1,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                "createdOn": "2019-06-04T09:32:11.8842183Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_VaiTro",
                "name": "Danh Sách Vai Trò",
                "path": "SystemsAdmin/MenuManagement",
                "iconPath": "",
                "description": "Quản lý menu hệ thống",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 1,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "7a3b7553-32cc-4390-aa63-1df2882e840b",
                "createdOn": "2019-06-04T09:35:03.7366528Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_KhaiBaoBaoCao",
                "name": "Khai báo báo cáo",
                "path": "SystemsAdmin/ReportDefinition",
                "iconPath": "",
                "description": "Chức năng cho phép khai báo các báo cáo lên hệ thống",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "2e8cb057-9fe3-4a2e-a484-1239c4eabd75",
                "createdOn": "2019-12-02T04:06:01.5357388Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomQuyen",
                "name": "Danh Sách Nhóm quyền",
                "path": "SystemsAdmin/GroupRoleManagement",
                "iconPath": "",
                "description": "Danh Sách Nhóm quyền",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 2,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "a9986a81-3c42-4f36-91e6-173f7e51f86d",
                "createdOn": "2019-06-27T02:10:14.4858587Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhapMoiHoSo",
                "name": "Nhập mới hồ sơ",
                "path": "Profile/ThemHoSo",
                "iconPath": "",
                "description": "Nhập mới hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "40e3614d-bd8e-48cd-95c7-7ff9b2e6e95b",
                "createdOn": "2019-06-14T02:52:27.4443085Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnuHeThong",
                "name": "Hệ thống",
                "path": "",
                "iconPath": "menu-icon fa fa-desktop",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON27",
                "name": "Tìm Kiếm Theo Điều Kiện",
                "path": "SearchProfile/SearchByCondition",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "57b22950-957e-4670-9738-95d24be9514e",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucKe",
                "name": "Danh Mục Kệ",
                "path": "DanhMuc/DanhMucKe",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "mnThemKeHoSo",
                        "name": "Thêm mới kệ hồ sơ",
                        "description": "Command thêm mới kệ hồ sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "9590c010-e85f-4669-ab2d-5e38b6d182ef",
                        "createdOn": "2019-05-27T10:16:44.9901875Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "mnSuaKeHoSo",
                        "name": "Sửa Kệ",
                        "description": "Sửa kệ hồ sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "6a8a49d1-3364-42ac-bb91-69303e6b1dae",
                        "createdOn": "2019-06-12T02:32:46.0552892Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "143ee3b6-7b15-4d3a-829e-f099cf1b3439",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ImportHoSo",
                "name": "Import Hồ sơ ",
                "path": "Profile/ImportHoSo",
                "iconPath": "",
                "description": "Import hồ sơ từ file excel",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "03f1df10-22f4-46d3-8690-71132ded7c18",
                "createdOn": "2019-07-16T03:26:52.9618385Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DangKyMuonHoSo",
                "name": "Lịch sử mượn hồ sơ",
                "path": "MiningProfile/ProfileRegistration",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "aaaaaa",
                        "name": "aaaaaaaa",
                        "description": "aaaaaa",
                        "order": 3,
                        "status": 0,
                        "partition": "Command",
                        "id": "0b4f7061-4561-44b8-929a-8d5c2a538cad",
                        "createdOn": "2019-07-16T09:29:20.6233199Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "qqqqqqq",
                        "name": "qqqqqqqqqq",
                        "description": "111",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "49b80f22-8deb-4360-9197-0c7227068e94",
                        "createdOn": "2019-07-17T04:24:59.3208166Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "1ecaeee3-5b3b-4bda-930f-ee5707507c0c",
                "createdOn": "2019-06-14T02:48:58.9366617Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "df",
                "name": "Quản lý cây đơn vị",
                "path": "Systems/OrganizationForUserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "26f5165c-14f8-452a-83e5-573cf0b4a0dd",
                "createdOn": "2019-05-17T07:18:24.2762051Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_CapNhatHoSo",
                "name": "Cập nhật Hồ Sơ",
                "path": "Profile/Edit-profile",
                "iconPath": "",
                "description": "Chức năng cập nhật hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "35c3244a-790d-4ef9-830e-e6b19f3af1b5",
                "createdOn": "2019-07-16T03:29:59.3454799Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HeThongBaoCao",
                "name": "Hệ Thống Báo Cáo",
                "path": "SystemsAdmin/Report",
                "iconPath": "",
                "description": "Danh sách các báo cáo đã đăng ký",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "a41f0985-a78a-40ef-9b40-21ace6e215a0",
                "createdOn": "2019-12-02T06:34:04.3780039Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhanXet",
                "name": "Nhận xét Hồ Sơ",
                "path": "Profile/NhanXetHoSo",
                "iconPath": "",
                "description": "Cho phép người dùng nhận xét comment về hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "a55281df-36e4-49f8-acbc-1dece0435d18",
                "createdOn": "2019-11-19T02:53:55.4519984Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_QuanTriNguoiDung",
                "name": "Quản trị người dùng",
                "path": "Systems/UserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "aa835364-5bf9-461b-a166-fbc5378bf8d1",
                "createdOn": "2019-06-04T10:01:41.6087173Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_xemNhatKyHoSo",
                "name": "Nhật ký hồ sơ",
                "path": "Profile/ProfileHistory",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "c136f1c2-b027-4a4f-8d07-3804a013a837",
                "createdOn": "2019-06-14T08:14:20.5112467Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCHA1002",
                "name": "Danh Mục",
                "path": "",
                "iconPath": "menu-icon fa fa-pencil-square-o",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DuyetHoSo",
                "name": "Xác nhận hồ sơ Xuất Bản",
                "path": "Profile/Publish",
                "iconPath": "",
                "description": "Xác nhận hồ sơ publish lên hệ thống để khai thác",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "e59b78b9-74de-4681-95ae-a70a1016515b",
                "createdOn": "2019-06-14T02:13:33.3130359Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucTang",
                "name": "Danh Mục Tầng",
                "path": "DanhMuc/DanhMucTang",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemTangHoSo",
                        "name": "Thêm mới tầng hồ sơ",
                        "description": "Thêm mới tầng hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "a343ac1c-ed44-47e9-a3e8-32934b17502a",
                        "createdOn": "2019-06-12T02:33:44.081596Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "cmdSuaTangHoSo",
                        "name": "Sửa Tầng Hồ Sơ",
                        "description": "Sửa Tầng Hồ Sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "b4dfb6fe-109c-4dbb-95b8-93d88c888226",
                        "createdOn": "2019-06-12T02:34:15.0705607Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d511beda-3d49-430f-8109-832e04c0814e",
                "createdOn": "2019-06-12T02:27:03.717283Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ThietKeReport",
                "name": "Thiết kế Báo Cáo",
                "path": "SystemsAdmin/ReportDesign",
                "iconPath": "",
                "description": "Thiết kế Báo Cáo",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "8b27481c-17e9-4fa3-8529-95980d7e0eac",
                "createdOn": "2019-12-03T01:36:50.5827841Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ocr_recognition",
                "name": "Nhận dạng ký tự",
                "path": "SearchProfile/OcrRecognization",
                "iconPath": "",
                "description": "Chức năng nhận dạng ký tự",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "ef2b521c-dccc-42b8-85f6-99d45a280425",
                "createdOn": "2019-11-04T02:23:48.9994663Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_QuanLyNoiSuDung",
                "name": "Quản lý Nơi sử dụng",
                "path": "SystemsAdmin/OrganizationManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "a19ee20a-ed64-449e-973b-220a96fb8298",
                "createdOn": "2019-06-04T09:53:00.208367Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ThamSo",
                "name": "Danh sách Tham số ",
                "path": "SystemsAdmin/ParameterManagement",
                "iconPath": "",
                "description": "Dành cho quản trị (SystemAdmin)",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "d3e7831d-dcfa-4d88-8762-1869bfed7afc",
                "createdOn": "2019-06-14T08:10:41.6516709Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ThamSoHeThong",
                "name": "Tham số hệ thống",
                "path": "Systems/ParameterForAdminLocalManagement",
                "iconPath": "",
                "description": "Tham số hệ thống ( Chỉ dành cho AdminLocal có Organization do SystemAdmin tạo ra)",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "fe3ce8e3-bfb9-4bc2-84cf-7e1c1158219e",
                "createdOn": "2019-06-14T08:08:04.78606Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_chuyenKhohoso",
                "name": "Chuyển kho Hồ Sơ",
                "path": "Profile/ChuyenKhoHoSoComponent",
                "iconPath": "",
                "description": "chuyển hồ sơ kho, tầng, hợp này sang kho, tầng, hộp khác",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "799ecb68-7fea-43bf-ad53-79908786572d",
                "createdOn": "2019-06-14T08:23:05.9218647Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_TimKiem",
                "name": "Tìm Kiếm",
                "path": "",
                "iconPath": "menu-icon fa fa-bar-chart-o",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucLoaiHoSo",
                "name": "Loại Hồ Sơ",
                "path": "DanhMuc/DanhMucLoaiHoSo",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmd_ThemMoiLoaiHoSo",
                        "name": "Thêm Mới Loại Hồ Sơ",
                        "description": "Chức năng thêm mới loại hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "caa13252-1715-4d6a-bfc2-679c2aa531f0",
                        "createdOn": "2019-06-17T01:34:20.6643626Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "59e61e52-ff00-4db8-80dd-b4ee903e22a6",
                "createdOn": "2019-06-14T07:34:27.7776867Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mn_HuyXuatBanHoSo",
                "name": "Hủy Xuất Bản Hồ Sơ",
                "path": "Profile/ProfileRevoke",
                "iconPath": "",
                "description": "Cho phép Hủy Xuất Bản Hồ Sơ sau khi thấy không hợp lệ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "6402552a-c8a0-4152-8002-992f1fba3a0e",
                "createdOn": "2019-06-27T01:52:33.636979Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucHop",
                "name": "Danh Mục Hộp",
                "path": "DanhMuc/DanhMucHop",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmd_ThemMoiHopHoSo",
                        "name": "Thêm mới hộp hồ sơ",
                        "description": "Thêm mới hộp hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "dac1c4c5-64af-4725-8fba-a7b8f36931fc",
                        "createdOn": "2019-06-14T06:26:27.8571595Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d3bce569-853c-457f-8e20-4b2304b64094",
                "createdOn": "2019-06-12T02:30:33.7741779Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_Workspace",
                "name": "Khai báo Server File",
                "path": "SystemsAdmin/WorkspaceManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 5,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "f6086c3c-9fdc-4d61-812a-569ab8c6f15e",
                "createdOn": "2019-06-18T02:25:38.3383004Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HoSo",
                "name": "Hồ Sơ",
                "path": "",
                "iconPath": "fa fa-archive",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                "createdOn": "2019-06-14T02:15:22.6343252Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomNguoiDung",
                "name": "Quản Trị Nhóm Người dùng",
                "path": "Systems/GroupManagement",
                "iconPath": "",
                "description": "Quản Trị Nhóm Người dùng",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "3a20e434-3944-478b-aa34-9f0bd734d48e",
                "createdOn": "2019-07-01T02:34:05.0591387Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucRieng",
                "name": "Danh Mục Chung",
                "path": "DanhMuc/DanhMucDinhNghia",
                "iconPath": "",
                "description": "Các danh mục định nghĩa",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "09ed17ad-1f9d-4719-b30a-abcb9a3cf3ca",
                "createdOn": "2019-10-24T09:11:51.8787212Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_KiemDuyet",
                "name": "Kiểm duyệt",
                "path": "",
                "iconPath": "fa fa-american-sign-language-interpreting",
                "description": "Chức năng kiểm duyệt xuất bản",
                "parent": null,
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                "createdOn": "2019-10-28T07:26:36.9155463Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_InbarcodeHoSo",
                "name": "In Barcode Hồ Sơ",
                "path": "Profile/InBarcode",
                "iconPath": "",
                "description": "Chức năng in barcode , QR code cho thành phần hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "8fe992a9-5810-448b-a636-25af223623ca",
                "createdOn": "2019-11-27T03:48:38.7640142Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DM_DonViHanhChinh",
                "name": "Danh Mục Đơn Vị Hành Chính",
                "path": "SystemsAdmin/DonViHanhChinh",
                "iconPath": "",
                "description": "Danh mục đơn vị hành chính",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "9d52aeb5-d274-4b2d-830b-d7077ebd583f",
                "createdOn": "2019-10-01T01:42:22.006618Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucPhongBan",
                "name": "Danh Mục Phòng Ban",
                "path": "SystemsAdmin/DepartmentManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemMoiPhongBan",
                        "name": "Thêm Mới Phòng Ban",
                        "description": "Thêm mới phòng ban",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "0000b056-3f64-4c49-aad5-8a3cc656408c",
                        "createdOn": "2019-06-19T03:12:30.4622076Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "9de06b14-2fd6-4e75-be64-16b34a9ee329",
                "createdOn": "2019-06-18T02:24:16.1831017Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_CauHinhDuyetHoSo",
                "name": "Cấu hình duyệt hồ sơ",
                "path": "Systems/ApprovalGroupManagement",
                "iconPath": "",
                "description": "Cấu hình duyệt hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d40079a4-9ca4-4f17-a6bc-950822b74b59",
                "createdOn": "2019-07-08T06:22:58.4784708Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnuMuonHoSo",
                "name": "Mượn hồ sơ",
                "path": "",
                "iconPath": "fa fa-exchange",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                "createdOn": "2019-06-14T02:47:51.9910513Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucDungChung",
                "name": "Danh Mục Dùng Chung ",
                "path": "SystemsAdmin/CommonDictionary",
                "iconPath": "",
                "description": "Danh mục dùng chung toàn site",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "8f7fe892-7cb3-4cce-b8af-b28b57789fe8",
                "createdOn": "2019-10-29T03:56:51.9204946Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomNguoiDuyet",
                "name": "Định nghĩa Danh Mục Riêng",
                "path": "Systems/DictionaryDefinition",
                "iconPath": "",
                "description": "Danh mục dùng chung theo từng Organization",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "04efb618-278e-45f0-8cf6-2260e9bd6411",
                "createdOn": "2019-07-31T01:57:08.9677459Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "APPROVALMANA",
                "name": "Duyệt phiếu",
                "path": "Approval/ApprovalManagement",
                "iconPath": "",
                "description": "là duyệt chứ còn gì nữa",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "3f3bca4c-1051-4242-933f-b7a7046ca7aa",
                "createdOn": "2019-08-01T06:45:17.3830578Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DinhNghiaDanhMucDungChung",
                "name": "Định nghĩa Danh Mục",
                "path": "SystemsAdmin/CommonDictionaryDefinition",
                "iconPath": "",
                "description": "Định nghĩa danh mục",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 8,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "91f26918-ed0f-4297-812b-4dc5c35ad75b",
                "createdOn": "2019-10-29T03:58:31.2475993Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_Báo Cáo",
                "name": "Báo Cáo",
                "path": "",
                "iconPath": "fa fa-cubes",
                "description": "Báo cáo thống kê",
                "parent": null,
                "commands": null,
                "order": 8,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                "createdOn": "2019-10-28T07:26:36.9303776Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON34",
                "name": "MENUCON 34",
                "path": null,
                "iconPath": null,
                "description": null,
                "parent": {
                    "code": "MENUCHA1003",
                    "name": "MENUCHA 1003",
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 3,
                    "status": 3,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "43bac58d-cf4d-43bd-8eb3-c64e7aae9d5f",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 34,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "7be3ca0d-2c0f-4391-b710-34e54133a661",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON35",
                "name": "MENUCON 35",
                "path": null,
                "iconPath": null,
                "description": null,
                "parent": {
                    "code": "MENUCHA1003",
                    "name": "MENUCHA 1003",
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 3,
                    "status": 3,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "43bac58d-cf4d-43bd-8eb3-c64e7aae9d5f",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 35,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "f81682e5-17db-4024-9662-1b4cf90b72f7",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON36",
                "name": "MENUCON 36",
                "path": null,
                "iconPath": null,
                "description": null,
                "parent": {
                    "code": "MENUCHA1003",
                    "name": "MENUCHA 1003",
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 3,
                    "status": 3,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "43bac58d-cf4d-43bd-8eb3-c64e7aae9d5f",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 36,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "105aaf4a-5ae6-4d6e-83eb-7784ea19ef0a",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhSachNhomBaoCao",
                "commands": null,
                "createdBy": "00000000-0000-0000-0000-000000000000",
                "createdOn": "2019-12-12T01:37:38.6698062Z",
                "description": "Danh sách nhóm báo cáo",
                "iconPath": "",
                "id": "44256d22-3d40-4f22-a772-fdd387c9f28a",
                "name": "DANH SÁCH NHÓM BÁO CÁO",
                "order": 9,
                "parent": {
                    "code": null,
                    "commands": null,
                    "createdBy": "00000000-0000-0000-0000-000000000000",
                    "createdOn": "0001-01-01T00:00:00",
                    "description": null,
                    "iconPath": null,
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "name": null,
                    "order": 0,
                    "parent": null,
                    "partition": "MenuItem",
                    "path": null,
                    "status": 0,
                    "statusAdmin": 0
                },
                "partition": "MenuItem",
                "path": "SystemsAdmin/GroupReportManagement",
                "status": 1,
                "statusAdmin": 0
            }
        ],
        "permissions": [
            {
                "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                "code": "mn_QuanTri",
                "name": "Quản Trị Website",
                "category": null
            },
            {
                "id": "7a3b7553-32cc-4390-aa63-1df2882e840b",
                "code": "mnu_VaiTro",
                "name": "Danh Sách Vai Trò",
                "category": null
            },
            {
                "id": "a9986a81-3c42-4f36-91e6-173f7e51f86d",
                "code": "mnu_NhomQuyen",
                "name": "Danh Sách Nhóm quyền",
                "category": null
            },
            {
                "id": "44256d22-3d40-4f22-a772-fdd387c9f28a",
                "code": "mnu_DanhSachNhomBaoCao",
                "name": "DANH SÁCH NHÓM BÁO CÁO",
                "category": null
            },
            {
                "id": "a19ee20a-ed64-449e-973b-220a96fb8298",
                "code": "mnu_QuanLyNoiSuDung",
                "name": "Quản lý Nơi sử dụng",
                "category": null
            },
            {
                "id": "d3e7831d-dcfa-4d88-8762-1869bfed7afc",
                "code": "mnu_ThamSo",
                "name": "Danh sách Tham số ",
                "category": null
            },
            {
                "id": "f6086c3c-9fdc-4d61-812a-569ab8c6f15e",
                "code": "mnu_Workspace",
                "name": "Khai báo Server File",
                "category": null
            },
            {
                "id": "9d52aeb5-d274-4b2d-830b-d7077ebd583f",
                "code": "mnu_DM_DonViHanhChinh",
                "name": "Danh Mục Đơn Vị Hành Chính",
                "category": null
            },
            {
                "id": "8f7fe892-7cb3-4cce-b8af-b28b57789fe8",
                "code": "mnu_DanhMucDungChung",
                "name": "Danh Mục Dùng Chung ",
                "category": null
            },
            {
                "id": "91f26918-ed0f-4297-812b-4dc5c35ad75b",
                "code": "mnu_DinhNghiaDanhMucDungChung",
                "name": "Định nghĩa Danh Mục",
                "category": null
            },
            {
                "id": "a41f0985-a78a-40ef-9b40-21ace6e215a0",
                "code": "mnu_HeThongBaoCao",
                "name": "Hệ Thống Báo Cáo",
                "category": null
            },
            {
                "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                "code": "mnu_Báo Cáo",
                "name": "Báo Cáo",
                "category": null
            },
            {
                "id": "8b27481c-17e9-4fa3-8529-95980d7e0eac",
                "code": "mnu_ThietKeReport",
                "name": "Thiết kế Báo Cáo",
                "category": null
            },
            {
                "id": "2e8cb057-9fe3-4a2e-a484-1239c4eabd75",
                "code": "mnu_KhaiBaoBaoCao",
                "name": "Khai báo báo cáo",
                "category": null
            }
        ]
    }
    static adminEN = {
        "menuitems": [
            {
                "code": "VIEWPROFILE",
                "name": "View profile",
                "path": "MiningProfile/ViewProfile",
                "iconPath": "",
                "description": "a",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "302c93a0-c354-4a35-8601-cbf3a1bb6774",
                "createdOn": "2019-08-16T01:47:04.0809548Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucKho",
                "name": "Catalog Warehouse Profile",
                "path": "DanhMuc/DanhMucKho",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemMoiKhoHoSo",
                        "name": "Thêm mới Kho",
                        "description": "123",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "6217c01d-d981-4b30-91cf-b8fb293c8ba5",
                        "createdOn": "2019-05-24T09:53:24.4494468Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "mnXoaKhoHoSo",
                        "name": "Xóa Kho Hồ Sơ",
                        "description": "Command Xóa",
                        "order": 3,
                        "status": 1,
                        "partition": "Command",
                        "id": "be710650-beb8-4f96-8b33-a3ae3fd2fa37",
                        "createdOn": "2019-05-27T01:29:16.3705427Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "8d964f5b-cc50-48e1-a34d-029fd54f2ce0",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON26",
                "name": "Search for words to remember",
                "path": "SearchProfile/SearchByWordPharse",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "975526a2-a8ee-4583-8f37-b9eb1ea9cc32",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HeThongNhomQuyen",
                "name": "Role Group System",
                "path": "Systems/GroupRoleForUserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "3e57f918-0928-4e19-bfc5-5acd6eccc9e4",
                "createdOn": "2019-06-04T10:01:00.6083367Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            }
            ,
            {
                "code": "mnu_HeThongNhomBaoCao",
                "commands": null,
                "createdBy": "00000000-0000-0000-0000-000000000000",
                "createdOn": "2019-12-12T02:51:34.5663888Z",
                "description": "Hệ Thống Nhóm Báo Cáo",
                "iconPath": "",
                "id": "cce65623-f761-4708-88ec-5c7f0303f6d1",
                "name": "Reporting Group System",
                "order": 8,
                "parent": {
                    "code": null,
                    "commands": null,
                    "createdBy": "00000000-0000-0000-0000-000000000000",
                    "createdOn": "0001-01-01T00:00:00",
                    "description": null,
                    "iconPath": null,
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "name": null,
                    "order": 0,
                    "parent": null,
                    "partition": "MenuItem",
                    "path": null,
                    "status": 0,
                    "statusAdmin": 0
                },
                "partition": "MenuItem",
                "path": "Systems/GroupReportForUserManagement",
                "status": 1,
                "statusAdmin": 0
            },
            {
                "code": "mn_QuanTri",
                "name": "Admin",
                "path": "",
                "iconPath": "fa fa-cogs",
                "description": "ahihi",
                "parent": null,
                "commands": [],
                "order": 1,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                "createdOn": "2019-06-04T09:32:11.8842183Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_VaiTro",
                "name": "Role List",
                "path": "SystemsAdmin/MenuManagement",
                "iconPath": "",
                "description": "Quản lý menu hệ thống",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 1,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "7a3b7553-32cc-4390-aa63-1df2882e840b",
                "createdOn": "2019-06-04T09:35:03.7366528Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_KhaiBaoBaoCao",
                "name": "Report declaration",
                "path": "SystemsAdmin/ReportDefinition",
                "iconPath": "",
                "description": "Chức năng cho phép khai báo các báo cáo lên hệ thống",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 1,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "2e8cb057-9fe3-4a2e-a484-1239c4eabd75",
                "createdOn": "2019-12-02T04:06:01.5357388Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomQuyen",
                "name": "List of Role Groups",
                "path": "SystemsAdmin/GroupRoleManagement",
                "iconPath": "",
                "description": "Danh Sách Nhóm quyền",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 2,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "a9986a81-3c42-4f36-91e6-173f7e51f86d",
                "createdOn": "2019-06-27T02:10:14.4858587Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhapMoiHoSo",
                "name": "Create a new profile",
                "path": "Profile/ThemHoSo",
                "iconPath": "",
                "description": "Nhập mới hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "40e3614d-bd8e-48cd-95c7-7ff9b2e6e95b",
                "createdOn": "2019-06-14T02:52:27.4443085Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnuHeThong",
                "name": "System",
                "path": "",
                "iconPath": "menu-icon fa fa-desktop",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON27",
                "name": "Search By Condition",
                "path": "SearchProfile/SearchByCondition",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "57b22950-957e-4670-9738-95d24be9514e",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucKe",
                "name": "Category Shelf",
                "path": "DanhMuc/DanhMucKe",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "mnThemKeHoSo",
                        "name": "Thêm mới kệ hồ sơ",
                        "description": "Command thêm mới kệ hồ sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "9590c010-e85f-4669-ab2d-5e38b6d182ef",
                        "createdOn": "2019-05-27T10:16:44.9901875Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "mnSuaKeHoSo",
                        "name": "Sửa Kệ",
                        "description": "Sửa kệ hồ sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "6a8a49d1-3364-42ac-bb91-69303e6b1dae",
                        "createdOn": "2019-06-12T02:32:46.0552892Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "143ee3b6-7b15-4d3a-829e-f099cf1b3439",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ImportHoSo",
                "name": "Import Profile",
                "path": "Profile/ImportHoSo",
                "iconPath": "",
                "description": "Import hồ sơ từ file excel",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "03f1df10-22f4-46d3-8690-71132ded7c18",
                "createdOn": "2019-07-16T03:26:52.9618385Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DangKyMuonHoSo",
                "name": "History borrowing Profile",
                "path": "MiningProfile/ProfileRegistration",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "aaaaaa",
                        "name": "aaaaaaaa",
                        "description": "aaaaaa",
                        "order": 3,
                        "status": 0,
                        "partition": "Command",
                        "id": "0b4f7061-4561-44b8-929a-8d5c2a538cad",
                        "createdOn": "2019-07-16T09:29:20.6233199Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "qqqqqqq",
                        "name": "qqqqqqqqqq",
                        "description": "111",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "49b80f22-8deb-4360-9197-0c7227068e94",
                        "createdOn": "2019-07-17T04:24:59.3208166Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "1ecaeee3-5b3b-4bda-930f-ee5707507c0c",
                "createdOn": "2019-06-14T02:48:58.9366617Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "df",
                "name": "Tree management unit",
                "path": "Systems/OrganizationForUserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "26f5165c-14f8-452a-83e5-573cf0b4a0dd",
                "createdOn": "2019-05-17T07:18:24.2762051Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_CapNhatHoSo",
                "name": "Update Profile",
                "path": "Profile/Edit-profile",
                "iconPath": "",
                "description": "Chức năng cập nhật hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "35c3244a-790d-4ef9-830e-e6b19f3af1b5",
                "createdOn": "2019-07-16T03:29:59.3454799Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HeThongBaoCao",
                "name": "Reporting System",
                "path": "SystemsAdmin/Report",
                "iconPath": "",
                "description": "Danh sách các báo cáo đã đăng ký",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 2,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "a41f0985-a78a-40ef-9b40-21ace6e215a0",
                "createdOn": "2019-12-02T06:34:04.3780039Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhanXet",
                "name": "Comment Profile",
                "path": "Profile/NhanXetHoSo",
                "iconPath": "",
                "description": "Cho phép người dùng nhận xét comment về hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "a55281df-36e4-49f8-acbc-1dece0435d18",
                "createdOn": "2019-11-19T02:53:55.4519984Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_QuanTriNguoiDung",
                "name": "Administration of users",
                "path": "Systems/UserManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "aa835364-5bf9-461b-a166-fbc5378bf8d1",
                "createdOn": "2019-06-04T10:01:41.6087173Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_xemNhatKyHoSo",
                "name": "Log Profile",
                "path": "Profile/ProfileHistory",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "c136f1c2-b027-4a4f-8d07-3804a013a837",
                "createdOn": "2019-06-14T08:14:20.5112467Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCHA1002",
                "name": "Category",
                "path": "",
                "iconPath": "menu-icon fa fa-pencil-square-o",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DuyetHoSo",
                "name": "Confirm the publication Profile",
                "path": "Profile/Publish",
                "iconPath": "",
                "description": "Xác nhận hồ sơ publish lên hệ thống để khai thác",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "e59b78b9-74de-4681-95ae-a70a1016515b",
                "createdOn": "2019-06-14T02:13:33.3130359Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucTang",
                "name": "Category Floor",
                "path": "DanhMuc/DanhMucTang",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemTangHoSo",
                        "name": "Thêm mới tầng hồ sơ",
                        "description": "Thêm mới tầng hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "a343ac1c-ed44-47e9-a3e8-32934b17502a",
                        "createdOn": "2019-06-12T02:33:44.081596Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    },
                    {
                        "code": "cmdSuaTangHoSo",
                        "name": "Sửa Tầng Hồ Sơ",
                        "description": "Sửa Tầng Hồ Sơ",
                        "order": 2,
                        "status": 1,
                        "partition": "Command",
                        "id": "b4dfb6fe-109c-4dbb-95b8-93d88c888226",
                        "createdOn": "2019-06-12T02:34:15.0705607Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 3,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d511beda-3d49-430f-8109-832e04c0814e",
                "createdOn": "2019-06-12T02:27:03.717283Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ThietKeReport",
                "name": "Report Design",
                "path": "SystemsAdmin/ReportDesign",
                "iconPath": "",
                "description": "Thiết kế Báo Cáo",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "8b27481c-17e9-4fa3-8529-95980d7e0eac",
                "createdOn": "2019-12-03T01:36:50.5827841Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ocr_recognition",
                "name": "Character recognition",
                "path": "SearchProfile/OcrRecognization",
                "iconPath": "",
                "description": "Chức năng nhận dạng ký tự",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "ef2b521c-dccc-42b8-85f6-99d45a280425",
                "createdOn": "2019-11-04T02:23:48.9994663Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_QuanLyNoiSuDung",
                "name": "Manage Place of Use",
                "path": "SystemsAdmin/OrganizationManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 3,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "a19ee20a-ed64-449e-973b-220a96fb8298",
                "createdOn": "2019-06-04T09:53:00.208367Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ThamSo",
                "name": "Parameter List",
                "path": "SystemsAdmin/ParameterManagement",
                "iconPath": "",
                "description": "Dành cho quản trị (SystemAdmin)",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "d3e7831d-dcfa-4d88-8762-1869bfed7afc",
                "createdOn": "2019-06-14T08:10:41.6516709Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_ThamSoHeThong",
                "name": "System parameter",
                "path": "Systems/ParameterForAdminLocalManagement",
                "iconPath": "",
                "description": "Tham số hệ thống ( Chỉ dành cho AdminLocal có Organization do SystemAdmin tạo ra)",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "fe3ce8e3-bfb9-4bc2-84cf-7e1c1158219e",
                "createdOn": "2019-06-14T08:08:04.78606Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_chuyenKhohoso",
                "name": "Moving warehouse Profile",
                "path": "Profile/ChuyenKhoHoSoComponent",
                "iconPath": "",
                "description": "chuyển hồ sơ kho, tầng, hợp này sang kho, tầng, hộp khác",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "799ecb68-7fea-43bf-ad53-79908786572d",
                "createdOn": "2019-06-14T08:23:05.9218647Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_TimKiem",
                "name": "Searching",
                "path": "",
                "iconPath": "menu-icon fa fa-bar-chart-o",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "b83a98f5-d242-4d7a-9608-39320f3c9810",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucLoaiHoSo",
                "name": "Type Profile",
                "path": "DanhMuc/DanhMucLoaiHoSo",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmd_ThemMoiLoaiHoSo",
                        "name": "Thêm Mới Loại Hồ Sơ",
                        "description": "Chức năng thêm mới loại hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "caa13252-1715-4d6a-bfc2-679c2aa531f0",
                        "createdOn": "2019-06-17T01:34:20.6643626Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 4,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "59e61e52-ff00-4db8-80dd-b4ee903e22a6",
                "createdOn": "2019-06-14T07:34:27.7776867Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mn_HuyXuatBanHoSo",
                "name": "Unpublish Profile",
                "path": "Profile/ProfileRevoke",
                "iconPath": "",
                "description": "Cho phép Hủy Xuất Bản Hồ Sơ sau khi thấy không hợp lệ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "6402552a-c8a0-4152-8002-992f1fba3a0e",
                "createdOn": "2019-06-27T01:52:33.636979Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucHop",
                "name": "Category Box",
                "path": "DanhMuc/DanhMucHop",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmd_ThemMoiHopHoSo",
                        "name": "Thêm mới hộp hồ sơ",
                        "description": "Thêm mới hộp hồ sơ",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "dac1c4c5-64af-4725-8fba-a7b8f36931fc",
                        "createdOn": "2019-06-14T06:26:27.8571595Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d3bce569-853c-457f-8e20-4b2304b64094",
                "createdOn": "2019-06-12T02:30:33.7741779Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_Workspace",
                "name": "Declare Server File",
                "path": "SystemsAdmin/WorkspaceManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [],
                "order": 5,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "f6086c3c-9fdc-4d61-812a-569ab8c6f15e",
                "createdOn": "2019-06-18T02:25:38.3383004Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_HoSo",
                "name": "Profile",
                "path": "",
                "iconPath": "fa fa-archive",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 5,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                "createdOn": "2019-06-14T02:15:22.6343252Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomNguoiDung",
                "name": "User Group Administration",
                "path": "Systems/GroupManagement",
                "iconPath": "",
                "description": "Quản Trị Nhóm Người dùng",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "3a20e434-3944-478b-aa34-9f0bd734d48e",
                "createdOn": "2019-07-01T02:34:05.0591387Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucRieng",
                "name": "General Category",
                "path": "DanhMuc/DanhMucDinhNghia",
                "iconPath": "",
                "description": "Các danh mục định nghĩa",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "09ed17ad-1f9d-4719-b30a-abcb9a3cf3ca",
                "createdOn": "2019-10-24T09:11:51.8787212Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_KiemDuyet",
                "name": "Censorship",
                "path": "",
                "iconPath": "fa fa-american-sign-language-interpreting",
                "description": "Chức năng kiểm duyệt xuất bản",
                "parent": null,
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "89e47a55-d67e-441b-be87-a257db6222f4",
                "createdOn": "2019-10-28T07:26:36.9155463Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_InbarcodeHoSo",
                "name": "Print Barcode Profile",
                "path": "Profile/InBarcode",
                "iconPath": "",
                "description": "Chức năng in barcode , QR code cho thành phần hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "a675db1e-73e0-4956-aebe-eaf77602c415",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "8fe992a9-5810-448b-a636-25af223623ca",
                "createdOn": "2019-11-27T03:48:38.7640142Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DM_DonViHanhChinh",
                "name": "Administrative units",
                "path": "SystemsAdmin/DonViHanhChinh",
                "iconPath": "",
                "description": "Danh mục đơn vị hành chính",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "9d52aeb5-d274-4b2d-830b-d7077ebd583f",
                "createdOn": "2019-10-01T01:42:22.006618Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucPhongBan",
                "name": "Directory of Departments",
                "path": "SystemsAdmin/DepartmentManagement",
                "iconPath": "",
                "description": "abc",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "c0300d10-e9be-49b6-a131-dc9a82adbfee",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": [
                    {
                        "code": "cmdThemMoiPhongBan",
                        "name": "Thêm Mới Phòng Ban",
                        "description": "Thêm mới phòng ban",
                        "order": 1,
                        "status": 1,
                        "partition": "Command",
                        "id": "0000b056-3f64-4c49-aad5-8a3cc656408c",
                        "createdOn": "2019-06-19T03:12:30.4622076Z",
                        "createdBy": "00000000-0000-0000-0000-000000000000"
                    }
                ],
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "9de06b14-2fd6-4e75-be64-16b34a9ee329",
                "createdOn": "2019-06-18T02:24:16.1831017Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_CauHinhDuyetHoSo",
                "name": "Profile approval configuration",
                "path": "Systems/ApprovalGroupManagement",
                "iconPath": "",
                "description": "Cấu hình duyệt hồ sơ",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 6,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "d40079a4-9ca4-4f17-a6bc-950822b74b59",
                "createdOn": "2019-07-08T06:22:58.4784708Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnuMuonHoSo",
                "name": "Borrow Profile",
                "path": "",
                "iconPath": "fa fa-exchange",
                "description": "abc",
                "parent": null,
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 1,
                "partition": "MenuItem",
                "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                "createdOn": "2019-06-14T02:47:51.9910513Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhMucDungChung",
                "name": "Shared Category",
                "path": "SystemsAdmin/CommonDictionary",
                "iconPath": "",
                "description": "Danh mục dùng chung toàn site",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "8f7fe892-7cb3-4cce-b8af-b28b57789fe8",
                "createdOn": "2019-10-29T03:56:51.9204946Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_NhomNguoiDuyet",
                "name": "Specific Category Definition",
                "path": "Systems/DictionaryDefinition",
                "iconPath": "",
                "description": "Danh mục dùng chung theo từng Organization",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "54449196-ae57-4690-bba1-4d7ae6677979",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "04efb618-278e-45f0-8cf6-2260e9bd6411",
                "createdOn": "2019-07-31T01:57:08.9677459Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "APPROVALMANA",
                "name": "Approval",
                "path": "Approval/ApprovalManagement",
                "iconPath": "",
                "description": "là duyệt chứ còn gì nữa",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "00f4c2cf-f44a-4283-880f-1a059f16ff1e",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 7,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "3f3bca4c-1051-4242-933f-b7a7046ca7aa",
                "createdOn": "2019-08-01T06:45:17.3830578Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DinhNghiaDanhMucDungChung",
                "name": "Category Definition",
                "path": "SystemsAdmin/CommonDictionaryDefinition",
                "iconPath": "",
                "description": "Định nghĩa danh mục",
                "parent": {
                    "code": null,
                    "name": null,
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 0,
                    "status": 0,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 8,
                "status": 1,
                "statusAdmin": 10,
                "partition": "MenuItem",
                "id": "91f26918-ed0f-4297-812b-4dc5c35ad75b",
                "createdOn": "2019-10-29T03:58:31.2475993Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_Báo Cáo",
                "name": "Report",
                "path": "",
                "iconPath": "fa fa-cubes",
                "description": "Báo cáo thống kê",
                "parent": null,
                "commands": null,
                "order": 8,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                "createdOn": "2019-10-28T07:26:36.9303776Z",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON34",
                "name": "MENUCON 34",
                "path": null,
                "iconPath": null,
                "description": null,
                "parent": {
                    "code": "MENUCHA1003",
                    "name": "MENUCHA 1003",
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 3,
                    "status": 3,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "43bac58d-cf4d-43bd-8eb3-c64e7aae9d5f",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 34,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "7be3ca0d-2c0f-4391-b710-34e54133a661",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON35",
                "name": "MENUCON 35",
                "path": null,
                "iconPath": null,
                "description": null,
                "parent": {
                    "code": "MENUCHA1003",
                    "name": "MENUCHA 1003",
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 3,
                    "status": 3,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "43bac58d-cf4d-43bd-8eb3-c64e7aae9d5f",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 35,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "f81682e5-17db-4024-9662-1b4cf90b72f7",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "MENUCON36",
                "name": "MENUCON 36",
                "path": null,
                "iconPath": null,
                "description": null,
                "parent": {
                    "code": "MENUCHA1003",
                    "name": "MENUCHA 1003",
                    "path": null,
                    "iconPath": null,
                    "description": null,
                    "parent": null,
                    "commands": null,
                    "order": 3,
                    "status": 3,
                    "statusAdmin": 0,
                    "partition": "MenuItem",
                    "id": "43bac58d-cf4d-43bd-8eb3-c64e7aae9d5f",
                    "createdOn": "0001-01-01T00:00:00",
                    "createdBy": "00000000-0000-0000-0000-000000000000"
                },
                "commands": null,
                "order": 36,
                "status": 1,
                "statusAdmin": 0,
                "partition": "MenuItem",
                "id": "105aaf4a-5ae6-4d6e-83eb-7784ea19ef0a",
                "createdOn": "0001-01-01T00:00:00",
                "createdBy": "00000000-0000-0000-0000-000000000000"
            },
            {
                "code": "mnu_DanhSachNhomBaoCao",
                "commands": null,
                "createdBy": "00000000-0000-0000-0000-000000000000",
                "createdOn": "2019-12-12T01:37:38.6698062Z",
                "description": "Danh sách nhóm báo cáo",
                "iconPath": "",
                "id": "44256d22-3d40-4f22-a772-fdd387c9f28a",
                "name": "REPORT LIST",
                "order": 9,
                "parent": {
                    "code": null,
                    "commands": null,
                    "createdBy": "00000000-0000-0000-0000-000000000000",
                    "createdOn": "0001-01-01T00:00:00",
                    "description": null,
                    "iconPath": null,
                    "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                    "name": null,
                    "order": 0,
                    "parent": null,
                    "partition": "MenuItem",
                    "path": null,
                    "status": 0,
                    "statusAdmin": 0
                },
                "partition": "MenuItem",
                "path": "SystemsAdmin/GroupReportManagement",
                "status": 1,
                "statusAdmin": 0
            }
        ],
        "permissions": [
            {
                "id": "2453e4a2-8fca-4ee7-b2ba-4af3434024ed",
                "code": "mn_QuanTri",
                "name": "Quản Trị Website",
                "category": null
            },
            {
                "id": "7a3b7553-32cc-4390-aa63-1df2882e840b",
                "code": "mnu_VaiTro",
                "name": "Danh Sách Vai Trò",
                "category": null
            },
            {
                "id": "a9986a81-3c42-4f36-91e6-173f7e51f86d",
                "code": "mnu_NhomQuyen",
                "name": "Danh Sách Nhóm quyền",
                "category": null
            },
            {
                "id": "44256d22-3d40-4f22-a772-fdd387c9f28a",
                "code": "mnu_DanhSachNhomBaoCao",
                "name": "DANH SÁCH NHÓM BÁO CÁO",
                "category": null
            },
            {
                "id": "a19ee20a-ed64-449e-973b-220a96fb8298",
                "code": "mnu_QuanLyNoiSuDung",
                "name": "Quản lý Nơi sử dụng",
                "category": null
            },
            {
                "id": "d3e7831d-dcfa-4d88-8762-1869bfed7afc",
                "code": "mnu_ThamSo",
                "name": "Danh sách Tham số ",
                "category": null
            },
            {
                "id": "f6086c3c-9fdc-4d61-812a-569ab8c6f15e",
                "code": "mnu_Workspace",
                "name": "Khai báo Server File",
                "category": null
            },
            {
                "id": "9d52aeb5-d274-4b2d-830b-d7077ebd583f",
                "code": "mnu_DM_DonViHanhChinh",
                "name": "Danh Mục Đơn Vị Hành Chính",
                "category": null
            },
            {
                "id": "8f7fe892-7cb3-4cce-b8af-b28b57789fe8",
                "code": "mnu_DanhMucDungChung",
                "name": "Danh Mục Dùng Chung ",
                "category": null
            },
            {
                "id": "91f26918-ed0f-4297-812b-4dc5c35ad75b",
                "code": "mnu_DinhNghiaDanhMucDungChung",
                "name": "Định nghĩa Danh Mục",
                "category": null
            },
            {
                "id": "a41f0985-a78a-40ef-9b40-21ace6e215a0",
                "code": "mnu_HeThongBaoCao",
                "name": "Hệ Thống Báo Cáo",
                "category": null
            },
            {
                "id": "ac5bdd2c-40cc-4401-870d-761aa086fb2b",
                "code": "mnu_Báo Cáo",
                "name": "Báo Cáo",
                "category": null
            },
            {
                "id": "8b27481c-17e9-4fa3-8529-95980d7e0eac",
                "code": "mnu_ThietKeReport",
                "name": "Thiết kế Báo Cáo",
                "category": null
            },
            {
                "id": "2e8cb057-9fe3-4a2e-a484-1239c4eabd75",
                "code": "mnu_KhaiBaoBaoCao",
                "name": "Khai báo báo cáo",
                "category": null
            }
        ]
    }
    static language = 'vi';
    // static showSidebar = true;
    // static liststatus = [
    //     { label: "Tất cả", value: -100 },
    //     { label: "Chờ duyệt", value: 0 },
    //     { label: "Chờ gia hạn", value: 2 },
    //     { label: "Đã duyệt", value: -1 }
    // ];
    // static liststatus2 = [
    //     { label: "All", value: -100 },
    //     { label: "Pending", value: 0 },
    //     { label: "Waiting for extension", value: 2 },
    //     { label: "Approved", value: -1 }
    // ];
    // static liststatus3 = [
    //     { label: "Tất cả", value: -100 },
    //     { label: "Chờ duyệt", value: 0 },
    //     { label: "Chờ gia hạn", value: 2 },
    //     { label: "Đã duyệt", value: -1 }
    // ];
}
