import { environment, oauth2 } from '../environments/environment';
export const constantsValue = {
  rowNav: {
    homeRowNav: 'homeRowNav',
    termPrivateRowNav: 'termPrivateRowNav',
    adminRowNav: 'adminRowNav',
    hideRowNav: 'hideRowNav',
  },
  colNav: {
    homeTermPrivateColNav: 'homeTermPrivateColNav',
    adminColNav: 'adminColNav'
  },
  adminRootPath: 'vnpt',
  redirecturl: oauth2.redirectUri,
  cryptoJSString: '8080808080808080',
  
  apiUpload: environment.apiUrlVnptResource,
  array_type_image: ['jpg', 'jpeg', 'png', 'gif', 'tif'],
  array_type_video: ['dat', 'wmv', '3g2', '3gp', '3gp2', '3gpp', 'amv', 'asf', 'avi', 'bin',
    'cue', 'divx', 'dv', 'flv', 'gxf', 'iso', 'm1v', 'm2v', 'm2t', 'm2ts', 'm4v', 'mkv', 'mov',
    'mp2', 'mp2v', 'mp4', 'mp4v', 'mpa', 'mpe', 'mpeg', 'mpeg1', 'mpeg2', 'mpeg4', 'mpg', 'mpv2',
    'mts', 'nsv', 'nuv', 'ogg', 'ogm', 'ogv', 'ogx', 'ps', 'rec', 'rm', 'rmvb', 'tod', 'ts', 'tts', 'vob', 'vro', 'webm'],
  array_type_office: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt'],
  array_type_file_import: ['jpg', 'jpeg', 'png', 'gif', 'tif','pdf','docx','txt','doc'],
  list_key_determined : ['Mục lục số', 'Đơn vị bảo quản số', 'Địa chỉ hồ sơ', 'Tiêu đề nhóm', 'Tên đơn vị bảo quản', 'Chú thích', 'Chuyên đề', 'Từ khóa','Số trang', 'Số tài liệu','Ghi chú', 'Người nhập tin']
};
