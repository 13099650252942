import { NgModule } from "@angular/core";
import { SanitizeHtmlPipe } from "app/services/pipe/santinize-html-pipe.service";
import { SanitizeUrlPipe } from "app/services/pipe/santinize-url-pipe.service";
import { DateFormatPipe, DateTimeFormatPipe } from "./date.pipe";
import { CustomPaginator } from "./CustomPaginatorConfiguration";
import { MatPaginatorIntl } from '@angular/material/paginator';
@NgModule({
    declarations: [DateFormatPipe, DateTimeFormatPipe, SanitizeHtmlPipe, SanitizeUrlPipe],
    exports: [DateFormatPipe, DateTimeFormatPipe, SanitizeHtmlPipe, SanitizeUrlPipe],
    providers: [
      { provide: MatPaginatorIntl, useValue: CustomPaginator() }
    ]
  })
  export class PipeModule {}