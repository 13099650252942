<div class="panel-header .panel-header-sm">
</div>
<div class="main-content-2">
    <div class="container-fluid">
        <div id="chart1">
            <div class="card">
                <div class="row" style="padding: 1vw 0vw 1vw 0vw; background-color: white; border-radius: 20px;">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-10 spaner-first">
                                <div class="row">
                                    <div class="col-md col-8">
                                        <div style="color: white;">Số hồ sơ</div>
                                        <a style="font-weight: bold;font-size:2vw;color: white;">{{profileNormal}}
                                            HS</a>
                                    </div>
                                    <div class="col-4 col-md-auto"
                                        style="align-items: center;display: flex;float: right;">
                                        <i class="material-icons"
                                            style="font-size: 3vw;color: white;border-radius: 100%; border: white 3px solid;padding: 0.5vw;">content_paste</i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 "></div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">

                            <div class="col-md-1"></div>
                            <div class="col-md-10 spaner-second">
                                <div class="row">
                                    <div class="col-md col-8">
                                        <div style="color: white;">Số file</div>
                                        <a style="font-weight: bold;font-size:2vw;color: white;">{{fileCount}} F</a>
                                    </div>
                                    <div class="col-4 col-md-auto"
                                        style="align-items: center;display: flex;float: right;">
                                        <i class="material-icons"
                                            style="font-size: 3vw;color: white;border-radius: 100%; border: white 3px solid;padding: 0.5vw;">description</i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1"></div>


                        </div>
                    </div>
                    <div class="col-md-3 ">
                        <div class="row">

                            <div class="col-md-1"></div>
                            <div class="col-md-10 spaner-third">
                                <div class="row">
                                    <div class="col-md col-8">
                                        <div style="color: white;">Số tài khoản</div>
                                        <a style="font-weight: bold;font-size:2vw;color: white;">{{accountNumber}}
                                            HS</a>
                                    </div>
                                    <div class="col-4 col-md-auto"
                                        style="align-items: center;display: flex;float: right;">
                                        <i class="material-icons"
                                            style="font-size: 3vw;color: white;border-radius: 100%; border: white 3px solid;padding: 0.5vw;">group</i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1"></div>

                        </div>
                    </div>
                    <div class="col-md-3 ">
                        <div class="row">

                            <div class="col-md-1"></div>
                            <div class="col-md-10 spaner-fours">
                                <div class="row">
                                    <div class="col-md col-8">
                                        <div style="color: white;">Online</div>
                                        <a style="font-weight: bold;font-size:2vw;color: white;">{{accountNumberOnline}}
                                            N</a>
                                    </div>
                                    <div class="col-4 col-md-auto"
                                        style="align-items: center;display: flex;float: right;">
                                        <i class="material-icons"
                                            style="font-size: 3vw;color: white;border-radius: 100%; border: white 3px solid;padding: 0.5vw;">person</i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1"></div>

                        </div>
                    </div>
                </div>
            </div>

            <br>
            <br>
        </div>

        <div id="chart2">
            <div class="row" id="chart2">
                <div class="col-md-6">
                    <div>
                        <div class="card">
                            <div
                                style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">
                                {{'admindashboard.dungluongdasudung'|translate}}
                            </div>
                            <div style="background-color: white;padding: 0.5vw;">
                                <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                    [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                                    [xaxis]="chartOptions.xaxis" [stroke]="chartOptions.stroke"
                                    [fill]="chartOptions.fill" [title]="chartOptions.title"
                                    [tooltip]="chartOptions.tooltip" [legend]="chartOptions.legend"></apx-chart>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div
                            style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">
                            {{'admindashboard.thongkehosonhaptheongay'|translate}}
                        </div>
                        <div style="background-color: white;padding: 0.5vw;">
                            <div style="height: 169px">
                                <canvas id="chart-create"></canvas>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="card">
                        <div
                            style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">
                            {{'admindashboard.thongkehosomuontheongay'|translate}}
                        </div>
                        <div style="background-color: white;padding: 0.5vw;">
                            <div style="position: relative;height: 169px;">
                                <canvas id="chart-borrow" width="2" height="1"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div
                                    style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">
                                    Loại hồ sơ theo đơn vị
                                </div>
                                <div style="background-color: white;padding: 0.5vw;">
                                    <div style="position: relative;height: 169px;">
                                        <canvas id="chart-category" width="2" height="1"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div
                                    style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">
                                    Kho theo đơn vị
                                </div>
                                <div style="background-color: white;padding: 0.5vw;">
                                    <div style="position: relative;height: 169px;">
                                        <canvas id="chart-warehouse" width="2" height="1"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div>
                        <div class="card">
                            <div class="row no-gutters" style="background-color: white;padding: 0.5vw;">
                                <div class="col-md-4 col-4">
                                    <div class="row">
                                        <div class="col-md-6 col-6" style="align-items: center;display: flex;">
                                            <i class="material-icons"
                                                style="font-size: 3vw;color: white;background-color: #04253a;border-radius: 100%;padding: 0.5vw;">publish</i>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <div style="color: gray;font-size: 0.7vw;">Xuất bản</div>
                                            <a style="font-weight: bold;font-size:1.25vw">{{profilePublish}} <span
                                                    style="font-size:0.7vw">HS</span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-4">
                                    <div class="row">
                                        <div class="col-md-6 col-6" style="align-items: center;display: flex;">
                                            <i class="material-icons"
                                                style="font-size: 3vw;color: white;background-color: #e1ddbf;border-radius: 100%;padding: 0.5vw;">handshake</i>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <div style="color: gray;font-size: 0.7vw;">Đang mượn</div>
                                            <a style="font-weight: bold;font-size:1.25vw">{{profileBorrowed}} <span
                                                    style="font-size:0.7vw">HS</span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-4">
                                    <div class="row">
                                        <div class="col-md-6 col-6" style="align-items: center;display: flex;">
                                            <i class="material-icons"
                                                style="font-size: 3vw;color: white;background-color: #4c837a;border-radius: 100%;padding: 0.5vw;">hourglass_top</i>
                                        </div>
                                        <div class="col-md-6 col-6">
                                            <div style="color: gray;font-size: 0.7vw;">Chờ duyệt</div>
                                            <a style="font-weight: bold;font-size:1.25vw">{{profilePending}} <span
                                                    style="font-size:0.7vw">HS</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div>
                        <div class="card">
                            <div
                                style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">
                                <!-- <span>{{'admindashboard.thongkeluotmuontranhomtheofont'|translate}}<span> -->
                                {{'admindashboard.thongkeluotmuontranhomtheofont'|translate}}

                                <!-- <mat-form-field style="width: 100%;">
                                            <mat-label>{{'accesslog.fromTime'|translate}}</mat-label>
                                            <input class=date-time readonly matInput [matDatepicker]="picker1" [(ngModel)]="date">
                                            <mat-datepicker-toggle matSuffix [for]="picker1">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>

                                        <mat-form-field style="width: 100%;">
                                            <mat-label>{{'accesslog.toTime'|translate}}</mat-label>
                                            <input class=date-time matInput [matDatepicker]="picker2" [(ngModel)]="serializedDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker2">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>

                                        <button [disabled]="isRefreshFont" mat-raised-button (click)=refreshDataFontAndFontMenu()>
                                            <span *ngIf="isRefreshFont">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </span>
                                            <mat-icon *ngIf="!isRefreshFont">refresh</mat-icon>
                                            <span *ngIf="!isRefreshFont">Cập nhật</span>
                                        </button> -->

                            </div>
                            <div style="background-color: white;padding: 0.5vw;">
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-3">
                                        <mat-form-field style="width: 100%;">
                                            <mat-label>{{'accesslog.fromTime'|translate}}</mat-label>
                                            <input readonly matInput [matDatepicker]="picker1" [(ngModel)]="date">
                                            <mat-datepicker-toggle matSuffix [for]="picker1">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3">
                                        <mat-form-field style="width: 100%;">
                                            <mat-label>{{'accesslog.toTime'|translate}}</mat-label>
                                            <input matInput [matDatepicker]="picker2" [(ngModel)]="serializedDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker2">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col-md-1"></div> -->
                                    <div class="col-md-1 button-refresh-2">
                                        <button [disabled]="isRefreshFont" mat-button color="blue" (click)=refreshDataFontAndFontMenu()>
                                            <span *ngIf="isRefreshFont">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </span>
                                            <mat-icon *ngIf="!isRefreshFont">refresh</mat-icon>
                                            <!-- <span *ngIf="!isRefreshFont">refresh</span> -->
                                        </button>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div>
    
                                <apx-chart [series]="chartOptionsFont.series" [chart]="chartOptionsFont.chart"
                                    [yaxis]="chartOptionsFont.yaxis" [xaxis]="chartOptionsFont.xaxis"
                                    [labels]="chartOptionsFont.labels" [stroke]="chartOptionsFont.stroke"
                                    [title]="chartOptionsFont.title" [dataLabels]="chartOptionsFont.dataLabels"
                                    [fill]="chartOptionsFont.fill" [tooltip]="chartOptionsFont.tooltip"></apx-chart>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <br>
            <br>
            <br>
        </div>


        <div  id="chart3" class="card">
            <div class="row" style="padding: 1vw 0vw 1vw 0vw;">
                <div class="col-md-3 ">
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-10 spaner-first">
                            <div class="row">
                                <div class="col-md">
                                    <div style="color: white;">{{'admindashboard.tsdv'|translate}}</div>
                                    <a style="font-weight: bold;font-size:2vw;color: white;">{{organizationCount}}
                                        DV</a>
                                </div>
                                <div class="col col-md-auto" style="align-items: center;display: flex;float: right;">
                                    <i class="material-icons"
                                        style="font-size: 3vw;color: white;border-radius: 100%; border: white 3px solid;padding: 0.5vw;">ac_unit</i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">

                        <div class="col-md-1"></div>
                        <div class="col-md-10 spaner-second">
                            <div class="row">
                                <div class="col-md">
                                    <div style="color: white;">{{'admindashboard.nguoidung'|translate}}</div>
                                    <a style="font-weight: bold;font-size:2vw;color: white;">{{accountCount}} N</a>
                                </div>
                                <div class="col col-md-auto" style="align-items: center;display: flex;float: right;">
                                    <i class="material-icons"
                                        style="font-size: 3vw;color: white;border-radius: 100%; border: white 3px solid;padding: 0.5vw;">group</i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1"></div>


                    </div>
                </div>
                <div class="col-md-3 ">
                    <div class="row">

                        <div class="col-md-1"></div>
                        <div class="col-md-10 spaner-third">
                            <div class="row">
                                <div class="col-md">
                                    <div style="color: white;">{{'admindashboard.soluong'|translate}}</div>
                                    <a style="font-weight: bold;font-size:2vw;color: white;">{{profileNormalAll}} HS</a>
                                </div>
                                <div class="col col-md-auto" style="align-items: center;display: flex;float: right;">
                                    <i class="material-icons"
                                        style="font-size: 3vw;color: white;border-radius: 100%; border: white 3px solid;padding: 0.5vw;">content_paste</i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1"></div>

                    </div>
                </div>
                <div class="col-md-3 ">
                    <div class="row">

                        <div class="col-md-1"></div>
                        <div class="col-md-10 spaner-fours">
                            <div class="row">
                                <div class="col-md">
                                    <div style="color: white;">Online</div>
                                    <a style="font-weight: bold;font-size:2vw;color: white;">{{accountNumberOnline}}
                                        N</a>
                                </div>
                                <div class="col col-md-auto" style="align-items: center;display: flex;float: right;">
                                    <i class="material-icons"
                                        style="font-size: 3vw;color: white;border-radius: 100%; border: white 3px solid;padding: 0.5vw;">person</i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1"></div>

                    </div>
                </div>
            </div>
        </div>
        <br>
        <br>


        <div id="chart4" class="row" >
            <div class="col-6">
                <div>
                    <div class="card">
                        <div
                            style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">
                            {{'admindashboard.thongkelichsutruycap'|translate}}
                        </div>
                        <div style="background-color: white;padding: 0.5vw;">
                            <canvas id="chart-login" width="2" height="1"></canvas>
                        </div>
                    </div>
                </div>
                <br>
                <div>
                    <div class="card">
                        <div
                            style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">

                            <span>Biểu đồ dung lượng file</span>
                            <button id="button-refresh" [disabled]="isRefresh" mat-button color="blue"
                                (click)="refreshDataUsedOfAllOrganization()">
                                <span *ngIf="isRefresh">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    Đang cập nhật ...
                                </span>
                                <mat-icon *ngIf="!isRefresh">refresh</mat-icon>
                                <span *ngIf="!isRefresh">Cập nhật dữ liệu</span>

                            </button>
                        </div>
                        <div style="background-color: white;padding: 0.5vw;">

                            <div id="chart">
                                <apx-chart [series]="chartOptionsAdmin.series" [chart]="chartOptionsAdmin.chart"
                                    [dataLabels]="chartOptionsAdmin.dataLabels"
                                    [plotOptions]="chartOptionsAdmin.plotOptions" [xaxis]="chartOptionsAdmin.xaxis"
                                    [stroke]="chartOptionsAdmin.stroke" [fill]="chartOptionsAdmin.fill"
                                    [title]="chartOptionsAdmin.title" [tooltip]="chartOptionsAdmin.tooltip"
                                    [legend]="chartOptionsAdmin.legend"></apx-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div>
                    <div class="card">
                        <div
                            style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">
                            {{'admindashboard.thongkelichsuloi'|translate}}
                        </div>
                        <div style="background-color: white;padding: 0.5vw;">
                            <canvas id="chart-error" width="2" height="1"></canvas>
                        </div>
                    </div>
                </div>
                <br>
                <div>
                    <div class="card">
                        <div
                            style="background-color: white;border-bottom: 0.2px solid rgba(128, 128, 128, 0.276);padding: 0.65vw;font-size: 1.1rem !important;font-weight: 400 !important;">
                            Thống kê tài khoản theo khu vực
                        </div>
                        <div style="background-color: white;padding: 0.5vw;">
                            <canvas id="chart-account-per-area" width="2" height="1"></canvas>
                        </div>
                    </div>
                </div>
            </div>

        </div>


</div>