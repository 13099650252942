<div class="signup__container row license-key-page">
    <div class="col-md-6 container__child signup__thumbnail">
      <div class="thumbnail__logo">

      </div>


      <div class="signup__overlay"></div>
    </div>
    <div class="col-md-6 signup__form background-form">
      <div>
        <div class="header">
           <p>Thay đổi mật khẩu tài khoản</p>
        </div>

        <div>
            <div class="description">
                <p>Mật khẩu tài khoản của bạn đã hết hạn, vui lòng thay đổi mật khẩu để tiếp tục sử dụng hệ thống</p>
            </div>

            <div class="input">
              <form [formGroup]="changePassword">
                <div class="row padding-row">
                  <input type="password" formControlName="oldPassword" class="input-change-password license-key" id="oldPassword"
                         placeholder=" Mật khẩu cũ" [ngClass]="{ 'is-invalid': submittedForm && f.oldPassword.errors }" />
                  <div *ngIf="submittedForm===true && f.oldPassword.errors" class="invalid-feedbackk">
                    <div *ngIf="f.oldPassword.errors.required===true">Vui lòng nhập mật khẩu cũ</div>
                  </div>
                </div>

                <!-- <br> -->

                <div class="row padding-row">
                  <input type="password" formControlName="newPassword" class="input-change-password license-key" id="newPassword"
                         placeholder=" Mật khẩu mới" [ngClass]="{ 'is-invalid': submittedForm && f.newPassword.errors }" />
                  <div *ngIf="submittedForm && f.newPassword.errors" class="invalid-feedbackk">
                    <div *ngIf="f.newPassword.errors.required">Vui lòng nhập mật khẩu mới</div>
                    <div *ngIf="f.newPassword.errors.pattern">Mật khẩu phải có ít nhất 8 kí tự, bao gồm ít nhất 1 chữ in hoa, 1 chữ thường, 1 số và 1 kí tự đặc biệt</div>
                    <div *ngIf="f.newPassword.errors.cannotContainSpace">Mật khẩu không được có dấu cách</div>
                  </div>
                </div>

                <!-- <br> -->

                <div class="row padding-row">

                  <input type="password" formControlName="confirmPassword" class="input-change-password license-key"
                    id="confirmPassword" placeholder=" Nhập lại mật khẩu mới"
                    [ngClass]="{ 'is-invalid': submittedForm && f.confirmPassword.errors }" />

                  <div *ngIf="submittedForm && f.confirmPassword.errors" class="invalid-feedbackk">
                    <div *ngIf="f.confirmPassword.errors.required">Vui lòng xác nhận mật khẩu mới</div>
                  </div>

                </div>

                <!-- <br> -->

                
                
                <button class="button-submit" id="submit" (click)="onChangePassword()" [disabled]="isUpdate">
                    <span *ngIf="!isUpdate">Cập nhật mật khẩu</span>
                    <span *ngIf="isUpdate">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </span>
                </button>
              </form>
                
            </div>
            <div *ngIf="submittedKey === false" class="error-validate">
              {{'licensekey.validateKeyRong'|translate}}
            </div>
        </div>

        <br>
        <div class="footer-form">
            
            <a class="button-back" (click)="goBackLogin()">{{'licensekey.quaylaitranglogin'|translate}}</a>
            <br>
            <span class="description" style="color: #172f49; bottom: 0;">
                © TRUNG TÂM VNPT IT KHU VỰC 2
            </span>
        </div>

      </div>

    </div>

</div>
