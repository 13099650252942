import { Component, OnInit, HostListener } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { MessagesService } from '../../services/messages.service';
import { constantsValue } from '../../const';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { oauth2 } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { NotificationService } from '../../services/notification.service';
import * as CryptoJS from 'crypto-js';
import { UtilityFunction } from '../../services/utility-functions';
import {AppGlobals} from '../../pages/common/app.global';
import { AdminApiService } from '../../services/admin-api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  isSinging = false;
  loginData = {
    Username: null,
    Password: null,
    Source: 'Web'
  };

  // tslint:disable-next-line: max-line-length
  constructor(private utilityFunction: UtilityFunction, private loginService: LoginService, private messageService: MessagesService, private formBuilder: FormBuilder, private noti: NotificationService , private adminApiService: AdminApiService) { 
    document.body.style.overflow = "hidden";
  }

  ngOnInit() {
    localStorage.setItem('statusLicense', undefined);
    this.messageService.changeRowNavMessage(constantsValue.rowNav.hideRowNav);
    this.messageService.changeFooterMessage('hideFooter');
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnDestroy(){
    document.body.style.overflow = "auto";
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    
    this.submitted = true;
    
    if (this.loginForm.invalid) {
      return;
    }
    this.isSinging = true;
    this.loginData.Username = this.loginForm.value.username;
    this.loginData.Password = this.utilityFunction.encryptedpassword(this.loginForm.value.password);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    this.loginService.obtainAccessToken(this.loginData, headers).pipe(first()).subscribe(data => {
      this.loginService.saveToken(data);   
    },
      error => {
        this.isSinging = false;
        
        if(error.error == undefined){
          if(AppGlobals.language == "vi"){
            this.noti.showError('Đăng nhập không hợp lệ', 'Error', undefined);
          }else {
            this.noti.showError('Invalid login', 'Error', undefined);
          }
          return;
        }
        if(error.error.errors == undefined){
          if(AppGlobals.language == "vi"){
            this.noti.showError('Đăng nhập không hợp lệ', 'Error', undefined);
          }else {
            this.noti.showError('Invalid login', 'Error', undefined);
          }
          return;
        }
        if(error.error.errors[0] == undefined){
          if(AppGlobals.language == "vi"){
            this.noti.showError('Đăng nhập không hợp lệ', 'Error', undefined);
          }else {
            this.noti.showError('Invalid login', 'Error', undefined);
          }
          return;
        }
        if(error.error.errors[0] == "Account-Not-Activated"){
          if(AppGlobals.language == "vi"){
            this.noti.showError('Tài khoản đang bị khóa, vui lòng liên hệ admin để đăng nhập', 'Error', undefined);
          }else {
            this.noti.showError('Account is locked, please contact admin to login', 'Error', undefined);
          }
          return;
        }
        if(error.error.errors[0] == "Lience-Key-Is-Out-Of-Date"){
          this.loginService.licenseNotification(error.error);
          return;
        }
        if(error.error.errors[0] == "Your-Password-Has-Expired"){
          this.loginService.expiryPasswordNotification(error.error);
          return;
        }
        if(AppGlobals.language == "vi"){
          this.noti.showError('Đăng nhập không hợp lệ', 'Error', undefined);
        }else {
          this.noti.showError('Invalid login', 'Error', undefined);
        }



        
      });
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  }

}
