<h1 mat-dialog-title>Upload a file</h1>

<mat-dialog-content>
  <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
  <input hidden (change)="fileProgress($event)" #fileInput type="file" id="file">
</mat-dialog-content>


<mat-label style="margin: 15px;">{{fileName}}</mat-label>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button [mat-dialog-close]="fileData" color="primary">OK</button>
</mat-dialog-actions>