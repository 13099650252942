import { AppGlobals } from './../../pages/common/app.global';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent, DatePipe } from '@angular/common';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
// import PerfectScrollbar from 'perfect-scrollbar';

import { Component, OnInit, HostListener, ViewChild, DoCheck, ChangeDetectorRef } from '@angular/core';
import { MessagesService } from '../../services/messages.service';
import { constantsValue } from '../../const';
import { AppService } from '../../services/app.service';
import { AdminApiService } from '../../services/admin-api.service';
import { throwIfEmpty, first } from 'rxjs/operators';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { HosoApiService } from '../../services/search-api.service';
import { NotificationService } from '../../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { DanhMucApiService } from '../../services/danhmuc-api.service';
import { UtilityFunction } from '../../services/utility-functions';

import * as $ from "jquery";
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { PhoneValidator } from 'app/helpers/phone.validator';
import { IDValidator } from 'app/helpers/ID.validator';

import { TouchedErrorStateMatcher } from 'app/helpers/touched-error-state.matcher';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})

export class AdminLayoutComponent implements OnInit, DoCheck {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  title = 'pro-dashboard-angular';
  showColNav: string;
  el: any;
  cartItem: any[] = [];
  //showCartItem = false;
  isLogin: boolean;
  userName: string;
  headerTitle: any;
  display: boolean;
  cols: { field: string; header: string; }[];
  registrationForm: any;
  cols1: { field: string; header: string; }[];
  nguoimuon: string;
  errorInfo: string;
  tenDonVi: any;
  payDate: any;
  borrowDate: any;
  nguoiPheDuyet: any[];
  displayApprovalAccount: boolean;
  ApprovalAccount: any;
  minDate = new Date();
  tempDate = new Date();
  maxDate = new Date();;
  isSaving2 = false;
  headerTitlettnd: any;
  stepTitle1 = 'Bước 1';
  stepTitle2 = 'Bước 2';
  currentLanguage: string;
  trangthai = true;
  capnhatthanhcong = "Đăng ký mượn hồ sơ thành công";
  capnhatkhongthanhcong = 'Đăng ký mượn hồ sơ không thành công';
  khongtimthaythongtin = 'Không tìm thấy thông tin người duyệt !';
  phone = new FormControl('', [Validators.required, PhoneValidator()]);
  IDNumber = new FormControl('', [Validators.required, IDValidator()]);

  listCannotBeBorrowed = [];
  listCannotBeBorrowedPaper = [];

  // Trường hợp phiếu mượn có tất cả các hồ sơ ko cần ai duyệt
  isExistanceOfApprovalAccount: boolean = true;

  displayedColumns1: string[] = ['imageFolder', 'code', 'name', 'attachments', 'documents', 'partition', 'feature'];
  displayedColumns3: string[] = ['imageFolder', 'code', 'name', 'attachments', 'documents', 'partition', 'status', 'feature'];
  displayedColumns2: string[] = ['codeProfile', 'isView', 'isDownload'];
  displayedColumns4: string[] = ['stt', 'codeProfile'];

  dataSource1 = new MatTableDataSource<any>(this.cartItem);
  dataSource2 = new MatTableDataSource<any>(this.cartItem);

  isBorrowOnline = true;
  isShowChooseBorrowMethod = true;
  enableNext: boolean = false;
  enableNext2: boolean = false;

  colunmTree: ({ field: string; header: string; class: string; } | { field: string; header: string; class?: undefined; })[];
  colunmTree1: ({ field: string; header: string; class: string; } | { field: string; header: string; class?: undefined; })[];
  treedata: any[];
  listDocumentSelect: any[] = [];
  listProfileAndDocumentSelect: any[] = [];

  phoneIsError: boolean = false;
  IDIsError: boolean = false;
  payDateIsError: number = 0;
  borrowDateIsError: number = 0;
  matcher = new TouchedErrorStateMatcher();
  isprofilencap = 0;
  constructor(private dateAdapter: DateAdapter<Date>, public location: Location, private router: Router, private appService: AppService, private adminService: AdminApiService, private messageService: MessagesService, private notiService: NotificationService,
    public ngbDateParserFormatter: NgbDateParserFormatter, private apiService: HosoApiService, private cdr: ChangeDetectorRef,
    private utilityFunction: UtilityFunction, private danhMucService: DanhMucApiService, private responsive: BreakpointObserver) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.currentLanguage = AppGlobals.language;
    const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

    if (isWindows && !document.getElementsByTagName('body')[0].classList.contains('sidebar-mini')) {

      document.getElementsByTagName('body')[0].classList.add('perfect-scrollbar-on');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('perfect-scrollbar-off');
    }
    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);
      }
    });
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      elemMainPanel.scrollTop = 0;
      elemSidebar.scrollTop = 0;
    });
    // if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    //   let ps = new PerfectScrollbar(elemMainPanel);
    //   ps = new PerfectScrollbar(elemSidebar);
    // }

    const window_width = $(window).width();
    let $sidebar = $('.sidebar');
    let $sidebar_responsive = $('body > .navbar-collapse');
    let $sidebar_img_container = $sidebar.find('.sidebar-background');


    if (window_width > 767) {
      if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
        $('.fixed-plugin .dropdown').addClass('open');
      }

    }

    $('.fixed-plugin a').click(function (event) {
      if ($(this).hasClass('switch-trigger')) {
        if (event.stopPropagation) {
          event.stopPropagation();
        }
        else if (window.event) {
          window.event.cancelBubble = true;
        }
      }
    });

    $('.fixed-plugin .badge').click(function () {
      let $full_page_background = $('.full-page-background');


      $(this).siblings().removeClass('active');
      $(this).addClass('active');

      var new_color = $(this).data('color');

      if ($sidebar.length !== 0) {
        $sidebar.attr('data-color', new_color);
      }

      if ($sidebar_responsive.length != 0) {
        $sidebar_responsive.attr('data-color', new_color);
      }
    });

    $('.fixed-plugin .img-holder').click(function () {
      let $full_page_background = $('.full-page-background');

      $(this).parent('li').siblings().removeClass('active');
      $(this).parent('li').addClass('active');


      var new_image = $(this).find("img").attr('src');

      if ($sidebar_img_container.length != 0) {
        $sidebar_img_container.fadeOut('fast', function () {
          $sidebar_img_container.css('background-image', 'url("' + new_image + '")');
          $sidebar_img_container.fadeIn('fast');
        });
      }

      if ($full_page_background.length != 0) {

        $full_page_background.fadeOut('fast', function () {
          $full_page_background.css('background-image', 'url("' + new_image + '")');
          $full_page_background.fadeIn('fast');
        });
      }

      if ($sidebar_responsive.length != 0) {
        $sidebar_responsive.css('background-image', 'url("' + new_image + '")');
      }
    });
    // this.maxDate = moment().add(10, 'days').format();
    this.maxDate.setDate(this.maxDate.getDate() + 10);
    AppGlobals.showCartItem = false;

    this.currentLanguage = AppGlobals.language;
    if (AppGlobals.language == "en") {
      this.capnhatthanhcong = "Update successful";
      this.capnhatkhongthanhcong = 'Update Failed';
      this.khongtimthaythongtin = 'No approval information found!';
    }
    this.cols = [
      { field: 'code', header: 'Mã hồ sơ' },
      { field: 'name', header: 'Tiêu đề' },
      { field: '', header: 'Chức năng' }
    ];
    this.colunmTree = [{ field: 'name', header: 'Tên hồ sơ' },
    { field: 'documentName', header: 'Tên thành phần' },
    { field: 'status', header: 'Trạng thái' }];
    this.colunmTree1 = [
      // { field: 'STT', header: 'STT' },
      { field: 'name', header: 'Tên hồ sơ' },
      { field: 'status', header: 'Trạng thái' },
      { field: 'thaotac', header: 'Thao tác' }
    ];
    this.cols1 = [
      { field: 'code', header: 'Mã hồ sơ' },
      { field: 'name', header: 'Tiêu đề' },
      { field: 'isView', header: 'Xem' },
      { field: 'isDownload', header: 'Download' }
    ];
    this.registrationForm = {
      payDate: this.maxDate,
      //payDate: null,
      purposeExploitation: null,
      recommendedContent: null,
      borrowDate: this.minDate,
      borrowProfiles: []
    };
    this.ApprovalAccount = {
      code: null,
      name: null,
      phoneNumber: null,
      cmnd: null,
      workingAt: null
    };
    this.displayApprovalAccount = false;

    this.messageService.changeColNavMessage(constantsValue.colNav.adminColNav);
    this.messageService.changeRowNavMessage(constantsValue.rowNav.adminRowNav);
    this.messageService.changeFooterMessage('footer');
    this.messageService.currentColNavMessageMessage.subscribe(message => this.showColNav = message);
    this.messageService.currentCartItem.subscribe(message => this.cartItem = message);
    this.messageService.currentLoginMessageMessage.subscribe(message => this.isLogin = message);



    this.userName = localStorage.getItem('user_name');
    if (this.userName !== 'SystemAdmin') {

      this.add2Cart();
      this.getAccountStatusFromStorage();
    }

    this.nguoimuon = localStorage.getItem('full_name');
    this.errorInfo = " ";
    this.tenDonVi = JSON.parse(localStorage.getItem('organization')).name;

    this.headerTitlettnd = 'Thông tin người duyệt';
    this.responsive.observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait, Breakpoints.WebLandscape, Breakpoints.WebPortrait]).subscribe(result => {
      const breakpoints = result.breakpoints;
      if (breakpoints[Breakpoints.HandsetLandscape] || breakpoints[Breakpoints.HandsetPortrait]) {
        // console.log('Mobile');
        const box = document.getElementById('main-panel');
        box.style.width = '-webkit-fill-available';
        const selectSidebar = document.getElementById('sidebarCustom');
        selectSidebar.style.display = 'none';

        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('nav-open');
        const toggle = document.getElementsByClassName('navbar-toggle')[0];
        toggle.classList.remove('toggled');
      }
      if (breakpoints[Breakpoints.WebLandscape] || breakpoints[Breakpoints.WebPortrait]) {
        // console.log('Web');
        const box = document.getElementById('main-panel');
        box.style.width = '';
        const selectSidebar = document.getElementById('sidebarCustom');
        selectSidebar.style.display = '';
      }

    });
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event) {
  //   const id = JSON.parse(localStorage.getItem('accountId'));
  //   if (id != null) {
  //     this.adminService.countUserOnlineWhenCloseTab(id, 0).pipe(first()).subscribe(result => {

  //     });
  //   } else {
  //     this.adminService.countUserOnlineWhenCloseTab('00000000-0000-0000-0000-000000000000', 0).pipe(first()).subscribe(result => {

  //     });
  //   }

  // }

  countUserOnlineWhenCloseTab() {

    this.adminService.countUserOnlineWhenCloseTab('00000000-0000-0000-0000-000000000000', 0).pipe(first()).subscribe(result => {

    })
  }

  ngAfterViewInit() {
    this.runOnRouteChange();

  }
  isMaps(path) {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1);
    if (path == titlee) {
      return false;
    }
    else {
      return true;
    }
  }
  runOnRouteChange(): void {
    // if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
    //   const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    //   const ps = new PerfectScrollbar(elemMainPanel);
    //   ps.update();
    // }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  getLanguage(value) {
    this.currentLanguage = value;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }


  getshowSidebar(value) {
    this.trangthai = value;
  }
  deleteCartItem(item) {
    for (const [i, value] of this.cartItem.entries()) {
      if (value.id === item.id) {
        this.cartItem.splice(i, 1);
        localStorage.setItem('cart_item', JSON.stringify(this.cartItem));
        this.messageService.changeCartItem(this.cartItem);
        this.isExistanceOfApprovalAccount = this.checkBorrowRegistrationForm();
        this.dataSource1.data = this.cartItem;
        this.dataSource2.data = this.cartItem;

        break;
      }
    }

    this.checkEnableNext();
  }

  deleteCartItem2(item) {
    for (const [i, value] of this.treedata.entries()) {
      if (value.data.id === item.id) {
        let a = [...this.treedata];
        a.splice(i, 1);
        this.treedata = a;
        this.cartItem.splice(i, 1);
        localStorage.setItem('cart_item', JSON.stringify(this.cartItem));
        this.messageService.changeCartItem(this.cartItem);
        this.isExistanceOfApprovalAccount = this.checkBorrowRegistrationForm();
        // this.dataSource1.data = this.cartItem;
        // this.dataSource2.data = this.cartItem;
        break;
      }
    }
    this.listDocumentSelect = []; //Tam xoa di do model bi reset lai
    this.checkEnableNext2();
  }

  checkBorrowRegistrationForm() {
    let flag = 0;
    if (this.cartItem.length == 0) {
      return false;
    }
    for (let element of this.cartItem) {
      if (element.category.isBorrow === 0) {
        flag = 1;
        break;
      }
    }
    return (flag == 1);
  }

  showDetailDialog(title) {
    this.listDocumentSelect = [];
    this.listProfileAndDocumentSelect = []
    this.isShowChooseBorrowMethod = true;

    this.isExistanceOfApprovalAccount = this.checkBorrowRegistrationForm();

    if (this.cartItem.length == 0) {
      this.errorInfo = "Phiếu vẫn chưa có bất kỳ hồ sơ nào, vui lòng chọn hồ sơ.";
    }

    let listIDProfile = [];
    for (var i = 0; i < this.cartItem.length; i++) {
      this.cartItem[i].isView = true;

      this.cartItem[i].isDownload = false;
      listIDProfile.push(this.cartItem[i].id);
    }
    this.dataSource1.data = this.cartItem;
    this.dataSource2.data = this.cartItem;
    this.errorInfo = "";
    if (AppGlobals.language == 'vi') {
      this.headerTitle = 'Đăng ký mượn hồ sơ';
    } else {
      this.headerTitle = 'Register to borrow profile'
      this.stepTitle1 = 'Step 1';
      this.stepTitle2 = 'Step 2';
    }
    let listTreeOrigin = [];
    this.cartItem.forEach(element => {
      element.parent = null;
      this.isprofilencap = element.styleProfile
      listTreeOrigin.push(element);
    });
    this.danhMucService.getDocumentbylistProfile(listIDProfile).pipe(first()).subscribe(dataResult => {

      dataResult.forEach(element => {
        element.parent = {};
        element.parent.id = element.profileId;
        listTreeOrigin.push(element);
      });
      this.treedata = this.utilityFunction.transformData(listTreeOrigin, null);
    });

    this.display = true;
  }

  createBorrowProfile() {
    //error info
    this.checkValidBorrowDate();
    this.checkValidPaymentDate();
    if (this.CheckError() === false) return;

    if (this.payDateIsError != 0 || this.borrowDateIsError != 0) {
      return;
    }

    if (this.listDocumentSelect.length < 1 && this.listProfileAndDocumentSelect.length < 1) {
      this.notiService.showSuccess("Cần chọn ít nhất một thành phần hồ sơ để đăng ký mượn.", undefined, undefined);
      return;
    }
    this.isSaving2 = true;

    // this.registrationForm.borrowProfiles = this.cartItem;
    var listBorrowProfiles = [];
    this.listProfileAndDocumentSelect.forEach(element => {
      if (element.parent == null) {
        let checkExist = this.listDocumentSelect.find(item => item.profileId == element.data.id);
        let checkExist2 = listBorrowProfiles.find(item => item.id == element.id);
        if (checkExist != undefined && checkExist2 == undefined) {
          listBorrowProfiles.push(element.data);
        }
      }
    });
    if (this.listDocumentSelect.length < 1) {
      this.listProfileAndDocumentSelect.forEach(element => {
        listBorrowProfiles.push(element.data);
      });

      this.registrationForm.type = 1;
    }
    this.registrationForm.borrowProfiles = listBorrowProfiles;

    this.registrationForm.organization = JSON.parse(localStorage.getItem('organization'));
    if (this.isExistanceOfApprovalAccount === false) {
      this.registrationForm.approvedAccount = null;
    }
    this.registrationForm.borrowDocuments = this.listDocumentSelect;
    this.adminService.postDataByUrl("/api/registrationform/create", this.registrationForm).pipe(first()).subscribe(data => {

      //this.apiService.createBorrowProfile(this.registrationForm).pipe(first()).subscribe(data => {
      this.isSaving2 = false;
      this.notiService.showSuccess(this.capnhatthanhcong, undefined, undefined);
      this.cartItem = [];
      localStorage.setItem('cart_item', JSON.stringify(this.cartItem));
      this.messageService.changeCartItem(this.cartItem);
      this.display = false;
    },
      error => {
        this.isSaving2 = false;
        if (error.error.errors[0] == 'Borrowing-Period-Must-Not-Exceed-60-Days') {
          this.notiService.showError("Hồ sơ chỉ được phép mượn tối đa 60 ngày", undefined, undefined);
        } else {
          this.notiService.showError(this.capnhatkhongthanhcong, undefined, undefined);
        }
      });
  }

  checkValidPhone() {
    if (this.phone.invalid) {
      this.phoneIsError = true;
    }
    else {
      this.phoneIsError = false;
    }
  }

  checkValidID() {
    if (this.IDNumber.invalid) this.IDIsError = true;
    else this.IDIsError = false;
  }

  checkValidPaymentDate() {
    if (!moment(this.registrationForm.payDate).isValid()) {
      this.payDateIsError = 3;
    }
    else {
      if (moment(this.registrationForm.payDate) <= moment(this.registrationForm.borrowDate))
        this.payDateIsError = 2;
      else {
        if (!this.registrationForm.payDate) {
          this.payDateIsError = 1;
        }
        else this.payDateIsError = 0;
      }
    }



  }

  checkValidBorrowDate() {

    if (!moment(this.registrationForm.borrowDate).isValid()) {
      this.borrowDateIsError = 3;
    }
    else {
      if (!moment(this.registrationForm.borrowDate).isSame(moment(), 'day')) {
        this.borrowDateIsError = 2;
      }
      else {
        if (!this.registrationForm.borrowDate) {
          this.borrowDateIsError = 1;
        }
        else this.borrowDateIsError = 0;
      }
    }


  }

  checkValidPhoneAndID() {
    this.checkValidPhone();
    this.checkValidID();
    this.checkValidPaymentDate();
    this.checkValidBorrowDate();


  }
  registerBorrowPaper() {
    this.checkValidPhoneAndID();

    if (this.phone.invalid || this.IDNumber.invalid || !this.registrationForm.payDate || !this.registrationForm.borrowDate) {
      return;
    }

    this.isSaving2 = true;
    this.registrationForm.borrowProfiles = this.cartItem;
    this.registrationForm.organization = JSON.parse(localStorage.getItem('organization'));
    this.registrationForm.iDCard = this.IDNumber.value;
    this.registrationForm.PhoneNumber = this.phone.value;

    // if (this.isExistanceOfApprovalAccount === false) {
    //   this.registrationForm.approvedAccount = null;
    // }

    const token = localStorage.getItem('access_token');

    let URL = `/api/paperregistrationform/create?token=${token}`;

    this.adminService.postDataByUrl(URL, this.registrationForm).pipe(first()).subscribe(data => {

      //this.apiService.createBorrowProfile(this.registrationForm).pipe(first()).subscribe(data => {
      this.isSaving2 = false;
      this.notiService.showSuccess("Đăng ký hồ sơ mượn thành công", undefined, undefined);
      this.cartItem = [];
      localStorage.setItem('cart_item', JSON.stringify(this.cartItem));
      this.messageService.changeCartItem(this.cartItem);
      this.display = false;

      this.phone.patchValue('');
      this.IDNumber.patchValue('');
      this.phoneIsError = false;
      this.IDIsError = false;
      this.payDateIsError = 0;
      this.borrowDateIsError = 0;
    },
      error => {
        this.isSaving2 = false;

        this.notiService.showError("Đăng ký hồ sơ mượn thất bại", undefined, undefined);
      });
  }

  getAccountStatus1() {
    this.nguoiPheDuyet = [];
    this.apiService.getAccountstatus1New(1).pipe(first()).subscribe(data => {
      for (const [i, value] of data.entries()) {
        this.nguoiPheDuyet.push({ 'label': value.username, 'value': value });
      }

      if (this.nguoiPheDuyet != null && this.nguoiPheDuyet != undefined && this.nguoiPheDuyet != [] && this.nguoiPheDuyet.length > 0) {
        this.registrationForm.approvedAccount = this.nguoiPheDuyet[0].value;
      }
      localStorage.setItem('approvedAccount', JSON.stringify(data));
    },
      error => {

      });
  }

  getAccountStatusFromStorage() {
    this.nguoiPheDuyet = [];
    var approvedAccount = JSON.parse(localStorage.getItem('approvedAccount'));
    for (var i = 0; i < approvedAccount.length; i++) {
      this.nguoiPheDuyet.push({ 'label': approvedAccount[i].username, 'value': approvedAccount[i] });
    }

    if (this.nguoiPheDuyet != null && this.nguoiPheDuyet != undefined && this.nguoiPheDuyet != [] && this.nguoiPheDuyet.length > 0) {
      this.registrationForm.approvedAccount = this.nguoiPheDuyet[0].value;
    }

  }

  add2Cart() {
    this.el = document.querySelector('.notification2');
    if (this.el === null) {
      return;
    }
    //let count = Number(this.el.getAttribute('data-count')) || 0;
    this.el.setAttribute('data-count', this.cartItem.length);
    this.el.classList.remove('notify');
    //(this.el as HTMLElement).style.width = this.el.offsetWidth;
    this.el.classList.add('notify');
    this.el.classList.add('show-count');
  }

  ngDoCheck() {
    if (AppGlobals.showCartItem === true) {
      this.add2Cart();
    }
  }

  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    };
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  CheckError() {
    let errorIn = "";

    if (this.registrationForm.payDate === null) errorIn += ". Kiểm tra lại Ngày hẹn trả !";
    if (this.registrationForm.borrowDate === null) errorIn += ". Kiểm tra lại Ngày mượn !";

    if (this.isExistanceOfApprovalAccount == true && (this.registrationForm.approvedAccount == null || this.registrationForm.approvedAccount == undefined)) {
      errorIn += ". Kiểm tra lại Người phê duyệt !";
    }


    // if (this.registrationForm.approvedAccount === null || this.registrationForm.approvedAccount == undefined) {
    //   errorIn += ". Kiểm tra lại Người phê duyệt !";
    // }

    if (this.registrationForm.borrowProfiles === []) errorIn += ". Kiểm tra lại các hồ sơ mượn !";
    this.errorInfo = errorIn;


    if (errorIn === "") {
      //if (this.registrationForm.borrowDate > this.registrationForm.payDate) this.errorInfo = "Ngày hẹn trả không được nhỏ hơn ngày mượn !";
      return true;
    }
    return false;
  }
  public getShowCartItem() {
    return AppGlobals.showCartItem;
  }
  ShowApprovedAccount() {
    let par: string;
    par = "&id=" + this.registrationForm.approvedAccount.id;

    this.adminService.getDataByParameterAndUrl("/api/account/fullobjectbyid", par).pipe(first()).subscribe(data => {
      if (AppGlobals.language == 'vi') {
        this.headerTitlettnd = 'Thông tin người duyệt';
      } else {
        this.headerTitlettnd = 'Approver Information';
      }
      this.displayApprovalAccount = true;
      this.ApprovalAccount = {
        code: data.account.username,
        name: data.account.fullname,
        phoneNumber: data.personalInformation.phoneNumber,
        cmnd: null,
        workingAt: null
      };

    },
      error => {
        this.notiService.showError(this.khongtimthaythongtin + error.error.title, undefined, undefined);
        this.displayApprovalAccount = false;
      });

  }

  gotoStep1(num: number) {
    this.isShowChooseBorrowMethod = false;

    if (num == 0) {
      this.isBorrowOnline = false;
      this.enableNext = false;

      let listID = [];

      for (let a of this.cartItem) {
        listID.push(a.id);
      }

      let body = {
        listBorrowProfileId: listID
      }

      this.adminService.postDataByUrl2('/api/paperregistrationform/checkvalidborrowprofile', body).subscribe(res => {
        let result: any = res;

        if (result.listProfileInValid != null) {
          this.listCannotBeBorrowedPaper = result.listProfileInValid;
        }

        this.checkEnableNext();
      })
    }
    else {
      this.isBorrowOnline = true;
      this.enableNext2 = false;
      let listID = [];

      for (let a of this.cartItem) {
        listID.push(a.id);
      }

      let body = {
        listBorrowProfileId: listID
      }
      this.adminService.postDataByUrl2('/api/registrationform/checkvalidborrowprofile', body).subscribe(res => {
        let result: any = res;

        if (result.listProfileInValid != null) {
          this.listCannotBeBorrowed = result.listProfileInValid;
        }
        // console.log("this.listCannotBeBorrowed", this.listCannotBeBorrowed);

        this.checkEnableNext2();
      })
    }
  }

  checkCannotBeBorrowed(id) {
    return this.listCannotBeBorrowed.indexOf(id) > -1;
  }
  checkCannotBeBorrowedPaper(id) {
    return this.listCannotBeBorrowedPaper.indexOf(id) > -1;
  }

  checkEnableNext() {
    for (let a of this.cartItem) {
      if (this.listCannotBeBorrowedPaper.includes(a.id)) {
        this.enableNext = false;
        return;
      }
    }

    this.enableNext = true;
    this.registrationForm.approvedAccount = this.nguoiPheDuyet[0].value;
    this.registrationForm.purposeExploitation = null;
    this.registrationForm.recommendedContent = null;
    // this.registrationForm.payDate = this.maxDate;
    this.registrationForm.borrowDate = this.minDate;
    this.IDNumber.reset();
    this.phone.reset();
    this.phoneIsError = false;
    this.IDIsError = false;
  }

  checkEnableNext2() {
    for (let a of this.cartItem) {
      if (this.listCannotBeBorrowed.includes(a.id)) {
        this.enableNext2 = false;
        return;
      }
    }

    // if(notification == true && (this.listDocumentSelect === [] || this.listDocumentSelect === null || this.listDocumentSelect === undefined || this.listDocumentSelect.length <1)){
    //   this.notiService.showSuccess("Cần chọn ít nhất một thành phần hồ sơ để đăng ký mượn", undefined, undefined);
    //   this.enableNext2 = false;
    //   return;
    // }    

    if (this.nguoiPheDuyet.length > 0) {
      this.registrationForm.approvedAccount = this.nguoiPheDuyet[0].value;
    } else {
      if (AppGlobals.language == 'vi') {
        this.notiService.showSuccess("Hiện không có người duyệt hồ sơ. Vui lòng kiểm tra lại cấu hình duyệt hồ sơ hoặc thông báo cho người quản trị.", undefined, undefined);
      }
      else {
        this.notiService.showSuccess("There are currently no profile approval. Please check the profile approval configuration or notify the administrator.", undefined, undefined);
      }
      return;
    }
    this.enableNext2 = true;

    this.registrationForm.purposeExploitation = null;
    this.registrationForm.recommendedContent = null;
    this.registrationForm.payDate = this.maxDate;
    // this.registrationForm.payDate = null;
    // console.log("date", this.registrationForm)
    this.payDateIsError = 0;
    let treeTable2 = [];
    this.listDocumentSelect.forEach(element => {
      // treeTable2.push({ id: element.profileId, name : element.profileName, parent: null, isView: true, isDownload: false});
      let data = this.cartItem.find(item => item.id == element.profileId);
      if (data != undefined) {
        data.parent = null;
        treeTable2.push(data);
      }
      treeTable2.push(element);
    });

    this.cartItem.forEach(element => {
      if (element.styleProfile == 1) {
        treeTable2.push(element);
      }
    });
    // console.log("cart", this.listProfileAndDocumentSelect, this.cartItem)
    treeTable2 = Array.from(new Set(treeTable2));
    this.listProfileAndDocumentSelect = [];
    this.listProfileAndDocumentSelect = this.utilityFunction.transformData(treeTable2, null);
  }

  getErrorPhoneMessage() {
    let message = "Số điện thoại không hợp lệ";
    let messageNull = "Bạn chưa nhập số điện thoại";

    if (AppGlobals.language == "en") {
      message = "Phone number invalid";
      messageNull = "Phone number is empty";
    }

    if (this.phone.hasError('required')) {
      return messageNull;
    }

    return this.phone.hasError('phonenumber') ? message : '';
  }

  getErrorIDMessage() {
    let message = "Số CMND/CCCD không hợp lệ";
    let messageNull = "Bạn chưa nhập số CMND/CCCD";

    if (AppGlobals.language == "en") {
      message = "Identity/Citizen identification card number invalid";
      messageNull = "Identity/Citizen identification card number is empty";
    }

    if (this.IDNumber.hasError('required')) {
      return messageNull;
    }

    return this.IDNumber.hasError('IDNumber') ? message : '';
  }

  selectDocument(rowData) {
    let checked = this.listDocumentSelect.findIndex(data => data.documentId == rowData.documentId);
    if (checked == -1) {
      this.listDocumentSelect.push(rowData);
    } else {
      this.listDocumentSelect.splice(checked, 1);
    }
  }
  changeIndex(event) {
    if (event.selectedIndex == 1) {
      this.checkEnableNext2();
    }
  }

}
